import React, { useState, useEffect, useCallback, useRef } from "react";
import { Form, GroupItem, Item } from "devextreme-react/form";

import { useAuth } from "../../../contexts/auth.js";
import { useAudit } from "../contexts/audit.js";
import { qualityURL } from "../../../api/config";
import {
  DSAuditType,
  DSShift,
  DSWorkStation,
  DSSuperviser,
  DSQualityLevel,
  preferences,
  arrayLogSession,
  arrayAuditor,
  arrayProcessSuperviser,
} from "../data";

export default function DataAuditor({ props, onSaveClick, onAuditTypeChange }) {
  const { user } = useAuth();
  const {
    pkAudit,
    auditArray,
    fkFacility,
    fkProcess,
    setFkProcess,
    fkShift,
    setFkShift,
    fkMasterOrder,
    fkAuditType,
    setFkAuditType,
    setAuditType,
    auditor,
    setAuditor,
    fkAuditor,
    fkSuperviser,
    setFkSuperviser,
    fkQualityLevel,
    setFkQualityLevel,
    fkWorkStation,
    setFkWorkStation,
  } = useAudit();

  const [auditAuditor, setAuditAuditor] = useState([]);
  const [shift, setShift] = useState([]);
  const [auditTypeArray, setAuditTypeArray] = useState([]);
  const [workStation, setWorkStation] = useState([]);
  const [superviser, setSuperviser] = useState([]);
  const [qualityLevel, setQualityLevel] = useState([]);

  const [dateAudit, setDateAudit] = useState(new Date());

  const formRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const auditorArray = await arrayAuditor();
        setAuditor(auditorArray);
        //console.log("dataAuditor: ", fkAuditor)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [fkAuditor]);

  useEffect(() => {
    async function fetchData() {
      try {
        const session = await arrayLogSession(user.idUser);
        if (session.length > 0) {
          setShift(
            await DSShift(session[0].FK_Facility, session[0].FK_Process)
          );
          setWorkStation(
            await DSWorkStation(session[0].FK_Facility, session[0].FK_Process)
          );
        }
      } catch (error) {
        console.error("Error fetching audit types:", error);
      }
    }
    fetchData();
  }, [fkFacility]);

  useEffect(() => {
    async function fetchData() {
      try {
        setAuditTypeArray(await DSAuditType());
        setQualityLevel(await DSQualityLevel());
        if (fkFacility && fkProcess) {
          setSuperviser(await arrayProcessSuperviser(fkFacility, fkProcess));
          const processSuperviser = await arrayProcessSuperviser(
            fkFacility,
            fkProcess
          );
          setFkSuperviser(processSuperviser[0].PK_Pople);
        }
      } catch (error) {
        console.error("Error fetching audit types:", error);
      }
    }
    fetchData();
  }, [fkFacility, fkProcess]);

  useEffect(() => {
    if (pkAudit < 0) {
      setFkShift(1);
      setFkWorkStation(1);
      setFkQualityLevel(1);
    } else {
    }
  }, [pkAudit]);

  function onFieldDataChanged(e) {
    if (e.dataField === "FK_AuditType") {
      const selectedAuditType = auditTypeArray.find(
        (audit) => audit.PK_AuditType === e.value
      );
      const displayValue = selectedAuditType
        ? selectedAuditType.AuditType
        : null;
      //onAuditTypeChange(displayValue);
      setAuditType(displayValue);
      setFkAuditType(e.value);
    }
    //setAuditArray(auditAuditor)
    //onSaveClick(auditAuditor);
  }

  const auditorEditorOptions = {
    label: "Auditor",
    dataSource: auditor,
    valueExpr: "FK_Auditor",
    displayExpr: "AuditorName",
    searchEnabled: true,
    value: fkAuditor,
    readOnly: true,
  };
  const auditTypeEditorOptions = {
    label: "Audit Type",
    dataSource: auditTypeArray,
    valueExpr: "PK_AuditType",
    displayExpr: "AuditType",
    searchEnabled: true,
    value: fkAuditType,
    readOnly: false,
  };
  const shiftEditorOptions = {
    label: "Shift",
    dataSource: shift,
    valueExpr: "PK_Shift",
    displayExpr: "Shift",
    searchEnabled: true,
    value: fkShift,
    readOnly: false,
  };
  const workStationEditorOptions = {
    label: "Work Station",
    dataSource: workStation,
    valueExpr: "PK_WorkStation",
    displayExpr: "WorkStation",
    searchEnabled: true,
    value: fkWorkStation,
    readOnly: false,
  };
  const superviserEditorOptions = {
    label: "Superviser",
    dataSource: superviser,
    valueExpr: "PK_Pople",
    displayExpr: "Superviser",
    searchEnabled: true,
    value: fkSuperviser,
    readOnly: false,
  };
  const qualityLevelEditorOptions = {
    dataSource: qualityLevel,
    valueExpr: "PK_QualityLevel",
    displayExpr: "QualityLevel",
    searchEnabled: true,
    value: fkQualityLevel,
    format: {
      type: "fixedPoint",
      precision: 3,
    },
    readOnly: false,
  };
  const pkAuditEditorOptions = {
    label: "PkAudit",
    value: pkAudit,
    visible: false,
  };
  const facilityEditorOptions = {
    label: "Facility",
    value: fkFacility,
    visible: false,
  };
  const processEditorOptions = {
    label: "Process",
    value: fkProcess,
    visible: false,
  };
  const dateAuditEditorOptions = {
    label: "Date Audit",
    value: dateAudit,
    visible: false,
  };
  const masterEditorOptions = {
    label: "Master",
    value: fkMasterOrder,
    visible: false,
  };

  const onOptionChanged = useCallback((e) => {
    if (e.name === "isDirty") {
    }
  }, []);

  return (
    <React.Fragment>
      <div className="sidebyside-container">
        <div className="leftside">Auditor Information</div>
      </div>
      <hr />
      <Form
        ref={formRef}
        formData={auditArray}
        readOnly={false}
        labelMode="floating"
        labelLocation="top"
        minColWidth={300}
        onOptionChanged={onOptionChanged}
        onFieldDataChanged={onFieldDataChanged}
      >
        <GroupItem colCount={2}>
          <GroupItem>
            <Item
              dataField="FK_Auditor"
              editorType="dxSelectBox"
              editorOptions={auditorEditorOptions}
            />
            <Item
              dataField="FK_AuditType"
              editorType="dxSelectBox"
              editorOptions={auditTypeEditorOptions}
            />
            <Item
              dataField="FK_Shift"
              editorType="dxSelectBox"
              editorOptions={shiftEditorOptions}
            />
          </GroupItem>
          <GroupItem>
            <Item
              dataField="FK_Superviser"
              editorType="dxSelectBox"
              editorOptions={superviserEditorOptions}
            />
            <Item
              dataField="FK_QualityLevel"
              editorType="dxSelectBox"
              editorOptions={qualityLevelEditorOptions}
            />
            <Item
              dataField="FK_WorkStation"
              editorType="dxSelectBox"
              editorOptions={workStationEditorOptions}
            />
          </GroupItem>
        </GroupItem>
      </Form>
      <Form
        formData={auditArray}
        readOnly={false}
        labelMode="floating"
        labelLocation="top"
        minColWidth={300}
      >
        <GroupItem colCount={6}>
          <GroupItem>
            <Item dataField="PK_Audit" editorOptions={pkAuditEditorOptions} />
          </GroupItem>
          <Item dataField="FK_Facility" editorOptions={facilityEditorOptions} />
          <GroupItem>
            <GroupItem>
              <Item
                dataField="FK_Process"
                editorOptions={processEditorOptions}
              />
            </GroupItem>
            <GroupItem>
              <Item
                dataField="FK_MasterOrder"
                editorOptions={masterEditorOptions}
              />
            </GroupItem>
            <GroupItem>
              <Item
                editorType="dxDateBox"
                dataField="Date_Audit"
                editorOptions={dateAuditEditorOptions}
              />
            </GroupItem>
          </GroupItem>
        </GroupItem>
      </Form>
    </React.Fragment>
  );
}
