import React, { useState, useEffect, useCallback, useRef } from "react";
import SelectBox from "devextreme-react/select-box";
import notify from "devextreme/ui/notify";
import { Button as ButtonItem } from "devextreme-react/button";
import DateBox from "devextreme-react/date-box";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Editing,
  Lookup,
  Summary,
  Selection,
  FilterRow,
  SearchPanel,
  Toolbar,
  Item,
  Format as FormatNumber,
  ColumnChooser,
  ColumnFixing,
  TotalItem,
  Scrolling,
  Export,
  HeaderFilter,
  FilterPanel,
  StateStoring,
  RequiredRule,
} from "devextreme-react/data-grid";
import {
  accessRights,
  DSFacility,
  DSShift,
  DSProductionSummary,
  dsProductionDetail,
  DSCutomerOnline,
  DSPress,
  DSPlacement,
} from "../data.js";
import "../planGrid.scss";
import { useAuth } from "../../../contexts/auth.js";

export default function ProductionDetail({ data }) {
  const { user } = useAuth();
  const [role, SetRole] = useState(2);
  const [facility, setFacility] = useState(data.fkFacility);
  const [shift, setShift] = useState(null);
  const [dateValue, setDateValue] = useState(null);
  const [pkProduction, setPkProduction] = useState([]);
  const min = new Date(2024, 0, 1);
  const now = new Date();
  const dataGridRef = useRef(null);

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    let isSubscribed = true; 

    const fetchData = async () => {
      try { 
        if (isSubscribed) {
          const roleResult = await accessRights(user.idUser, 1004, '30')
          SetRole(roleResult != undefined ?  roleResult : 2);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    DSShift(facility, data.fkProcess)
      .load()
      .then((data) => {
        if (data && data.length > 0) {
          setShift(data[0].PK_Shift);
        } else setShift(0);
      });
    setDateValue(formatDate(now));
  }, [facility]);

  useEffect(() => {
    if (shift !== null && dateValue !== null) {
      dsProductionDetail(facility, data.fkProcess, shift, dateValue)
        .load()
        .then((data) => {
          if (data && data.length > 0) {
            setPkProduction(data[0].FK_Production);
          } else setPkProduction(0);
        });
    }
  }, [facility, shift, dateValue]);

  function onFacilityValueChanged(e) {
    setFacility(e.value);
  }
  function onShiftValueChanged(e) {
    setShift(e.value);
  }
  const onDateValueChanged = useCallback((e) => {
    setDateValue(formatDate(e.value));
  }, []);

  const onAddingNew = () => {
    const dataGridInstance = dataGridRef.current.instance;
    if (dataGridInstance) {
      if (shift !== null && dateValue !== null) {
        dsProductionDetail(facility, data.fkProcess, shift, dateValue)
          .load()
          .then((data) => {
            if (data && data.length > 0) {
              setPkProduction(data[0].FK_Production);
            } else {
              setPkProduction(0);
            }
          });
      }
      dataGridInstance.addRow();
    }
  };
  const onInitNewRow = (e) => {
    e.data.PK_ProductionDetail = 0;
    e.data.FK_Production = pkProduction;
    e.data.FK_Facility = facility;
    e.data.FK_Process = data.fkProcess;
    e.data.FK_Shift = shift;
    e.data.DateProduction = dateValue;
    e.data.DateCreate = now;
    e.data.FK_User = user.idUser;
  };

  return (
    <React.Fragment>
      <DataGrid
        id="gridContainer"
        ref={dataGridRef}
        dataSource={
          shift !== null && dateValue !== null
            ? dsProductionDetail(facility, data.fkProcess, shift, dateValue)
            : null
        }
        height="100%"
        focusedRowEnabled={false}
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        allowColumnReordering={false}
        allowColumnResizing={true}
        showBorders={true}
        columnAutoWidth={true}
        showColumnHeaders={true}
        onInitNewRow={onInitNewRow}
        //onSaved={refreshDataGrid}
        /* onExporting={onExporting}
            onCellPrepared={onCellPrepared}
            onSelectionChanged={onSelectionChanged}*/
      >
        <SearchPanel visible={true} />
        <Scrolling mode="standard" />
        <Paging defaultPageSize={10} />
        <HeaderFilter visible={true} />
        <FilterPanel visible={true} />
        <FilterRow visible={false} />
        <ColumnFixing enabled={false} />
        <ColumnChooser enabled={false} mode={"dragAndDrop"} /> {/*select*/}
        <Pager
          visible={true}
          allowedPageSizes={true}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Selection
          mode="single"
          selectAllMode="allPages"
          showCheckBoxesMode="always"
        />{" "}
        <Editing
          mode="cell"
          allowAdding={role.FK_Role > 3}
          allowDeleting={role.FK_Role > 4}
          allowUpdating={role.FK_Role > 2}
        ></Editing>
        <Column
          dataField="PK_ProductionDetail"
          caption="ID"
          dataType="number"
          visible={true}
          allowEditing={false}
        />
        <Column
          dataField="FK_Production"
          caption="FK_Production"
          dataType="number"
          visible={false}
          allowEditing={false}
        />
        <Column
          dataField="FK_Facility"
          caption="FK_Facility"
          dataType="number"
          visible={false}
          allowEditing={false}
        />
        <Column
          dataField="FK_Process"
          caption="FK_Process"
          dataType="number"
          visible={false}
          allowEditing={false}
        />
        <Column
          dataField="FK_Shift"
          caption="FK_Shift"
          dataType="number"
          visible={false}
          allowEditing={false}
        />
        <Column
          dataField="DateProduction"
          caption="DateProduction"
          visible={false}
          allowEditing={false}
        />
        <Column
          dataField="FK_Customer"
          caption="Customer"
          dataType="number"
          visible={true}
          allowEditing={true}
        >
          <Lookup
            dataSource={DSCutomerOnline}
            valueExpr="PK_Customer"
            displayExpr="Customer"
          />
          <RequiredRule />
        </Column>
        <Column
          dataField="Remarks"
          caption="Remarks"
          dataType="text"
          visible={true}
          allowEditing={true}
        />
        <Column
          dataField="FK_Press"
          caption={data.fkProcess===12 ? "Line" : "Press"}
          dataType="number"
          visible={true}
          allowEditing={true}
        >
          <Lookup
            dataSource={DSPress(facility, data.fkProcess)}
            valueExpr="PK_Press"
            displayExpr="PressName"
          />
           <RequiredRule />
        </Column>
        <Column
          dataField="FK_Placement"
          caption="Placement"
          dataType="number"
          visible={data.fkProcess === 8 || data.fkProcess === 9 ? true : false}
          allowEditing={true}
        >
          <Lookup
            dataSource={DSPlacement}
            valueExpr="PK_Placement"
            displayExpr="Placement"
          />
        </Column>
        <Column
          dataField="Hits"
          caption={data.fkProcess===12 ? "Units" : "Hits"}
          dataType="number"
          visible={true}
          allowEditing={true}
        >
          <FormatNumber type="fixedPoint" precision={0} />
          <RequiredRule />
        </Column>
        <Column
          dataField="Setup"
          caption="Setup"
          dataType="number"
          visible={
            data.fkProcess === 8 ||
            data.fkProcess === 9 ||
            data.fkProcess === 10
              ? true
              : false
          }
          allowEditing={true}
        />
        <Column
          dataField="DateCreate"
          caption="DateCreate"
          visible={false}
          allowEditing={false}
        />
        <Column
          dataField="FK_User"
          caption="FK_User"
          dataType="number"
          visible={false}
          allowEditing={false}
        />
        <Summary>
          <TotalItem
            column="Hits"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem
            column="Setup"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
        </Summary>
        <Toolbar>
          <Item location="before">
            <SelectBox
              width="150"
              displayExpr="FacilityCode"
              showClearButton={false}
              dataSource={DSFacility}
              label="Select Facility"
              value={facility}
              valueExpr="PK_Facility"
              onValueChanged={onFacilityValueChanged}
              readOnly={ role.FK_Role < 6 && role.FK_Role > 2 }
            />
          </Item>
          <Item location="before">
            <SelectBox
              width="150"
              displayExpr="Shift"
              showClearButton={false}
              dataSource={DSShift(facility, data.fkProcess)}
              label="Select Shift"
              value={shift}
              valueExpr="PK_Shift"
              onValueChanged={onShiftValueChanged}
              readOnly={ role.FK_Role < 6 && role.FK_Role > 2 }
            />
          </Item>
          <Item location="before">
            <DateBox
              value={dateValue}
              min={min}
              max={now}
              onValueChanged={onDateValueChanged}
            />
          </Item>
          <Item location="after">
            <ButtonItem
              icon="add"
              visible={shift !== 0 && dateValue !== undefined ? role.FK_Role > 3 : false}
              onClick={onAddingNew}
            />
          </Item>
          <Item location="after">
            <ButtonItem icon="refresh" visible={true} />
          </Item>
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
      </DataGrid>
    </React.Fragment>
  );
}
