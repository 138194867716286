import { HomePage, TasksPage, ProfilePage, QualitySuite, BamMainPage, 
        WarehouseMainPage, PlanningMainPage, ProductionMainPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';
import { isDisabled } from "@testing-library/user-event/dist/utils";

const routes = [
    {
        path: '/home',
        element: HomePage,
        index: 1,
    },
    {
        path: '/tasks',
        element: isDisabled,
        index: 1,
    },
    {
        path: '/inventory',
        element: WarehouseMainPage,
        index: 5,
    },
    {
        path: '/bam',
        element: BamMainPage,
        index: 4,
    },
    {
        path: '/quality',
        element: QualitySuite,
        index: 3,
    },
    {
        path: '/production',
        element: ProductionMainPage,
        index: 6,
    },
    {
        path: '/planning',
        element: PlanningMainPage,
        index: 7,
    },
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
