
const menuItems = [
  {
    id: "1",
    name: "Dashboard",
    visible: true,
  },
  {
    id: "2",
    name: "Global Inventory",
    visible: true,
  },
];

export default {
  getMenuItem() {
    return menuItems;
  },
};