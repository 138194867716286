import React, {
    useState,
    useEffect,
    useContext,
    useRef,
    useCallback,
  } from "react";
  import DataGrid, { Column } from "devextreme-react/data-grid";
  
  import { useTheme } from "../../../contexts/theme";
  import { getStatusArtById, } from "../data";
  import "./status.scss";
  
  export default function ArtSummary(props) {
    const { PK_Master } = props || {};
    const { getTheme } = useTheme();
    const [themeColor, setThemeColor] = useState("purple");
    const [artStatus, setArtStatus] = useState([]);
  
    let currentTheme = getTheme().split(".")[0];
    useEffect(() => {
      function themeResult() {
        setThemeColor(currentTheme);
  
      }
      themeResult();
    }, [currentTheme]);
    useEffect(() => {
      async function ArtResult() {
        if (PK_Master) {
          const result = await getStatusArtById(PK_Master);
          setArtStatus(result);
        }
      }
      ArtResult();
    }, [PK_Master]);
  
    const renderGridCell = (data) => {
      return <div style={{ fontWeight: "bold", fontSize: 20 }}>{data.text}</div>;
    };
    const renderGridStatusCell = (data) => {
      return <div style={{ fontWeight: "normal", fontSize: 20, color: 'grey' }}>{data.text}</div>;
    };
  
    return (
      <React.Fragment>
        <DataGrid
          id="gridContainer"
          class="borderlessGrid"
          dataSource={artStatus}
          height={600}
          keyExpr="FK_Style"
          showBorders={false}
          showColumnHeaders={false}
        >
          <Column dataField="FK_Master" caption="FK_Master" visible={false} />
          <Column
            dataField="Description"
            caption="Description"
            width={250}
            cellRender={renderGridCell}
          />
          <Column dataField="Status" caption="status" cellRender={renderGridStatusCell}/>
        </DataGrid>
      </React.Fragment>
    );
  }
  