import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import FileUploader from "devextreme-react/file-uploader";
import ProgressBar from "devextreme-react/progress-bar";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
  EmailRule,
  GroupItem,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import LoadIndicator from "devextreme-react/load-indicator";
import { createAccount } from "../../api/auth";
import "./CreateAccountForm.scss";
import { uploadAvatarURL, sharedURL } from "../../api/config";

export default function CreateAccountForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({
    Name: "",
    Last_Name: "",
    User_Name: "",
    email: "",
    password: "",
    imageName: "avatar.png",
  });

  const [isDropZoneActive, setIsDropZoneActive] = useState(false);
  const [imageSource, setImageSource] = useState("");
  const [textVisible, setTextVisible] = useState(true);
  const [progressVisible, setProgressVisible] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [imageName, setImageName] = useState("avatar.png");
  const [facility, setFacility] = useState([]);
  const [process, setProcess] = useState([]);
  const [position, setPosition] = useState([]);

  const allowedFileExtensions = [".jpg", ".jpeg", ".gif", ".png"];
   
  useEffect(() => {
    fetch(`${sharedURL}/facility`)
      .then((res) => res.json())
      .then((data) => setFacility(data))
      .catch((error) => console.error("Error fetching facility data:", error));
    fetch(`${sharedURL}/process`)
      .then((res) => res.json())
      .then((data) => setProcess(data))
      .catch((error) => console.error("Error fetching process data:", error));
    fetch(`${sharedURL}/position`)
      .then((res) => res.json())
      .then((data) => setPosition(data))
      .catch((error) => console.error("Error fetching position data:", error));
  }, []);

  const FacilityEditorOptions = {
    items: facility,
    value: "",
    valueExpr: "PK_Facility",
    displayExpr: "FacilityCode",
  };
  const ProcessEditorOptions = {
    items: process,
    value: "",
    valueExpr: "PK_Process",
    displayExpr: "Process",
  };
  const PositionEditorOptions = {
    items: position,
    value: "",
    valueExpr: "PK_PlantPosition",
    displayExpr: "PlantPosition",
  };

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const {
        Name,
        Last_Name,
        User_Name,
        email,
        password,
        imageName,
        facility,
        process,
        position,
      } = formData.current;
      setLoading(true);
      const result = await createAccount(
        Name,
        Last_Name,
        User_Name,
        email,
        password,
        imageName,
        facility,
        process,
        position
      );
      setLoading(false);

      if (result.isOk) {
        navigate("/login");
      } else {
        notify(result.message, "error", 2000);
      }
    },
    [navigate]
  );

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  function onDropZoneEnter(e) {
    if (e.dropZoneElement.id === "dropzone-external") {
      setIsDropZoneActive(true);
    }
  }

  function onDropZoneLeave(e) {
    if (e.dropZoneElement.id === "dropzone-external") {
      setIsDropZoneActive(false);
    }
  }

  function onUploaded(e) {
    const { file } = e;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setIsDropZoneActive(false);
      setImageSource(fileReader.result);
    };
    fileReader.readAsDataURL(file);
    setTextVisible(false);
    setProgressVisible(false);
    setProgressValue(0);
    setImageName(e.file.name);
  }

  function onProgress(e) {
    setProgressValue((e.bytesLoaded / e.bytesTotal) * 100);
  }

  function onUploadStarted() {
    setImageSource("");
    setProgressVisible(true);
  }
  const imageNameEditorOptions = {
    stylingMode: "filled",
    value: imageName,
    readOnly: true,
    visible: false,
  };

  return (
    <form className={"create-account-form"} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <GroupItem colCount={2}>
          <GroupItem>
            <div className="widget-container flex-box">
              <span>Profile Picture</span>
              <div
                id="dropzone-external"
                className={`flex-box ${
                  isDropZoneActive
                    ? "dx-theme-accent-as-border-color dropzone-active"
                    : "dx-theme-border-color"
                }`}
              >
                {imageSource && (
                  <img id="dropzone-image" src={imageSource} alt="" />
                )}
                {textVisible && (
                  <div id="dropzone-text" className="flex-box">
                    <span>Drag & Drop the desired file</span>
                    <span>…or click to browse for a file instead.</span>
                  </div>
                )}
                <ProgressBar
                  id="upload-progress"
                  min={0}
                  max={100}
                  width="30%"
                  showStatus={false}
                  visible={progressVisible}
                  value={progressValue}
                ></ProgressBar>
              </div>
              <FileUploader
                id="file-uploader"
                dialogTrigger="#dropzone-external"
                dropZone="#dropzone-external"
                multiple={false}
                name="file"
                accept="image/*"
                allowedFileExtensions={allowedFileExtensions}
                uploadMode="instantly"
                uploadUrl={uploadAvatarURL}
                visible={false}
                onDropZoneEnter={onDropZoneEnter}
                onDropZoneLeave={onDropZoneLeave}
                onUploaded={onUploaded}
                onProgress={onProgress}
                onUploadStarted={onUploadStarted}
              ></FileUploader>
            </div>
          </GroupItem>
          <GroupItem>
            <Item
              dataField={"Name"}
              editorType={"dxTextBox"}
              editorOptions={nameEditorOptions}
            >
              <RequiredRule message="Name is required" />
              <Label visible={false} />
            </Item>
            <Item
              dataField={"Last_Name"}
              editorType={"dxTextBox"}
              editorOptions={lastNameEditorOptions}
            >
              <RequiredRule message="Last Name is required" />
              <Label visible={false} />
            </Item>
            <Item
              dataField={"User_Name"}
              editorType={"dxTextBox"}
              editorOptions={UserNameEditorOptions}
            >
              <RequiredRule message="User Name is required" />
              <Label visible={false} />
            </Item>
          </GroupItem>
        </GroupItem>
        <GroupItem colCount={2}>
          <GroupItem>
            <Item
              dataField={"email"}
              editorType={"dxTextBox"}
              editorOptions={emailEditorOptions}
            >
              <RequiredRule message="Email is required" />
              <EmailRule message="Email is invalid" />
              <Label visible={false} />
            </Item>
            <Item
              dataField={"password"}
              editorType={"dxTextBox"}
              editorOptions={passwordEditorOptions}
            >
              <RequiredRule message="Password is required" />
              <Label visible={false} />
            </Item>
            <Item
              dataField={"confirmedPassword"}
              editorType={"dxTextBox"}
              editorOptions={confirmedPasswordEditorOptions}
            >
              <RequiredRule message="Password is required" />
              <CustomRule
                message={"Passwords do not match"}
                validationCallback={confirmPassword}
              />
              <Label visible={false} />
            </Item>
          </GroupItem>
          <GroupItem>
            <Item
              dataField={"facility"}
              editorType={"dxSelectBox"}
              editorOptions={FacilityEditorOptions}
            >
              <RequiredRule message="Facility is required" />
              <Label visible={false} />
            </Item>
            <Item
              dataField={"process"}
              editorType={"dxSelectBox"}
              editorOptions={ProcessEditorOptions}
            >
              <RequiredRule message="Facility is required" />
              <Label visible={false} />
            </Item>
            <Item
              dataField={"position"}
              editorType={"dxSelectBox"}
              editorOptions={PositionEditorOptions}
            >
              <RequiredRule message="Facility is required" />
              <Label visible={false} />
            </Item>
          </GroupItem>
        </GroupItem>
        <Item
          dataField={"imageName"}
          editorType={"dxTextBox"}
          editorOptions={imageNameEditorOptions}
        >
          <Label visible={false} />
        </Item>
        <Item>
          <div className="policy-info">
            By creating an account, you agree to the{" "}
            <Link to="#">Terms of Service</Link> and{" "}
            <Link to="#">Privacy Policy</Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Create a new account"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={"login-link"}>
            Have an account? <Link to={"/login"}>Sign In</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}

const emailEditorOptions = {
  stylingMode: "filled",
  placeholder: "Email",
  mode: "email",
};
const passwordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Password",
  mode: "password",
};
const confirmedPasswordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Confirm Password",
  mode: "password",
};
const nameEditorOptions = { stylingMode: "filled", placeholder: "Name" };
const lastNameEditorOptions = {
  stylingMode: "filled",
  placeholder: "Last Name",
};

const UserNameEditorOptions = {
  stylingMode: "filled",
  placeholder: "User Name",
};

