import React, { useState, useEffect, useContext } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Menu from "devextreme-react/menu";
import { useTheme } from "../../contexts/theme";
import { useAuth } from "../../contexts/auth";

import { ReactComponent as RBLogo } from "./assets/RBLogo.svg";
import { ReactComponent as BIS_BW } from "./assets/BIS_BW.svg";
import { ReactComponent as TSC_BW } from "./assets/TSC_BW.svg";
import service from "./dataMenu.js";
import DashboardTemplate from "./dashboard.js";
import SewingCapacity from "./capacity/sewing.js";
import OpenOrder from "./capacity/openOrder.js";
import Main from "./status/main.js";
import PdStatus from "./processUpdate/pd";
import PdDashboardTemplate from "./processUpdate/pdDashboard";

import "./plan.scss"; 


export default function PlanningMainPage() {
  const { user, signOut } = useAuth();
  const { getTheme } = useTheme();
  const [title, setTitle] = useState('PO Status by Process');
  const [iconColor, setIconColor] = useState("white");
  const itemMenu = service.getMenuItem();
  const [itemComponent, setItemComponent] = useState(() => <Main />);

  const showSubmenuModes = [
    {
      name: "onHover",
      delay: { show: 0, hide: 500 },
    },
    {
      name: "onClick",
      delay: { show: 0, hide: 300 },
    },
  ];

  let currentTheme = getTheme().split(".")[1];
  let themeColor = getTheme().split(".")[0];
  useEffect(() => {
    function themeResult() {
      setIconColor(currentTheme === "dark" ? "white" : "black");
    }
    themeResult();
  }, [currentTheme]);

  const onItemClick = (e) => {
    if (e.itemData.id === "1") {
      setItemComponent(() => <DashboardTemplate />);
      setTitle('DPR Demand Prioritization Report');
    }
    if (e.itemData.id === "10") {
      setItemComponent(() => <Main />);
      setTitle('PO Status by Process');
    }
    if (e.itemData.id === "20_1") {
      setItemComponent(() => <OpenOrder />);
      setTitle('Open Order');
    }
    if (e.itemData.id === "20_2") {
      setItemComponent(() => <SewingCapacity />);
      setTitle('Sewing Capacity');
    }
    if (e.itemData.id === "30_1_1") {
      setItemComponent(() => <PdDashboardTemplate />);
      setTitle('PD Dashboard');
    }
    if (e.itemData.id === "30_1_2") {
      setItemComponent(() => <PdStatus />);
      setTitle('PD Status');
    }
  };

  return (
    <React.Fragment>
      <Toolbar className={"header-toolbar"}>
      <Item
          visible={true}
          location={"before"}
          locateInMenu={"auto"}     
        >
          <h5 className={'content-block'}>{title}</h5>          
        </Item>
        <Item
          visible={true}
          location={"after"}
          locateInMenu={"auto"}
          widget={"dxButton"}
          cssClass={"menu-button"} 
        >
          <BIS_BW fill={iconColor} className={"bis-logo"} />
        </Item>
        <Item
          visible={true}
          location={"after"}
          locateInMenu={"auto"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <TSC_BW fill={iconColor} className={"tsc-logo"} />
        </Item>
        <Item
          visible={true}
          location={"after"}
          locateInMenu={"auto"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <RBLogo fill={iconColor} className={"rb-logo"} />
        </Item>
      </Toolbar>
      <div className="form">
        <div className="parent-container">
          <Menu
            id="scroll"
            dataSource={itemMenu}
            displayExpr="name"
            showFirstSubmenuMode={showSubmenuModes[1]}
            orientation={"horizontal"}
            hideSubmenuOnMouseLeave={true}
            onItemClick={onItemClick}
          />
        </div>
      </div>
      <div className={"content-block dx-card responsive-paddings"}>
        {itemComponent}
      </div>
    </React.Fragment>
  );
}
