
const menuItems = [
  {
    id: "1",
    name: "Dashboard",
    visible: true,
  },
  {
    id: "10",
    name: "Status",
    visible: true,
  },
  {
    id: "20",
    name: "Capacity",
    visible: true,
    items: [
      {
        id: "20_1",
        name: "Open Order",
        visible: true,
      },
      {
        id: "20_2",
        name: "Sewing",
        visible: true,
      },
    ],
  },
  {
    id: "30",
    name: "Process",
    visible: true,
    items: [
      {
        id: "30_1",
        name: "Product Development",
        visible: true,
        items: [
          {
            id: "30_1_1",
            name: "Dashboard",
            visible: true,
          },
          {
            id: "30_1_2",
            name: "Status - Update",
            visible: true,
          },
        ],
      },
      {
        id: "30_2",
        name: "Art",
        visible: true,
      },
    ],
  },
];

export default {
  getMenuItem() {
    return menuItems;
  },
};