import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { createStore } from "devextreme-aspnet-data-nojquery";
import ArrayStore from "devextreme/data/array_store";
import { retrobrandURL } from "../../api/config";

export async function getSalesRepCode(FK_User) {
    const response = await fetch(`${retrobrandURL}/salesrep/${FK_User}`)
      .then((response) => response.json())
      .catch((error) => {
        console.error(error);
        return [];
      });
    return response;
  }

