import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import DataGrid, { Column, Format } from "devextreme-react/data-grid";
import List from "devextreme-react/list";
import TileView from "devextreme-react/tile-view";
import ArrayStore from "devextreme/data/array_store";
import SelectBox from "devextreme-react/select-box";
import notify from "devextreme/ui/notify";
import Box, { Item } from "devextreme-react/box";
import ResponsiveBox, { Row, ColorBox, Item as ItemResponsive, Location } from "devextreme-react/responsive-box"; //https://js.devexpress.com/React/Demos/WidgetsGallery/Demo/ResponsiveBox/Overview/MaterialBlueLight/
import Tabs, { Item as ItemTab } from "devextreme-react/tabs";
import TabPanel from "devextreme-react/tab-panel";
import MultiView from "devextreme-react/multi-view";
import { useAuth } from "../../../contexts/auth";
import { arrayOpenOrder, DSStatusWip, arraySummaryStatus } from "../data";
import Summary from "./summary";
import PdSummary from "./pd.js";
import ArtSummary from "./art.js";
import TrimSummary from "./trims.js";
import ProductionSummary from "./production.js";

import "./status.scss";

const listAttrs = { class: "list" };
const renderListItem = (item) => (
  <div>
    <div className="Customer">
      <div className="name">Customer: {item.Customer}</div>
      <div className="sku">PO: {item.PO}</div>
      <div className="sku">Style: {item.Style}</div>
      <div className="sku">Color: {item.Color}</div>
      <div className="sku">Art: {item.Art}</div>
      <div className="sku">Id Master: {item.PK_Master}</div>
    </div>
  </div>
);

export default function Main() {
  const { user } = useAuth();
  const [openOrderArray, setOpenOrderArray] = useState([]);
  const [summaryArray, setSummaryArray] = useState([]);
  const [currentPO, setCurrentPO] = useState([]);
  const [selectedItemKeys, setSelectedItemKeys] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [status, setStatus] = useState("buttonOpen");

  const handleListSelectionChange = useCallback(
    (e) => {
      const po = e.addedItems[0];
      setCurrentPO(po);
      setSelectedItemKeys([po.Pk_Master]);

      const fetchData = async () => {
        const summary = await arraySummaryStatus(po.PK_Master);
        if (summary) setSummaryArray(summary);
      };
      fetchData();
    },
    [setCurrentPO, setSelectedItemKeys]
  );

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      if (isSubscribed) {
        try {
          const poArray = await arrayOpenOrder();
          setOpenOrderArray(poArray);
          //console.log(poArray);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
    return () => (isSubscribed = false);
  }, []);

  const dataSourceOptions = {
    store: new ArrayStore({
      data: openOrderArray,
      key: "PK_Master",
    }),
    searchExpr: ["PO", "Style", "Customer", "Art", "PK_Master"],
  };

  function onCellPrepared(e) {
    if (e.rowType === "header" || e.rowType === "data") {
      e.cellElement.style.textAlign = "center";
    }
  }

  const renderCustomItem = () => {
    return (
      <div className="sku-name">
        {currentPO.Customer ? currentPO.Customer : "Loading..."}
      </div>
    );
  };

  function onSelectionChanged(e) {
    const tab = e.addedItems[0];
    setSelectedItem(tab.title);
  }
  const renderGridCell = (data) => {
    return (
      <div
        style={{
          fontWeight: "normal",
          fontSize: 20,
        }}
      >
        {data.text}
      </div>
    );
  };
  const renderGridBalCell = (data) => {
    return (
      <div
        style={{
          fontWeight: "normal",
          fontSize: 20,
          color: data.value < 0 ? "red" : "white",
        }}
      >
        {data.text}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Box direction="row" width="100%" height={650}>
        <Item ratio={0} baseSize={350}>
          <div className="left">
            <List
              selectionMode="single"
              dataSource={dataSourceOptions}
              grouped={false}
              searchEnabled={true}
              selectedItemKeys={selectedItemKeys}
              onSelectionChanged={handleListSelectionChange}
              itemRender={renderListItem}
              elementAttr={listAttrs}
            />
          </div>
        </Item>
        <Item ratio={2}>
          <TabPanel
            width="100%"
            height={650}
            animationEnabled={true}
            swipeEnabled={true}
            tabsPosition={"left"}
            stylingMode={"primary"}
            iconPosition={"top"}
            onSelectionChanged={onSelectionChanged}
          >
            <ItemTab title="Summary" visible={true}>
              <div id="form-demo">{Summary(currentPO)}</div>
            </ItemTab>
            <ItemTab title="PD" visible={true}>
              <div id="form-demo">{PdSummary(currentPO)}</div>
            </ItemTab>
            <ItemTab className="sku-name" title="Art" visible={true}>
              <div>{ArtSummary(currentPO)}</div>
            </ItemTab>
            <ItemTab className="sku-name" title="Trims" visible={true}>
              <div>{TrimSummary(currentPO)}</div>
            </ItemTab>
            <ItemTab className="sku-name" title="Service" visible={true}>
              <div>{renderCustomItem()}</div>
            </ItemTab>
            <ItemTab className="sku-name" title="Fabric" visible={true}>
              <div>{renderCustomItem()}</div>
            </ItemTab>
            <ItemTab className="sku-name" title="Textil" visible={true}>
              <div>{renderCustomItem()}</div>
            </ItemTab>
            <ItemTab className="sku-name" title="Planning" visible={true}>
              <div>{renderCustomItem()}</div>
            </ItemTab>
            <ItemTab className="sku-name" title="Production" visible={true}>
              <div>{ProductionSummary(currentPO)}</div>
            </ItemTab>
          </TabPanel>
        </Item>
      </Box>
      <br />
      {summaryArray.length > 0 && (
        <Box direction="row" width="100%" height={70}>
          <Item ratio={1}>
            <button
              className={
                summaryArray[0].StatusPD === 1 ? "buttonClose" : "buttonOpen"
              }
            >
              <span>
                PD
                <br />
                &ensp;
              </span>
            </button>
          </Item>
          <Item ratio={0} baseSize={20}>
            <div className="rect demo-trans"></div>
          </Item>
          <Item ratio={1}>
            <button
              className={
                summaryArray[0].StatusArt === 1
                  ? "buttonClose"
                  : summaryArray[0].StatusArt === 2
                  ? "button2"
                  : "buttonOpen"
              }
            >
              {/*onClick={finalHandleClick}*/}
              <span>
                ART <br />
                {currentPO.Art}
              </span>
            </button>
          </Item>
          <Item ratio={0} baseSize={20}>
            <div className="rect demo-trans"></div>
          </Item>
          <Item ratio={1}>
            <button
              className={
                summaryArray[0].StatusTrims === 1
                  ? "buttonClose"
                  : "buttonOpen"
              }
            >
              <span>
                TRIMS
                <br />
                &ensp;
              </span>
            </button>
          </Item>
          <Item ratio={0} baseSize={20}>
            <div className="rect demo-trans"></div>
          </Item>
          <Item ratio={1}>
            <button className="button1">
              <span>
                SERVICE
                <br />
                &ensp;
              </span>
            </button>
          </Item>
          <Item ratio={0} baseSize={20}>
            <div className="rect demo-trans"></div>
          </Item>
          <Item ratio={1}>
            <button className="button1">
              <span>
                FABRIC
                <br />
                &ensp;
              </span>
            </button>
          </Item>
          <Item ratio={0} baseSize={20}>
            <div className="rect demo-trans"></div>
          </Item>
          <Item ratio={1}>
            <button className="button1">
              <span>
                TEXTILE
                <br />
                &ensp;
              </span>
            </button>
          </Item>
          <Item ratio={0} baseSize={20}>
            <div className="rect demo-trans"></div>
          </Item>
          <Item ratio={1}>
            <button className="button1">
              <span>
                PLANNING
                <br />
                &ensp;
              </span>
            </button>
          </Item>
          <Item ratio={0} baseSize={20}>
            <div className="rect demo-trans"></div>
          </Item>
          <Item ratio={1}>
            <button
              className={
                summaryArray[0].StatusProduction === 1
                  ? "buttonClose"
                  : "buttonOpen"
              }
            >
              <span>
                PRODUCTION
                <br />
                {summaryArray[0].LastProcess}
              </span>
            </button>
          </Item>
        </Box>
      )}
      <br />
      <DataGrid
        id="gridContainer"
        class="borderlessGrid"
        dataSource={
          currentPO.PK_Master ? DSStatusWip(currentPO.PK_Master) : null
        }
        height={500}
        keyExpr="FK_Master"
        showBorders={true}
        showColumnHeaders={true}
        onCellPrepared={onCellPrepared}
        visible={selectedItem === "Production" ? true : false}
      >
        <Column dataField="FK_Master" caption="FK_Master" visible={false} />
        <Column
          dataField="TotalOrder"
          caption="Total Order"
          width={250}
          visible={false}
        />
        <Column
          dataField="Process"
          caption="Process"
          cellRender={renderGridCell}
        />
        <Column caption="Entry">
          <Column
            dataField="TotalIn"
            caption="Total"
            dataType="number"
            cellRender={renderGridCell}
          >
            <Format type="fixedPoint" precision={0} />
          </Column>
          <Column
            dataField="BalanceIn"
            caption="Balance"
            dataType="number"
            cellRender={renderGridBalCell}
          >
            <Format type="fixedPoint" precision={0} />
          </Column>
          <Column
            dataField="ExtraIn"
            caption="Extras"
            dataType="number"
            cellRender={renderGridCell}
          >
            <Format type="fixedPoint" precision={0} />
          </Column>
          <Column
            dataField="AvgIn"
            caption="Avgerage"
            dataType="number"
            cellRender={renderGridBalCell}
          >
            <Format type="percent" precision={1} />
          </Column>
        </Column>
        <Column caption="Production">
          <Column
            dataField="TotalProduction"
            caption="Total"
            dataType="number"
            cellRender={renderGridCell}
          >
            <Format type="fixedPoint" precision={0} />
          </Column>
          <Column
            dataField="BalanceProduction"
            caption="Balance"
            dataType="number"
            cellRender={renderGridBalCell}
          >
            <Format type="fixedPoint" precision={0} />
          </Column>
        </Column>
        <Column caption="Out">
          <Column
            dataField="TotalOut"
            caption="Total"
            dataType="number"
            cellRender={renderGridCell}
          >
            <Format type="fixedPoint" precision={0} />
          </Column>
          <Column
            dataField="BalanceOut"
            caption="Balance"
            dataType="number"
            cellRender={renderGridBalCell}
          >
            <Format type="fixedPoint" precision={0} />
          </Column>
          <Column
            dataField="ExtraOut"
            caption="Extras"
            dataType="number"
            cellRender={renderGridCell}
          >
            <Format type="fixedPoint" precision={0} />
          </Column>
          <Column
            dataField="AvgOut"
            caption="Avgerage"
            dataType="number"
            cellRender={renderGridBalCell}
          >
            <Format type="percent" precision={1} />
          </Column>
        </Column>
      </DataGrid>
    </React.Fragment>
  );
}
