import React from 'react';
import bisTeam from "../../assets/BIS_Team.jpg";
import genisysLogo from "../../assets/Genisys_Logo.png";
import './home.scss';

export default function Home() {
  return (
    <React.Fragment>

      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'}>
          <div className={'logos-container'}>
            <img className='bis-pic' src={bisTeam} /> <br/>
            <img className='genisys-logo' src={genisysLogo} /> <br/>
          </div>
        </div>
      </div>
    </React.Fragment>
)}

//<img className='bis-pic' src={`http://192.168.2.4:8087/Genisys/Image_Art/tbl_Art/BIS_Team.jpg`} /> <br/>
//<img className='genisys-logo' src={`http://192.168.2.4:8087/Genisys/Image_Art/tbl_Art/Genisys_logo.png`} /> <br/>