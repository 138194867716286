import React, { useState, useEffect, useContext } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Menu from "devextreme-react/menu";
import { useTheme } from "../../contexts/theme";
import { useAuth } from "../../contexts/auth";

import { getMenuItems } from "./dataMenu.js";
import { ReactComponent as BAM_blue } from "./assets/BAM_Blue.svg";
import { ReactComponent as BAM_orange } from "./assets/BAM_Orange.svg";
import { ReactComponent as BAM_purple } from "./assets/BAM_Purple.svg";
import { ReactComponent as RBLogo } from "./assets/RBLogo.svg";
import SummaryTemplate from "./summary/dashboard.js";
import LicenseTemplate from "./license/dashboard.js";
import DashboardTemplate from "./salesRep/dashboard.js";
import DataBaseSales from "./databaseSales/dbSales.js";
import { getSalesRepCode } from "./data.js";

import "./bam.scss";

export default function BamMainPage() {
  const { user, signOut } = useAuth();
  const { getTheme } = useTheme();
  const [iconColor, setIconColor] = useState("white");
  const [Logo, setLogo] = useState();
  const [isSalesRep, setIsSalesRep] = useState(true);
  const [srCode, setSrCode] = useState();
  const [itemComponent, setItemComponent] = useState(() => null);

  const itemMenu = getMenuItems(isSalesRep);

  const showSubmenuModes = [
    {
      name: "onHover",
      delay: { show: 0, hide: 500 },
    },
    {
      name: "onClick",
      delay: { show: 0, hide: 300 },
    },
  ];

  let currentTheme = getTheme().split(".")[1];
  let themeColor = getTheme().split(".")[0];
  useEffect(() => {
    function themeResult() {
      setIconColor(currentTheme === "dark" ? "white" : "black");
    }
    themeResult();
  }, [currentTheme]);

  useEffect(() => {
    const isSR = user.FK_Position === 2005 ? true : false;
    setIsSalesRep(isSR);
    async function getSRCode() {
      const salesRep = await getSalesRepCode(user.idUser);
      if (salesRep.length > 0) setSrCode(salesRep[0].Code);
    }
    getSRCode();
  }, [user]);

  useEffect(() => {
    setItemComponent(
      isSalesRep
        ? () => <DashboardTemplate props={{ salesRepCode: srCode }} />
        : () => <SummaryTemplate />
    );
  }, [srCode]);

  const onItemClick = (e) => {
    if (e.itemData.id === "1") {
      setItemComponent(() => <DataBaseSales />);
    }
    if (e.itemData.id === "2") {
      setItemComponent(() => <SummaryTemplate />);
    }
    if (e.itemData.id === "3") {
      setItemComponent(() => <LicenseTemplate />);
    }
    if (isSalesRep) {
      if (e.itemData.id === "4") {
        setItemComponent(() => (
          <DashboardTemplate props={{ salesRepCode: srCode }} />
        ));
      }
    } else if (e.itemData.id === "4_1") {
      setItemComponent(() => (
        <DashboardTemplate props={{ salesRepCode: "BHAG" }} />
      ));
    } else if (e.itemData.id === "4_2") {
      setItemComponent(() => (
        <DashboardTemplate props={{ salesRepCode: "CKIM" }} />
      ));
    } else if (e.itemData.id === "4_3") {
      setItemComponent(() => (
        <DashboardTemplate props={{ salesRepCode: "DMUL" }} />
      ));
    } else if (e.itemData.id === "4_4") {
      setItemComponent(() => (
        <DashboardTemplate props={{ salesRepCode: "JHOF" }} />
      ));
    } else if (e.itemData.id === "4_5") {
      setItemComponent(() => (
        <DashboardTemplate props={{ salesRepCode: "JTG" }} />
      ));
    } else if (e.itemData.id === "4_6") {
      setItemComponent(() => (
        <DashboardTemplate props={{ salesRepCode: "MGLN" }} />
      ));
    } else if (e.itemData.id === "4_7") {
      setItemComponent(() => (
        <DashboardTemplate props={{ salesRepCode: "NED" }} />
      ));
    } else if (e.itemData.id === "4_8") {
      setItemComponent(() => (
        <DashboardTemplate props={{ salesRepCode: "PLIP" }} />
      ));
    } else if (e.itemData.id === "4_9") {
      setItemComponent(() => (
        <DashboardTemplate props={{ salesRepCode: "RBJN" }} />
      ));
    } else if (e.itemData.id === "4_10") {
      setItemComponent(() => (
        <DashboardTemplate props={{ salesRepCode: "ROB" }} />
      ));
    } else if (e.itemData.id === "4_11") {
      setItemComponent(() => (
        <DashboardTemplate props={{ salesRepCode: "SCH" }} />
      ));
    } else if (e.itemData.id === "4_12") {
      setItemComponent(() => (
        <DashboardTemplate props={{ salesRepCode: "SOLO" }} />
      ));
    } else if (e.itemData.id === "4_13") {
      setItemComponent(() => (
        <DashboardTemplate props={{ salesRepCode: "TDAW" }} />
      ));
    }
  };

  return (
    <React.Fragment>
      <Toolbar className={"header-toolbar"}>
        <Item
          visible={true}
          location={"before"}
          locateInMenu={"auto"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <RBLogo fill={iconColor} className={"rb-logo"} />
        </Item>
      </Toolbar>
      <div className="form">
        <div className="parent-container">
          <Menu
            id="scroll"
            dataSource={itemMenu}
            displayExpr="name"
            showFirstSubmenuMode={showSubmenuModes[1]}
            orientation={"horizontal"}
            hideSubmenuOnMouseLeave={true}
            onItemClick={onItemClick}
          />
        </div>
      </div>
      <div className={"content-block dx-card responsive-paddings"}>
        {itemComponent}
      </div>
    </React.Fragment>
  );
}
