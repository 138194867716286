export { default as HomePage } from './home/home';
export { default as ProfilePage } from './profile/profile';
export { default as TasksPage } from './tasks/tasks';
export { default as QualitySuite } from './quality/suite';
export { default as QualityAudit } from './quality/audit/audit';
export { default as BamMainPage } from './bam/main';
export { default as WarehouseMainPage } from './warehouse/main';
export { default as PlanningMainPage } from './planning/main';
export { default as ProductionMainPage } from './production/main';

