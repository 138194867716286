
const menuItems = [
  {
    id: "1",
    name: "Dashboard",
    visible: true,
  },
  {
    id: "10",
    name: "Production",
    visible: true,
    items: [
      {
        id: "10_1",
        name: "Daily Production",
        visible: true,
      },
      {
        id: "10_2",
        name: "Monthly Production",
        visible: true,
      },
    ],
  },
  {
    id: "15",
    name: "Shipping",
    visible: true,
    items: [
      {
        id: "15_1",
        name: "Dashboard",
        visible: true,
      },
      {
        id: "15_2",
        name: "Invoices",
        visible: true,
      },
    ],
  },
  {
    id: "20",
    name: "Planning",
    visible: true,
    items: [
      {
        id: "20_1",
        name: "Open Order",
        visible: true,
      },
      {
        id: "20_10",
        name: "DPR",
        visible: true,
        items: [
          {
            id: "20_10_1",
            name: "Planner Report",
            visible: true,
          },
          {
            id: "20_10_2",
            name: "Export To Excel",
            visible: true,
          },
        ],
      },
      {
        id: "20_15",
        name: "Plant Capacity",
        visible: true,
      },
      {
        id: "20_20",
        name: "Process",
        visible: true,
        items: [
          {
            id: "20_20_1",
            name: "Textil",
            visible: true,
            items: [
              {
                id: "20_20_1_1",
                name: "Fabric",
                visible: true,
              },
              {
                id: "20_20_1_2",
                name: "Pigment Dye",
                visible: true,
              },
              {
                id: "20_20_1_3",
                name: "Mineral Wash",
                visible: true,
              },
              {
                id: "20_20_1_4",
                name: "Garment Wash",
                visible: true,
              },
              {
                id: "20_20_1_5",
                name: "Tie Dye",
                visible: true,
              },
            ],
          },
          {
            id: "20_20_10",
            name: "Cutting",
            visible: true,
          },
          {
            id: "20_20_20",
            name: "Sewing",
            visible: true,
            items: [
              {
                id: "20_20_20_1",
                name: "Sewing",
                visible: true,
              },
              {
                id: "20_20_20_2",
                name: "Heat Transfer",
                visible: true,
              },
              {
                id: "20_20_20_3",
                name: "Tackle Twill",
                visible: true,
              },
            ],
          },
          {
            id: "20_20_25",
            name: "Sublimation",
            visible: true,
          },
          {
            id: "20_20_30",
            name: "Screen Printing",
            visible: true,
            items: [
              {
                id: "20_20_30_1",
                name: "Printing",
                visible: true,
              },
              {
                id: "20_20_30_2",
                name: "Rapidtag",
                visible: true,
              },
            ],
          },
          {
            id: "20_20_40",
            name: "Packing",
            visible: true,
          },
          {
            id: "20_20_50",
            name: "Shipping",
            visible: true,
          },
        ],
      },
    ],
  },
  {
    id: "30",
    name: "Process",
    visible: true,
    items: [
      {
        id: "30_1",
        name: "Textil",
        visible: true,
        items: [
          {
            id: "30_1_1",
            name: "Fabric",
            visible: true,
          },
          {
            id: "30_1_2",
            name: "Garment Wash",
            visible: true,
          },
          {
            id: "30_1_2",
            name: "Garment Dye",
            visible: true,
          },
        ],
      },
      {
        id: "30_2",
        name: "Cutting",
        visible: false,
      },
      {
        id: "30_3",
        name: "Sewing",
        visible: false,
      },
      {
        id: "30_4",
        name: "Digital Print",
        visible: true,
        items: [
          {
            id: "30_4_1",
            name: "Daily Production",
            visible: true,
          },
          {
            id: "30_4_2",
            name: "Production Report",
            visible: true,
          },
          {
            id: "30_4_3",
            name: "Database",
            visible: true,
          },
        ],
      },
      {
        id: "30_5",
        name: "Screen Printing",
        visible: true,
        items: [
          {
            id: "30_5_1",
            name: "Daily Production",
            visible: true,
          },
          {
            id: "30_5_2",
            name: "Production Report",
            visible: true,
          },
          {
            id: "30_5_3",
            name: "Database",
            visible: true,
          },
        ],
      },
      {
        id: "30_6",
        name: "RapidTag",
        visible: true,
        items: [
          {
            id: "30_6_1",
            name: "Daily Production",
            visible: true,
          },
          {
            id: "30_6_2",
            name: "Production Report",
            visible: true,
          },
          {
            id: "30_6_3",
            name: "Database",
            visible: true,
          },
        ],
      },
      {
        id: "30_7",
        name: "Packing",
        visible: true,
        items: [
          {
            id: "30_7_1",
            name: "Daily Production",
            visible: true,
          },
          {
            id: "30_7_2",
            name: "Production Report",
            visible: true,
          },
          {
            id: "30_7_3",
            name: "Database",
            visible: true,
          },
        ],
      },
      {
        id: "30_8",
        name: "Shipping",
        visible: false,
      },
    ],
  },
];

export default {
  getMenuItem() {
    return menuItems;
  },
};