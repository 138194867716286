import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  Form,
  GroupItem,
  ButtonItem,
  ButtonOptions,
  Item,
  SimpleItem,
  Label,
  EmptyItem,
} from "devextreme-react/form";
import DataGrid, {
  Column,
  Lookup,
  Editing,
  Selection,
  Pager,
  Popup,
  Form as FormItem,
  Summary,
  Scrolling,
  TotalItem,
  Item as ItemBar,
  Toolbar,
  Paging,
  FilterRow,
} from "devextreme-react/data-grid";
import { Template } from "devextreme-react/core/template";
import DataSource from "devextreme/data/data_source";

import { qualityURL } from "../../../api/config";
import {
  arrayProcess,
  arrayQualityDefect,
  arrayOrderSize,
  updateSampling,
  dataArraySampling,
  deleteSampling,
  DSQualityStatus,
} from "../data";
import { useAudit } from "../contexts/audit.js";

export default function AuditSampling({ props, onSaveClick, onSaveSampling }) {
  const {
    pkAudit,
    auditArray,
    fkMasterOrder,
    dataGridVisible,
    setDataGridVisible,
    auditReadOnly,
    totalOrder,
    sampling, 
    setSampling,
    totalBoxes,
    arraySampling,
    setArraySampling,
    setAuditArray,
  } = useAudit();

  const [defectList, setDefectList] = useState([]);
  const [processList, setProcessList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [qualityStatus, setQualityStatus] = useState([]);

  const dataGridSampling = useRef(null);
  const notesEditorOptions = { height: 75 };

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      if (isSubscribed) {
        setDefectList(await arrayQualityDefect());
        setProcessList(await arrayProcess());
        setSizeList(await arrayOrderSize());
        setQualityStatus(await DSQualityStatus());
        setDataGridVisible(dataGridVisible);
        const arraySample = await dataArraySampling(pkAudit);
        setArraySampling(arraySample);
        /*const totalSample = arraySample.reduce((total, item) => total + item["DefectQuantity"], 0);
        setSampling(totalSample);*/
      }
    };
    fetchData().catch(console.error);
    return () => (isSubscribed = false);
  }, [pkAudit]);

  const dsSampling = new DataSource({
    store: {
      type: "array",
      key: "PK_AuditSampling",
      data: arraySampling,
    },
  });

  function refreshDsSampling() {
    dsSampling.reload();
  }

  const totalOrderEditorOptions = {
    label: "Total Order",
    value: totalOrder,
    visible: true,
  };
  const samplingEditorOptions = {
    label: "Sampling",
    //value: sampling,
    visible: false,
    readOnly: true,
  };
  const totalBoxesEditorOptions = {
    label: "Total Boxes",
    value: totalBoxes,
    visible: true,
  };
  const sizeEditorOptions = {
    label: "Size",
    dataSource: `${qualityURL}/order/size/${fkMasterOrder}`,
    valueExpr: "FK_Size",
    displayExpr: "SizeName",
    searchEnabled: true,
    readOnly: false,
  };
  const sampleEditorOptions = {
    label: "Sample",
    readOnly: true,
  };
  const defectEditorOptions = {
    label: "Defects",
    dataSource: defectList,
    valueExpr: "PK_Defect",
    displayExpr: "UQ_DefectCode",
    searchEnabled: true,
    readOnly: false,
    itemTemplate: "DefectItemTemplate",
  };
  const processBoxEditorOptions = {
    label: "Process",
    dataSource: processList,
    valueExpr: "PK_Process",
    displayExpr: "Process",
    searchEnabled: true,
    readOnly: false,
  };

  const renderDefect = (itemData) => {
    return (
      <div>
        <div style={{ display: "inline-block" }}>
          {itemData.UQ_DefectCode} - {itemData.DefectName}
        </div>
      </div>
    );
  };

  const getTotalSum = (columnName) => {
    return arraySampling.reduce((total, item) => total + item[columnName], 0);
  };

  const onGridUpdated = (e) => {
    /*const totalSample = getTotalSum("DefectQuantity");
    setSampling(totalSample);*/
    setAuditArray((prevAudit) => ({
      ...prevAudit,
    }));
  };

  const onGridSamplingSaved = async (e) => {
    if (pkAudit > 0 && e.changes[0]?.type === "update") {
      const changedData = e.changes[0]?.data;
      if (changedData.PK_AuditSampling) {
        const result = await updateSampling(changedData);
      }
    }
    if (pkAudit > 0 && e.changes[0]?.type === "insert") {
      const changedData = e.changes[0]?.data;
      const myJSON = JSON.stringify({
        FK_Audit: pkAudit,
        FK_Size: changedData.FK_Size,
        Sample: changedData.Sample,
        FK_Defect: changedData.FK_Defect,
        FK_Process: changedData.FK_Process,
        DefectQuantity: changedData.DefectQuantity,
        Remarks: changedData.Remarks,
        FK_Status: changedData.FK_Status,
      });
      await fetch(`${qualityURL}/audit/sampling/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: myJSON,
      });
      setArraySampling(await dataArraySampling(pkAudit));
    }
  };

  const onGridSamplingDeleting = async (e) => {
    const PK_AuditSampling = e.data.PK_AuditSampling;
    if (e.data.PK_AuditSampling) {
      const result = await deleteSampling(PK_AuditSampling);
    }
  };

  function onFieldDataChanged(e) {
    //onSaveClick(auditSampling);
  }

  return (
    <React.Fragment>
      <span>Order Information & Sampling</span>
      <hr />
      <Form
        formData={auditArray}
        readOnly={false}
        labelMode="floating"
        labelLocation="top"
        minColWidth={300}
        visible={dataGridVisible}
        onFieldDataChanged={onFieldDataChanged}
      >
        <GroupItem colCount={2}>
          <SimpleItem
            dataField="TotalOrder"
            editorType="dxNumberBox"
            editorOptions={totalOrderEditorOptions}
          />
          <SimpleItem
            dataField="Sampling"
            editorType="dxNumberBox"
            editorOptions={samplingEditorOptions}
            visible={false}
          />
          <SimpleItem
            dataField="TotalBoxes"
            editorType="dxNumberBox"
            editorOptions={totalBoxesEditorOptions}
          />
        </GroupItem>
      </Form>
      <DataGrid
        //id="gridContainerPurple"
        ref={dataGridSampling}
        dataSource={dsSampling}
        hoverStateEnabled={true}
        height="100%"
        showColumnHeaders={true}
        showBorders={true}
        onSaved={onGridSamplingSaved}
        onRowRemoving={onGridSamplingDeleting}
        onRowInserted={onGridUpdated}
        onRowUpdated={onGridUpdated}
        onRowRemoved={onGridUpdated}
        visible={dataGridVisible}
        repaintChangesOnly={true}
      >
        <Editing
          refreshMode={"repaint"}
          mode="popup"
          allowAdding={!auditReadOnly}
          allowDeleting={!auditReadOnly}
          allowUpdating={!auditReadOnly}
        >
          <Popup
            title="Sampling Info"
            showTitle={true}
            width={700}
            height={550}
          />
          <FormItem>
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item
                dataField="FK_Size"
                editorType="dxSelectBox"
                editorOptions={sizeEditorOptions}
              />
              <Item
                dataField="Sample"
                editorType="dxNumberBox"
                visible={false}
                editorOptions={sampleEditorOptions}
              />
              <Item
                dataField="FK_Defect"
                editorType="dxSelectBox"
                editorOptions={defectEditorOptions}
              />
              <Template name="DefectItemTemplate" render={renderDefect} />
              <Item
                dataField="FK_Process"
                editorType="dxSelectBox"
                editorOptions={processBoxEditorOptions}
              />
              <Item dataField="DefectQuantity" />
              <Item dataField="FK_Status" />
              <Item
                dataField="Remarks"
                editorType="dxTextArea"
                colSpan={2}
                editorOptions={notesEditorOptions}
              />
            </Item>
          </FormItem>
        </Editing>
        <Selection mode="single" />
        <Scrolling mode="standard" />
        <Column
          dataField="PK_AuditSampling"
          caption="ID"
          dataType="integer"
          visible={false}
        />
        <Column
          dataField="FK_Audit"
          caption="Fk Audit"
          dataType="integer"
          value={pkAudit}
          visible={false}
        />
        <Column
          dataField="FK_Size"
          caption="Size"
          dataType="number"
          visible={true}
        >
          <Lookup
            dataSource={sizeList}
            valueExpr="PK_Size"
            displayExpr="SizeName"
          />
        </Column>
        <Column
          dataField="Sample"
          caption="Sample"
          dataType="integer"
          visible={false}
        />
        <Column
          dataField="FK_Defect"
          caption="Defects"
          dataType="string"
          visible={true}
        >
          <Lookup
            dataSource={defectList}
            valueExpr="PK_Defect"
            displayExpr="UQ_DefectCode"
          />
        </Column>
        <Column
          dataField="FK_Process"
          caption="Process"
          dataType="string"
          visible={true}
        >
          <Lookup
            dataSource={processList}
            valueExpr="PK_Process"
            displayExpr="Process"
          />
        </Column>
        <Column
          dataField="Remarks"
          caption="Remarks"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="DefectQuantity"
          caption="Total"
          dataType="number"
          visible={true}
        />
        <Column
          dataField="FK_Status"
          caption="Status"
          dataType="number"
          visible={true}
        >
          <Lookup
            dataSource={qualityStatus}
            valueExpr="PK_Status"
            displayExpr="Status"
          />
        </Column>
      </DataGrid>
    </React.Fragment>
  );
}
