import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import TextArea from "devextreme-react/text-area";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import DropDownBox from "devextreme-react/drop-down-box";
import DataSource from "devextreme/data/data_source";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { SpeedDialAction } from "devextreme-react/speed-dial-action";
import List from "devextreme-react/list";
import { Button } from "devextreme-react/button";
import Gallery from "devextreme-react/gallery";
import FileUploader from "devextreme-react/file-uploader";
import { Template } from "devextreme-react/core/template";
import notify from "devextreme/ui/notify";
import {
  Form,
  GroupItem,
  ButtonItem,
  ButtonOptions,
  Item,
  SimpleItem,
  Label,
  EmptyItem,
} from "devextreme-react/form";
import DataGrid, {
  Column,
  Lookup,
  Editing,
  Selection,
  Pager,
  Popup,
  Form as FormItem,
  Summary,
  Scrolling,
  TotalItem,
  Item as ItemBar,
  Toolbar,
  Paging,
  FilterRow,
} from "devextreme-react/data-grid";

import "./final.scss";
import { useTheme } from "../../contexts/theme";
import { qualityURL, auditImageUploadURL } from "../../api/config";
import {
  dataSourceMasterOrder,
  dataSourceChecklist,
  accessRole,
  preferences,
  DSAuditType,
  DSSuperviser,
  DSShift,
  DSQualityLevel,
  DSWorkStation,
  DSQualityStatus,
  dataArraySampling,
  dataArrayMeasure,
  dataArrayMeasureDetail,
  saveAuditData,
  updateSampling,
  deleteSampling,
  arrayAudit,
  arrayProcess,
  arrayOrderSize,
  arrayQualityDefect,
  arrayMeasurement,
  updateMeasure,
  deleteMeasure,
  updateMeasureDetail,
  deleteMeasureDetail,
} from "./data";
import dxNumberBox from "devextreme/ui/number_box";
import ArrayStore from "devextreme/data/array_store";
import dxSelectBox from "devextreme/ui/select_box";
import imageItem from "./item.js";
const preSelectedItems = [{ key: "Garment - Gender", items: ["Men", "Women"] }];
const preSelectedKeys = preSelectedItems.flatMap((item) => item.items);

export default function FinalAudit({ props }) {
  const [theme, setTheme] = useState("orange.light");
  const [gridBoxValue, setGridBoxValue] = useState([]);
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const [isDropBoxVisible, setIsDropBoxVisible] = useState(true);
  const [masterColumns, setMasterColumns] = useState([]);
  const [master, setMaster] = useState([]);
  const [visible, setVisible] = useState(false);
  const [dataVisible, setDataVisible] = useState(false);
  const [process, setProcess] = useState("Packing");
  const [auditType, setAuditType] = useState([]);
  const [qualityLevel, setQualityLevel] = useState([]);
  const [qualityStatus, setQualityStatus] = useState([]);
  const [superviser, setSuperviser] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [fkSuperviser, setFkSuperviser] = useState();
  const [role, setRole] = useState([]);
  const [shift, setShift] = useState([]);
  const [measurementList, setMeasurementList] = useState([]);
  const [orderSize, setOrderSize] = useState([]);
  const [workStation, setWorkStation] = useState([]);
  const [defectList, setDefectList] = useState([]);
  const [processList, setProcessList] = useState([]);
  const [showLabel, setShowLabel] = useState("Show less...");
  const [collapsedGroup, setCollapsedGroup] = useState(false);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);
  const [isCheckListChecked, setIsCheckListChecked] = useState(true);
  const [checklistDisabled, setChecklistDisabled] = useState(false);
  const [auditChecklist, setAuditChecklist] = useState([]);
  const [targetElement, setTargetElement] = useState(null);
  const [calculateColCountAutomatically, setCalculateColCountAutomatically] =
    useState(true);
  const [Audit, setAudit] = useState([]);
  const [auditReadOnly, setAuditReadOnly] = useState(false);
  const [fkAuditor, setFkAuditor] = useState(props.User.idUser);
  const [pkAudit, setPkAudit] = useState(-1);
  const [pkAuditToLoad, setPkAuditToLoad] = useState(null);
  const [fkFacility, setFkFacility] = useState(props.FKFacility);
  const [fkProcess, setFkProcess] = useState(props.FKProcess);
  const [fkAuditType, setFkAuditType] = useState(2);
  const [dateAudit, setDateAudit] = useState(new Date());
  const [fkQualityLevel, setFkQualityLevel] = useState(1);
  const [fkShift, setFkShift] = useState(1);
  const [fkMasterOrder, setFkMasterOrder] = useState();
  const [fkWorkStation, setFkWorkStation] = useState(1);
  const [totalOrder, setTotalOrder] = useState(0);
  const [sampling, setSampling] = useState(0);
  const [totalBoxes, setTotalBoxes] = useState(0);
  const [status, setStatus] = useState(1);
  const [remarks, setRemarks] = useState();
  const [index, setIndex] = useState(0);
  const [idImage, setIdImage] = useState(null);
  const [auditFinish, setAuditFinish] = useState(false);

  const [dataSourceList, setDataSourceList] = useState([]);
  const [dataSourceSampling, setDataSourceSampling] = useState([]);
  const [dataSourceMeasure, setDataSourceMeasure] = useState([]);
  const [dataSourceMeasureDetail, setDataSourceMeasureDetail] = useState([]);
  const [newPkAuditMeasure, setNewPkAuditMeasure] = useState(-1);
  const [pkAuditMeasure, setPkAuditMeasure] = useState(-1);
  const [detailVisible, setDetailVisible] = useState(false);
  const notesEditorOptions = { height: 75 };

  const getDetail = (key) =>
    new DataSource({
      store: new ArrayStore({
        data: dataSourceMeasureDetail,
        key: "PK_AuditMeasureDetail",
      }),
      filter: ["FK_AuditMeasure", "=", key],
    });
  const dataSource = getDetail(pkAuditMeasure);

  const { getTheme } = useTheme();

  const dropBox = useRef(null);
  const formOrder = useRef(null);
  const checkList = useRef(null);
  const dataGridSampling = useRef(null);
  const colCountByScreen = {
    sm: 2,
    md: 4,
  };
  const auditor = [
    {
      PKUser: props.User.idUser,
      Name: props.User.FirstName + " " + props.User.LastName,
    },
  ];

  function onContentReady(e) {
    var items = e.component.option("items");
    for (var i = 0; i < items.length; i++) e.component.collapseGroup(i);
    setCollapsedGroup(true);
  };

  const onSelectedItemKeysChange = useCallback(
    ({ name, value }) => {
      if (name === "selectedItemKeys") {
        //console.log(value)
        setSelectedItemKeys(value);

        setAudit((prevAudit) => ({
          ...prevAudit,
        }));
      }
    },
    [selectedItemKeys, setSelectedItemKeys]
  );

  async function onSelectionChanged(e) {
    const addedItems = e.addedItems;
    const removedItems = e.removedItems;
    if (pkAudit > 0 && addedItems.length > 0 && !auditReadOnly) {
      const myJSON = JSON.stringify({
        FK_Audit: pkAudit,
        FK_Checklist: addedItems[0].id,
      });
      await fetch(`${qualityURL}/audit/checklist/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: myJSON,
      });
    }
    if (pkAudit > 0 && removedItems.length > 0 && !auditReadOnly) {
      const myJSON = JSON.stringify({
        FK_Audit: pkAudit,
        FK_Checklist: removedItems[0].id,
      });
      await fetch(`${qualityURL}/audit/delete/checklist/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: myJSON,
      });
    }
  }

  function refreshMasterOrder() {
    dataSourceMasterOrder.reload();
  }

  const validateForm = useCallback((e) => {
    e.component.validate();
  }, []);

  const imageDataSource = createStore({
    keyExpr: "PK_AuditImage",
    loadUrl: `${qualityURL}/audit/image/${pkAudit}`,
  });

  const galleryDataSource = new DataSource({
    store: imageDataSource,
    requireTotalCount: true,
  });

  function refresImageData() {
    galleryDataSource
      .reload()
      .then(() => {
        setIndex(galleryDataSource.items().length);
      })
      .catch((error) => {
        console.error("Error al recargar datos:", error);
      });
  }

  function onItemClick(e) {
    setIdImage(e.itemData.PK_AuditImage);
  }

  useEffect(() => {
    setTargetElement(document.querySelector(".open-button"));
    async function result() {
      setRole(await accessRole(props.User.idUser));
      setChecklistDisabled(
        (await preferences(props.User.idUser, 1)) === 1 ? false : true
      );
      setAuditType(await DSAuditType());
      setSuperviser(await DSSuperviser());
      setFkSuperviser(await preferences(props.User.idUser, 2));
      setQualityLevel(await DSQualityLevel());
      setShift(await DSShift(props.FKFacility, props.FKProcess));
      setSizeList(await arrayOrderSize());
      setWorkStation(await DSWorkStation(props.FKFacility, props.FKProcess));
      setQualityStatus(await DSQualityStatus());
      setDataSourceList(await dataSourceChecklist(props.FKProcess));
      setDefectList(await arrayQualityDefect());
      setProcessList(await arrayProcess());
      setMeasurementList(await arrayMeasurement());
      if (!props.pkAuditToLoad) {
        setDataSourceSampling(await dataArraySampling(pkAudit));
        setDataSourceMeasure(await dataArrayMeasure(pkAudit));
      }
    }
    result();
    if (props.pkAuditToLoad) {
      async function onOverviewLoad() {
        const audit = await arrayAudit(props.pkAuditToLoad);
        loadAudit(audit[0]);
        setAuditReadOnly(props.readOnly);
      }
      onOverviewLoad();
    } else {
      onNewClick();
    }
    refreshDsSampling();
    refresImageData();
  }, []);

  useEffect(() => {
    fetch(`${qualityURL}/columnsearch`)
      .then((res) => res.json())
      .then((data) => {
        const valoresColumna = data.map((row) => row.MasterColumn);
        setMasterColumns(valoresColumna);
      })
      .catch((error) => console.error("Error fetching columns data:", error));
  }, []);

  let currentTheme = getTheme();

  useEffect(() => {
    function themeResult() {
      setTheme(currentTheme);
      localStorage.setItem("lastScreen", "FinalAudit");
    }
    themeResult();
  }, [currentTheme]);

  const dsSampling = new DataSource({
    store: {
      type: "array",
      key: "PK_AuditSampling",
      data: dataSourceSampling,
    },
  });

  function refreshDsSampling() {
    dsSampling.reload();
  }

  function checkboxClassName() {
    switch (theme) {
      case "orange.light":
        return "orange-light-mode-checkbox";
      case "orange.dark":
        return "orange-dark-mode-checkbox";
      case "blue.light":
        return "blue-light-mode-checkbox";
      case "blue.dark":
        return "blue-dark-mode-checkbox";
      case "purple.light":
        return "purple-light-mode-checkbox";
      case "purple.dark":
        return "purple-dark-mode-checkbox";
    }
  }

  function OnSearchValueChanged(e) {
    if (e.value[0] != null) {
      fetch(`${qualityURL}/order/${e.value[0]}`)
        .then((res) => res.json())
        .then((data) => {
          setMaster(data[0]);
          setFkMasterOrder(data[0].PK_Master);
          setTotalOrder(data[0].TotalOrder);
          expandGroup();
          setCollapsedGroup(false);
          setIsCheckListChecked(true);
          setDataVisible(true);
        })
        .catch((error) => console.error("Error fetching columns data:", error));
    } else {
      collapseGroup();
      setCollapsedGroup(true);
      setDataVisible(false);
    }
  }

  function onNewClick() {
    setPkAudit(-1);
    setGridBoxValue([]);
    setMaster([]);
    setFkFacility(props.FKFacility);
    setFkAuditType(2);
    setFkProcess(props.FKProcess);
    setDateAudit(new Date());
    setFkSuperviser(fkSuperviser);
    setFkQualityLevel(1);
    setFkShift(1);
    setFkWorkStation(1);
    setIsCheckListChecked(true);
    setTotalOrder(0);
    setSampling(0);
    setTotalBoxes(0);
    setStatus(1);
    setRemarks(null);
    setAuditFinish(false);
    setSelectedItemKeys((prevSelectedItemKeys) => []);
    setDataSourceSampling([]);
    setDataSourceMeasure([]);
    setDataSourceMeasureDetail([]);
    setPkAuditToLoad(null);
    setAuditReadOnly(false);
  }

  async function onSaveClick() {
    const result = await saveAuditData(
      pkAudit,
      Audit,
      selectedItemKeys,
      dataSourceSampling,
      dataSourceMeasure,
      dataSourceMeasureDetail
    );
    setPkAudit(result);
    const audit = await arrayAudit(result);
    loadAudit(audit[0]);
    const message =
      pkAudit > 0
        ? "Update successfully completed"
        : "Saved successfully completed";
    notify({ message: message, shading: true }, "success", 1000);
  }

  function editRow() {
    console.log("420 - editRow", selectedItemKeys);
  }

  async function loadAudit(props) {
    setPkAudit(props.PK_Audit);
    setRole(await accessRole(props.FK_Auditor));
    fetch(`${qualityURL}/order/${props.FK_MasterOrder}`)
      .then((res) => res.json())
      .then((data) => {
        setMaster(data[0]);
        setFkMasterOrder(data[0].PK_Master);
        setTotalOrder(data[0].TotalOrder);
        expandGroup();
        setCollapsedGroup(false);
        setIsCheckListChecked(true);
        setDataVisible(true);
      });

      fetch(`${qualityURL}/audit/checklist/${props.PK_Audit}`)
        .then((res) => res.json())
        .then((data) => {
          const fkChecklistValues = data.map((item) => item.FK_Checklist);
          setSelectedItemKeys(fkChecklistValues);
        });

    setFkAuditType(props.FK_AuditType);
    setFkProcess(props.FK_Process);
    setFkFacility(props.FK_Facility);
    setDateAudit(props.Date_Audit);
    setFkMasterOrder(props.FK_MasterOrder);
    setFkAuditor(props.FK_Auditor);
    setFkSuperviser(props.FK_Superviser);
    setFkQualityLevel(props.FK_QualityLevel);
    setFkShift(props.FK_Shift);
    setIsCheckListChecked(props.isChecklist);
    setFkWorkStation(props.FK_WorkStation);
    setTotalOrder(props.TotalOrder);
    setSampling(props.Sampling);
    setTotalBoxes(props.TotalBoxes);
    setStatus(props.FK_Status);
    setRemarks(props.Remarks);
    setAuditFinish(props.isClosed);
    setDataSourceSampling(await dataArraySampling(props.PK_Audit));
    setDataSourceMeasure(await dataArrayMeasure(props.PK_Audit));
    setDataSourceMeasureDetail(await dataArrayMeasureDetail(props.PK_Audit));
  }

  const onGridSamplingSaving = async (e) => {
    if (e.changes[0]?.type === "update") {
      const changedData = e.changes[0]?.data;
      if (changedData.PK_AuditSampling) {
        const result = await updateSampling(changedData);
      }
    }
    if (pkAudit > 0 && e.changes[0]?.type === "insert") {
      const changedData = e.changes[0]?.data;
      const myJSON = JSON.stringify({
        FK_Audit: pkAudit,
        FK_Size: changedData.FK_Size,
        Sample: changedData.Sample,
        FK_Defect: changedData.FK_Defect,
        FK_Process: changedData.FK_Process,
        DefectQuantity: changedData.DefectQuantity,
        Remarks: changedData.Remarks,
        FK_Status: changedData.FK_Status,
      });
      await fetch(`${qualityURL}/audit/sampling/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: myJSON,
      });
      setDataSourceSampling(await dataArraySampling(pkAudit));
    }
  };

  const onGridSamplingDeleting = async (e) => {
    const PK_AuditSampling = e.data.PK_AuditSampling;
    if (e.data.PK_AuditSampling) {
      const result = await deleteSampling(PK_AuditSampling);
    }
  };

  const onGridMeasureSaving = async (e) => {
    if (e.changes[0]?.type === "update") {
      const changedData = e.changes[0]?.data;
      if (changedData.PK_AuditMeasure) {
        const result = await updateMeasure(changedData);
      }
    }
    if (pkAudit > 0 && e.changes[0]?.type === "insert") {
      const changedData = e.changes[0]?.data;
      const myJSON = JSON.stringify({
        FK_Audit: pkAudit,
        FK_Size: changedData.FK_Size,
      });
      await fetch(`${qualityURL}/audit/measure/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: myJSON,
      });
      setDataSourceMeasure(await dataArrayMeasure(pkAudit));
    }
  };

  const onGridMeasureDeleting = async (e) => {
    const PK_AuditMeasure = e.data.PK_AuditMeasure;
    if (e.data.PK_AuditMeasure) {
      const result = await deleteMeasure(PK_AuditMeasure);
      setDataSourceMeasureDetail(await dataArrayMeasureDetail(pkAudit));
    }
  };

  const onGridMeasureDetailDeleting = async (e) => {
    const PK_AuditMeasureDetail = e.data.PK_AuditMeasureDetail;
    if (e.data.PK_AuditMeasureDetail) {
      const result = await deleteMeasureDetail(PK_AuditMeasureDetail);
    }
  };

  function onMeasureInitNewRow(e) {
    e.data.PK_AuditMeasure = newPkAuditMeasure;
    setNewPkAuditMeasure(newPkAuditMeasure - 1);
  }

  function onMeasureChanged({ selectedRowsData }) {
    const data = selectedRowsData[0];
    if (data) {
      setPkAuditMeasure(data.PK_AuditMeasure);
      setDetailVisible(true);
    }
  }

  function onMeasureDetailInitNewRow(e) {
    e.data.FK_AuditMeasure = pkAuditMeasure;
  }

  const onSavedMeasureDetail = async (e) => {
    if (e.changes[0]?.type === "update") {
      const changedData = e.changes[0]?.data;
      if (changedData.PK_AuditMeasureDetail) {
        const result = await updateMeasureDetail(changedData);
      }
    }
    if (pkAudit > 0 && e.changes[0]?.type === "insert") {
      const changedData = e.changes[0]?.data;
      const myJSON = JSON.stringify({
        FK_AuditMeasure: changedData.FK_AuditMeasure,
        FK_Measure: changedData.FK_Measure,
        Specs: changedData.Specs,
        Tolerance: changedData.Tolerance,
        RealValue: changedData.RealValue,
      });
      await fetch(`${qualityURL}/audit/measure/detail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: myJSON,
      });
      setDataSourceMeasureDetail(await dataArrayMeasureDetail(pkAudit));
    }
  };

  function showLessColumns() {
    formOrder.current.instance.itemOption("Status", "visible", visible);
    formOrder.current.instance.itemOption("PODate", "visible", visible);
    formOrder.current.instance.itemOption("ExFactory", "visible", visible);
    formOrder.current.instance.itemOption("PriorityWeek", "visible", visible);
    formOrder.current.instance.itemOption("DesignName", "visible", visible);
    formOrder.current.instance.itemOption("Store", "visible", visible);
    setVisible(!visible);
    setShowLabel(visible ? "Show less...." : "Show more...");
  }

  async function onClickfinish() {
    await fetch(`${qualityURL}/audit/finish/${pkAudit}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(setAuditFinish(true))
      .catch((error) => {
        console.error(error);
      });
  }

  const collapseGroup = () => {
    if (checkList.current) {
      for (var i = 0; i < 10; i++) checkList.current.instance.collapseGroup(i);
      setCollapsedGroup(true);
    }
  };
  const expandGroup = () => {
    if (checkList.current) {
      for (var i = 0; i < 10; i++) checkList.current.instance.expandGroup(i);
      setCollapsedGroup(false);
    }
  };

  const handleCheckListCheckboxChange = (event) => {
    setIsCheckListChecked(event.target.checked);
    if (event.target.checked === false) {
      collapseGroup();
    }

    setAudit((prevAudit) => ({
      ...prevAudit,
    }));
  };

  const addIdParameter = (e) => {
    const uploadUrl = updateQueryStringParameter(
      e.component.option("uploadUrl"),
      "FK_Audit",
      pkAudit
    );
    e.component.option("uploadUrl", uploadUrl);
  };

  const updateQueryStringParameter = (uri, key, value) => {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
    const separator = uri.indexOf("?") !== -1 ? "&" : "?";

    if (uri.match(re)) {
      return uri.replace(re, `$1${key}=${value}$2`);
    } else {
      return uri + separator + `${key}=${value}`;
    }
  };

  async function onUploaded(e) {
    const imagePath = `http://192.168.2.4:8087/Genisys/Image_Art/Quality/${pkAudit}-${e.file.name}`;
    const myJSON = JSON.stringify({
      FK_Audit: pkAudit,
      ImagePath: imagePath,
      isDelete: false,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: myJSON,
    };
    try {
      const response = await fetch(`${qualityURL}/audit/image`, requestOptions);
      if (response.ok) {
        refresImageData();
      }
    } catch (error) {
      console.log(error);
    }
  }

  function onClickDelete() {
    if (idImage) {
      fetch(`${qualityURL}/audit/image/${idImage}`, { method: "DELETE" })
        .then(() => {
          setIdImage(null);
          notify(
            { message: "Delete successfully completed", shading: true },
            "success",
            1000
          );
        })
        .catch((error) => {
          notify({ message: "Delete Error", shading: true }, "error", 1000);
        });
    } else {
      notify("Pleaes click an image to delete", "error", 1000);
    }
  }

  const pkAuditEditorOptions = {
    label: "PkAudit",
    value: pkAudit,
    visible: false,
  };
  const facilityEditorOptions = {
    label: "Facility",
    value: fkFacility,
    visible: false,
  };
  const processEditorOptions = {
    label: "Process",
    value: fkProcess,
    visible: false,
  };
  const dateAuditEditorOptions = {
    label: "Date Audit",
    value: dateAudit,
    visible: false,
  };
  const masterEditorOptions = {
    label: "Master",
    value: fkMasterOrder,
    visible: false,
  };
  const checklistEditorOptions = {
    value: isCheckListChecked,
    visible: false,
  };
  const totalOrderEditorOptions = {
    label: "Total Order",
    value: totalOrder,
    visible: true,
  };
  const samplingEditorOptions = {
    label: "Sampling",
    value: sampling,
    visible: true,
  };
  const totalBoxesEditorOptions = {
    label: "Total Boxes",
    value: totalBoxes,
    visible: true,
  };
  const auditorEditorOptions = {
    label: "Auditor",
    dataSource: auditor,
    valueExpr: "PKUser",
    displayExpr: "Name",
    searchEnabled: true,
    value: fkAuditor,
    readOnly: true,
  };
  const auditTypeEditorOptions = {
    label: "Audit Type",
    dataSource: auditType,
    valueExpr: "PK_AuditType",
    displayExpr: "AuditType",
    searchEnabled: true,
    value: fkAuditType,
    readOnly: true,
  };
  const superviserEditorOptions = {
    label: "Superviser",
    dataSource: superviser,
    valueExpr: "PK_User",
    displayExpr: "SuperviserName",
    searchEnabled: true,
    value: fkSuperviser,
    readOnly: false,
  };
  const qualityLevelEditorOptions = {
    dataSource: qualityLevel,
    valueExpr: "PK_QualityLevel",
    displayExpr: "QualityLevel",
    searchEnabled: true,
    value: fkQualityLevel,
    format: {
      type: "fixedPoint",
      precision: 3,
    },
    readOnly: false,
  };
  const shiftEditorOptions = {
    label: "Shift",
    dataSource: shift,
    valueExpr: "PK_Shift",
    displayExpr: "Shift",
    searchEnabled: true,
    value: fkShift,
    readOnly: false,
  };
  const workStationEditorOptions = {
    label: "Work Station",
    dataSource: workStation,
    valueExpr: "PK_WorkStation",
    displayExpr: "WorkStation",
    searchEnabled: true,
    value: fkWorkStation,
    readOnly: false,
  };
  const statusEditorOptions = {
    label: "Status",
    dataSource: qualityStatus,
    valueExpr: "PK_Status",
    displayExpr: "Status",
    searchEnabled: true,
    value: status,
    readOnly: false,
  };
  const remarksEditorOptions = {
    label: "Remarks",
    value: remarks,
    readOnly: false,
  };
  const sizeEditorOptions = {
    label: "Size",
    dataSource: `${qualityURL}/order/size/${fkMasterOrder}`,
    valueExpr: "FK_Size",
    displayExpr: "SizeName",
    searchEnabled: true,
    readOnly: false,
  };
  const sampleEditorOptions = {
    label: "Sample",
    readOnly: false,
  };
  const defectEditorOptions = {
    label: "Defects",
    dataSource: defectList,
    valueExpr: "PK_Defect",
    displayExpr: "UQ_DefectCode",
    searchEnabled: true,
    readOnly: false,
    itemTemplate: "DefectItemTemplate",
  };
  const processBoxEditorOptions = {
    label: "Process",
    dataSource: processList,
    valueExpr: "PK_Process",
    displayExpr: "Process",
    searchEnabled: true,
    readOnly: false,
  };
  const measureEditorOptions = {
    label: "Measurement",
    dataSource: measurementList,
    valueExpr: "PK_Measure",
    displayExpr: "Measure",
    searchEnabled: true,
    value: null,
    readOnly: false,
  };

  const renderDefect = (itemData) => {
    return (
      <div>
        <div style={{ display: "inline-block" }}>
          {itemData.UQ_DefectCode} - {itemData.DefectName}
        </div>
      </div>
    );
  };

  //////////////////////////DROP BOX ////////////////////////////
  const ownerLabel = { "aria-label": "Owner" };
  const gridColumns = masterColumns;

  function dataGridRender() {
    return (
      <DataGrid
        dataSource={dataSourceMasterOrder}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={gridBoxValue}
        columnAutoWidth={true}
        onSelectionChanged={dataGridOnSelectionChanged}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="standard" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={true}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
    );
  }

  function gridBoxDisplayExpr(item) {
    return item && `ID: ${item.PK_Master} <PO: ${item.PO}>`;
  }

  function syncDataGridSelection(e) {
    setGridBoxValue(e.value);
    setDataSourceMeasure([]);
    setDataSourceMeasureDetail([]);
    setDetailVisible(false);
    setNewPkAuditMeasure(-1);
    if (e.value) OnSearchValueChanged(e);
    else {
      setMaster([]);
      setDataSourceSampling([]);
      setDataSourceMeasure([]);
      setDataSourceMeasureDetail([]);
      setCollapsedGroup(false);
      collapseGroup();
      setDataVisible(false);
      setDetailVisible(false);
    }
  }

  function onGridBoxOpened(e) {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }

  function dataGridOnSelectionChanged(e) {
    setGridBoxValue(e.selectedRowKeys);
    setIsGridBoxOpened(false);
  }

  ////////////////////////////////////////////////////////////////

  return (
    <React.Fragment>
      <div className="sidebyside-container">
        <div className="leftside">
          <h6 className={"content-block"}>
            {props.Process} Final Audit: {pkAudit}
          </h6>
        </div>
        <div className="rightside">Access: {role.Role}</div>
        <div className="rightside">|</div>
        <div className="rightside">
          Status: {auditFinish ? "CLOSED" : "OPEN"}
        </div>
      </div>
      <DropDownBox
        ref={dropBox}
        dataSource={dataSourceMasterOrder}
        value={gridBoxValue}
        opened={isGridBoxOpened}
        valueExpr={"PK_Master"}
        deferRendering={false}
        inputAttr={ownerLabel}
        displayExpr={gridBoxDisplayExpr}
        placeholder="Select an order..."
        showClearButton={true}
        onValueChanged={syncDataGridSelection}
        onOptionChanged={onGridBoxOpened}
        contentRender={dataGridRender}
        disabled={false}
        visible={isDropBoxVisible}
        onOpened={refreshMasterOrder}
      />
      <Form>
        <EmptyItem />
      </Form>
      <Form
        ref={formOrder}
        formData={master}
        readOnly={true}
        labelMode="floating"
        labelLocation="top"
        colCountByScreen={
          calculateColCountAutomatically ? null : colCountByScreen
        }
        minColWidth={300}
        colCount="auto"
        screenByWidth={screenByWidth}
      ></Form>
      <div className="sidebyside-container">
        <div className="leftside">Auditor Information</div>
      </div>
      <hr />
      <Form
        formData={Audit}
        readOnly={false}
        labelMode="floating"
        labelLocation="top"
        minColWidth={300}
      >
        <GroupItem colCount={2}>
          <GroupItem>
            <Item
              dataField="FK_Auditor"
              editorType="dxSelectBox"
              editorOptions={auditorEditorOptions}
            />
            <Item
              dataField="FK_AuditType"
              editorType="dxSelectBox"
              editorOptions={auditTypeEditorOptions}
            />
            <Item
              dataField="FK_Shift"
              editorType="dxSelectBox"
              editorOptions={shiftEditorOptions}
            />
          </GroupItem>
          <GroupItem>
            <Item
              dataField="FK_Superviser"
              editorType="dxSelectBox"
              editorOptions={superviserEditorOptions}
            />
            <Item
              dataField="FK_QualityLevel"
              editorType="dxSelectBox"
              editorOptions={qualityLevelEditorOptions}
            />
            <Item
              dataField="FK_WorkStation"
              editorType="dxSelectBox"
              editorOptions={workStationEditorOptions}
            />
          </GroupItem>
        </GroupItem>
      </Form>
      <br />
      <div className="sidebyside-container">
        <span className="leftside">Checklist</span>
        <div className="rightside">
          <input
            id="cbchecklist"
            type="checkbox"
            name="cb-checklist"
            value={"checklist"}
            checked={isCheckListChecked}
            onChange={handleCheckListCheckboxChange}
            className={checkboxClassName()}
            disabled={checklistDisabled}
          />
        </div>
      </div>
      <hr />
      <Form>
        <GroupItem className="list-container">
          <List
            ref={checkList}
            dataSource={dataSourceList}
            height="400"
            grouped={true}
            selectionMode={"multiple"}
            selectedItemKeys={selectedItemKeys}
            showSelectionControls={true}
            collapsibleGroups={true}
            onContentReady={onContentReady}
            onOptionChanged={onSelectedItemKeysChange}
            onSelectionChanged={onSelectionChanged}
            selectByClick={true}
            visible={isCheckListChecked}
          />
        </GroupItem>
      </Form>
      <br />

      <SpeedDialAction
        icon="add"
        label="New Audit"
        index={1}
        visible={true}
        onClick={onNewClick}
      />
      <SpeedDialAction
        icon="check"
        label={"Save"}
        index={2}
        visible={
          Object.keys(master).length > 0
            ? role.FK_Role > 2
              ? auditReadOnly
                ? false
                : true
              : false
            : false
        }
        onClick={onSaveClick}
      />
      <SpeedDialAction
        icon="trash"
        label="Delete Audit"
        index={3}
        visible={
          Object.keys(master).length > 0
            ? role.FK_Role > 4
              ? auditReadOnly
                ? false
                : true
              : false
            : false
        }
        //onClick={deleteRow}
      />
      <SpeedDialAction
        icon="edit"
        label="Edit audit"
        index={4}
        visible={
          Object.keys(master).length > 0
            ? role.FK_Role > 2
              ? true //true
              : false
            : false
        }
        onClick={editRow}
      />
      <SpeedDialAction
        icon="checklist"
        label={collapsedGroup ? "Expanse Checklist" : "Collapse Checklist"}
        index={5}
        visible={Object.keys(master).length > 0 ? true : false}
        onClick={collapsedGroup ? expandGroup : collapseGroup}
      />
      <SpeedDialAction
        icon="fieldchooser"
        label={showLabel}
        index={6}
        visible={Object.keys(master).length > 0 ? true : false}
        onClick={showLessColumns}
      />
      <SpeedDialAction
        icon="bookmark"
        label={"Finish Audit"}
        index={7}
        visible={pkAudit > 0 ? (auditReadOnly ? false : true) : false}
        onClick={onClickfinish}
      />
      <br />
      <span>Order Information & Sampling</span>
      <hr />
      <Form
        formData={Audit}
        readOnly={false}
        labelMode="floating"
        labelLocation="top"
        minColWidth={300}
      >
        <GroupItem colCount={3}>
          <SimpleItem
            dataField="TotalOrder"
            editorType="dxNumberBox"
            editorOptions={totalOrderEditorOptions}
          />
          <SimpleItem
            dataField="Sampling"
            editorType="dxNumberBox"
            editorOptions={samplingEditorOptions}
          />
          <SimpleItem
            dataField="TotalBoxes"
            editorType="dxNumberBox"
            editorOptions={totalBoxesEditorOptions}
          />
        </GroupItem>
      </Form>
      <DataGrid
        //id="gridContainerPurple"
        ref={dataGridSampling}
        dataSource={dsSampling}
        hoverStateEnabled={true}
        height="100%"
        showColumnHeaders={true}
        showBorders={true}
        onSaved={onGridSamplingSaving}
        onRowRemoving={onGridSamplingDeleting}
        visible={dataVisible}
        repaintChangesOnly={true}
      >
        <Editing
          refreshMode={"repaint"}
          mode="popup"
          allowAdding={!auditReadOnly}
          allowDeleting={!auditReadOnly}
          allowUpdating={!auditReadOnly}
        >
          <Popup
            title="Sampling Info"
            showTitle={true}
            width={700}
            height={650}
          />
          <FormItem>
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item
                dataField="FK_Size"
                editorType="dxSelectBox"
                editorOptions={sizeEditorOptions}
              />
              <Item
                dataField="Sample"
                editorType="dxNumberBox"
                editorOptions={sampleEditorOptions}
              />
              <Item
                dataField="FK_Defect"
                editorType="dxSelectBox"
                editorOptions={defectEditorOptions}
              />
              <Template name="DefectItemTemplate" render={renderDefect} />
              <Item
                dataField="FK_Process"
                editorType="dxSelectBox"
                editorOptions={processBoxEditorOptions}
              />
              <Item dataField="DefectQuantity" />
              <Item dataField="FK_Status" />
              <Item
                dataField="Remarks"
                editorType="dxTextArea"
                colSpan={2}
                editorOptions={notesEditorOptions}
              />
            </Item>
          </FormItem>
        </Editing>
        <Selection mode="single" />
        <Scrolling mode="standard" />
        <Column
          dataField="PK_AuditSampling"
          caption="ID"
          dataType="integer"
          visible={false}
        />
        <Column
          dataField="FK_Audit"
          caption="Fk Audit"
          dataType="integer"
          value={pkAudit}
          visible={false}
        />
        <Column
          dataField="FK_Size"
          caption="Size"
          dataType="number"
          visible={true}
        >
          <Lookup
            dataSource={sizeList}
            valueExpr="PK_Size"
            displayExpr="SizeName"
          />
        </Column>
        <Column
          dataField="Sample"
          caption="Sample"
          dataType="integer"
          visible={true}
        />
        <Column
          dataField="FK_Defect"
          caption="Defects"
          dataType="string"
          visible={true}
        >
          <Lookup
            dataSource={defectList}
            valueExpr="PK_Defect"
            displayExpr="UQ_DefectCode"
          />
        </Column>
        <Column
          dataField="FK_Process"
          caption="Process"
          dataType="string"
          visible={true}
        >
          <Lookup
            dataSource={processList}
            valueExpr="PK_Process"
            displayExpr="Process"
          />
        </Column>
        <Column
          dataField="Remarks"
          caption="Remarks"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="DefectQuantity"
          caption="Total"
          dataType="number"
          visible={true}
        />
        <Column
          dataField="FK_Status"
          caption="Status"
          dataType="number"
          visible={true}
        >
          <Lookup
            dataSource={qualityStatus}
            valueExpr="PK_Status"
            displayExpr="Status"
          />
        </Column>
      </DataGrid>
      <br />
      <span>Measures</span>
      <hr />
      <Form>
        <GroupItem colCount={3}>
          <GroupItem>
            <DataGrid
              //id="gridContainerTransferMaterial"
              //ref={dataGrid}
              dataSource={dataSourceMeasure}
              hoverStateEnabled={true}
              selectAllMode="page"
              height="100%"
              showColumnHeaders={true}
              showBorders={true}
              visible={dataVisible}
              onSelectionChanged={onMeasureChanged}
              onInitNewRow={onMeasureInitNewRow}
              onSaved={onGridMeasureSaving}
              onRowRemoving={onGridMeasureDeleting}
            >
              <Selection mode="single" />
              <Editing
                refreshMode={"repaint"}
                mode="popup"
                allowAdding={!auditReadOnly}
                allowDeleting={!auditReadOnly}
                allowUpdating={!auditReadOnly}
              >
                <Popup
                  title="Measure Size"
                  showTitle={true}
                  width={500}
                  height={325}
                />
                <FormItem>
                  <Item itemType="group" colCount={1} colSpan={2}>
                    <Item
                      dataField="PK_AuditMeasure"
                      editorType="dxNumberBox"
                      visible={false}
                      caption={"ID"}
                    />
                    <Item
                      dataField="FK_Size"
                      editorType="dxSelectBox"
                      editorOptions={sizeEditorOptions}
                    />
                  </Item>
                </FormItem>
              </Editing>
              <Selection mode="single" />
              <Scrolling mode="standard" />
              <Column
                dataField="PK_AuditMeasure"
                caption="ID"
                dataType="number"
                visible={false}
              />
              <Column
                dataField="FK_Size"
                caption="Size"
                dataType="number"
                visible={true}
              >
                <Lookup
                  dataSource={sizeList}
                  valueExpr="PK_Size"
                  displayExpr="SizeName"
                />
              </Column>
            </DataGrid>
          </GroupItem>
          <GroupItem colSpan={2}>
            <DataGrid
              //id="gridContainerTransferMaterial"
              //ref={dataGrid}
              dataSource={dataSource}
              hoverStateEnabled={true}
              height="100%"
              // onSaving={onSaving}
              //onEditorPreparing={editorPreparing}
              showColumnHeaders={true}
              showBorders={true}
              onInitNewRow={onMeasureDetailInitNewRow}
              onSaved={onSavedMeasureDetail}
              onRowRemoving={onGridMeasureDetailDeleting}
              visible={detailVisible}
            >
              <Editing
                refreshMode={"repaint"}
                mode="popup"
                allowAdding={!auditReadOnly}
                allowDeleting={!auditReadOnly}
                allowUpdating={!auditReadOnly}
              >
                <Popup
                  title="Measure Details"
                  showTitle={true}
                  width={700}
                  height={400}
                />
                <FormItem>
                  <Item itemType="group" colCount={2} colSpan={2}>
                    <Item
                      dataField="FK_AuditMeasure"
                      editorType="dxNumberBox"
                      visible={false}
                    />
                    <Item
                      dataField="FK_Measure"
                      editorType="dxSelectBox"
                      editorOptions={measureEditorOptions}
                    />
                    <Item dataField="Specs" editorType="dxNumberBox" />
                    <Item dataField="Tolerance" editorType="dxNumberBox" />
                    <Item dataField="RealValue" editorType="dxNumberBox" />
                  </Item>
                </FormItem>
              </Editing>
              <Selection mode="single" />
              <Scrolling mode="standard" />
              <Column
                dataField="PK_AuditMeasureDetail"
                caption="ID"
                dataType="integer"
                visible={false}
              />
              <Column
                dataField="FK_AuditMeasure"
                caption="Audit Measure"
                dataType="number"
                visible={false}
              />
              <Column
                dataField="FK_Measure"
                caption="Measure"
                dataType="number"
                visible={true}
              >
                <Lookup
                  dataSource={measurementList}
                  valueExpr="PK_Measure"
                  displayExpr="Measure"
                />
              </Column>
              <Column
                dataField="Specs"
                caption="Specs"
                dataType="number"
                visible={true}
              />
              <Column
                dataField="Tolerance"
                caption="Tolerance"
                dataType="number"
                visible={true}
              />
              <Column
                dataField="RealValue"
                caption="Real"
                dataType="number"
                visible={true}
              />
            </DataGrid>
          </GroupItem>
        </GroupItem>
      </Form>
      <br />
      <span>Conclusion</span>
      <hr />
      <Form
        formData={Audit}
        readOnly={false}
        labelMode="floating"
        labelLocation="top"
        minColWidth={300}
      >
        <GroupItem>
          <Item
            dataField="FK_Status"
            editorType="dxSelectBox"
            editorOptions={statusEditorOptions}
          />
          <Item
            dataField="Remarks"
            editorType="dxTextArea"
            editorOptions={remarksEditorOptions}
          />
        </GroupItem>
      </Form>
      <div className="widget-container" align="center">
        <Gallery
          id="dxGallery_container"
          dataSource={galleryDataSource}
          itemRender={imageItem}
          height={600}
          width={800}
          loop={true}
          showNavButtons={true}
          showIndicator={true}
          defaultSelectedIndex={index}
          onItemClick={onItemClick}
        />
      </div>

      <div className="widget-container" align="center">
        <FileUploader
          dialogTrigger={targetElement}
          selectButtonText="Select photo"
          multiple={true}
          accept="image/*"
          uploadMode="instantly"
          onValueChanged={addIdParameter}
          uploadUrl={auditImageUploadURL}
          onUploaded={onUploaded}
          disabled={pkAudit > 0 ? (auditReadOnly ? true : false) : true}
        />
      </div>

      <div className="buttons" align="center">
        <Button
          text="Delete Photo"
          icon="trash"
          type="danger"
          onClick={onClickDelete}
          stylingMode="contained"
          useSubmitBehavior={true}
          disabled={auditReadOnly}
        />
      </div>
      <Form
        formData={Audit}
        readOnly={false}
        labelMode="floating"
        labelLocation="top"
        minColWidth={300}
      >
        <GroupItem colCount={6}>
          <GroupItem>
            <Item dataField="PK_Audit" editorOptions={pkAuditEditorOptions} />
          </GroupItem>
          <Item dataField="FK_Facility" editorOptions={facilityEditorOptions} />
          <GroupItem>
            <GroupItem>
              <Item
                dataField="FK_Process"
                editorOptions={processEditorOptions}
              />
            </GroupItem>
            <GroupItem>
              <Item
                dataField="FK_MasterOrder"
                editorOptions={masterEditorOptions}
              />
            </GroupItem>
            <GroupItem>
              <Item
                editorType="dxDateBox"
                dataField="Date_Audit"
                editorOptions={dateAuditEditorOptions}
              />
            </GroupItem>
            <GroupItem>
              <Item
                dataField="isChecklist"
                editorType="dxNumberBox"
                editorOptions={checklistEditorOptions}
              />
            </GroupItem>
          </GroupItem>
        </GroupItem>
      </Form>
    </React.Fragment>
  );
}

function screenByWidth(width) {
  return width < 720 ? "sm" : "md";
}
