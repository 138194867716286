import CustomStore from "devextreme/data/custom_store";
import { createStore } from 'devextreme-aspnet-data-nojquery';
import {
    sharedURL, warehouseURL,
} from "../../api/config";

export const DSFacility = new CustomStore({
    key: 'PK_Facility',
    loadMode: 'raw', 
    load: () => {
        return fetch(`${sharedURL}/facility`)
            .then(response => response.json());
    }
  });
  
  export const DSInventoryByKey = (PK_Facility) => {
  const InventoryByKey = new CustomStore({
    key: 'Facility_id',
    loadMode: 'raw', 
    load: () => {
        return fetch(`${warehouseURL}/inventory/${PK_Facility}`)
            .then(response => response.json());
    }
  })
  return InventoryByKey;
};

  export const DSInventory = new CustomStore({
    key: 'Facility_id',
    loadMode: 'raw', 
    load: () => {
        return fetch(`${warehouseURL}/inventory`)
            .then(response => response.json());
    }
  });