import React, { useState, useEffect, useCallback, useRef } from "react";
import notify from "devextreme/ui/notify";
import { Form, EmptyItem } from "devextreme-react/form";
import "../final.scss";
import { useTheme } from "../../../contexts/theme.js";
import { qualityURL } from "../../../api/config.js";
import { accessRole, arrayLogSession } from "../data.js";
import { useAuth } from "../../../contexts/auth.js";
import { useAudit } from "../contexts/audit.js";
import SpeedAction from "./speedAction.js";
import MasterDropBox from "./masterDropBox.js";
import DataAuditor from "./dataAuditor.js";
import AuditChecklist from "./auditChecklist.js";
import AuditSampling from "./auditSampling.js";
import AuditMeasurements from "./auditMeasurements.js";
import AuditConclusion from "./auditConclusion.js";
import ImageUploader from "./imageUploader.js";

export default function Audit({ props }) {
  const { user } = useAuth();
  const {
    pkAudit,
    setPkAudit,
    setFkAuditor,
    setFkProcess,
    fkProcess,
    fkShift,
    fkAuditor,
    setProcess, 
    process,
    setFkShift,
    master,
    setMaster,
    fkMasterOrder,
    setFkMasterOrder,
    setFkAuditType,
    auditType,
    setFkSuperviser,
    setFkQualityLevel,
    setFkWorkStation,
    role,
    setRole,
    setFkStatus,
    setRemarks,
    setSelectedItemKeys,
    dataGridVisible,
    setAuditReadOnly,
    auditFinish, 
    setFkFacility,
    setAuditFinish,
  } = useAudit();

  const [calculateColCountAutomatically, setCalculateColCountAutomatically] =
    useState(true);
  const [showLess, setShowLess] = useState(true);

  const formOrder = useRef(null);
  const colCountByScreen = {
    sm: 2,
    md: 4,
  };

  /*const arraySampling = useRef([]);
  const setArraySampling = useCallback((arraySample) => {
    arraySampling.current = arraySample;
  }, []);

  const arrayMeasure = useRef([]);
  const setArrayMeasure = useCallback((arrayMeasurement) => {
    arrayMeasure.current = arrayMeasurement;
  }, []);

  const arrayMeasureDetail = useRef([]);
  const setArrayMeasureDetail = useCallback((arrayMeasurementDetail) => {
    arrayMeasureDetail.current = arrayMeasurementDetail;
  }, []);*/

  useEffect(() => {
    async function getLastSessionResult() {
      try {
        const session = await arrayLogSession(user.idUser);
        const LastProcess = session.length > 0 ? session[0].Process : null;
        const LastFkProcess = session.length > 0 ? session[0].FK_Process : null;
        setProcess(LastProcess);
        setFkProcess(LastFkProcess);
        setFkAuditType(session[0].FK_AuditType ? session[0].FK_AuditType : 2);
        setRole(await accessRole(user.idUser));
        setFkAuditor(fkAuditor ? fkAuditor : user.idUser);
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    }
    getLastSessionResult();
  }, [user.idUser]);

  useEffect(() => {
    if (fkMasterOrder) {
      formOrder.current.instance.itemOption("Status", "visible", showLess);
      formOrder.current.instance.itemOption("PODate", "visible", showLess);
      formOrder.current.instance.itemOption("ExFactory", "visible", showLess);
      formOrder.current.instance.itemOption(
        "PriorityWeek",
        "visible",
        showLess
      );
      formOrder.current.instance.itemOption("DesignName", "visible", showLess);
      formOrder.current.instance.itemOption("Store", "visible", showLess);
    }
  }, [showLess, fkMasterOrder]);

  async function loadAudit(props) {
    setPkAudit(props.PK_Audit);
    setRole(await accessRole(props.FK_Auditor));
    fetch(`${qualityURL}/order/${props.FK_MasterOrder}`)
      .then((res) => res.json())
      .then((data) => {
        setMaster(data[0]);
        setFkMasterOrder(data[0].PK_Master);
      });

    await fetch(`${qualityURL}/audit/checklist/${props.PK_Audit}`)
      .then((res) => res.json())
      .then((data) => {
        const fkChecklistValues = data.map((item) => item.FK_Checklist);
        setSelectedItemKeys(fkChecklistValues);
      });

    setFkAuditType(props.FK_AuditType);
    setFkProcess(props.FK_Process);
    setFkMasterOrder(props.FK_MasterOrder);
    setFkSuperviser(props.FK_Superviser);
    setFkQualityLevel(props.FK_QualityLevel);
    setFkShift(props.FK_Shift);
    setFkWorkStation(props.FK_WorkStation);
    setAuditReadOnly(props.isClosed);
    setFkStatus(props.FK_Status);
    setRemarks(props.Remarks);
  }

  return (
    <React.Fragment>
      <div className="sidebyside-container">
        <div className="leftside">
          <h6 className={"content-block"}>
            {process} - {auditType ? auditType : "Final Audit"} : {pkAudit}
          </h6>
        </div>
        <div className="rightside">Access: {role.Role}</div>
        <div className="rightside">|</div>
        <div className="rightside">
          Status: {auditFinish ? "CLOSED" : "OPEN"}
        </div>
      </div>
      <SpeedAction
        props={{}}
        showLessColumns={(showLess) => setShowLess(showLess)}
        onSaveAuditClick={(Audit) => {
          loadAudit(Audit);
        }}
      />
      <MasterDropBox props={{}} />
      <Form>
        <EmptyItem />
      </Form>
      <Form
        ref={formOrder}
        formData={master}
        readOnly={true}
        labelMode="floating"
        labelLocation="top"
        colCountByScreen={
          calculateColCountAutomatically ? null : colCountByScreen
        }
        minColWidth={300}
        colCount="auto"
        screenByWidth={screenByWidth}
        visible={dataGridVisible}
      ></Form>
      <DataAuditor
        props={{}}
        /*onSaveClick={(auditData) => {
          setAuditAuditor(auditData);
        }}
        onAuditTypeChange={(auditType) => {
          setAuditType(auditType);
        }}*/
      />
      <AuditChecklist
        props={{ user: user }}
        /*onSelectedItemKeys={(items) => {
          setSelectedItemKeys(items);
        }}
        onCollapsedClick={(collapsed) => setCollapsedGroup(collapsed)}
        isChecked={(checked) => setIsListChecked(checked)}*/
      />
      <AuditSampling
        props={{}}
        /* onSaveClick={(auditSample) => {
          setAuditSampling(auditSample);
        }}
        onSaveSampling={(arraySampling) => {
          setArraySampling(arraySampling);
        }}*/
      />
      <br />
      <AuditMeasurements
        props={{}}
        /*onSaveMeasure={(arrayMeasure) => {
          setArrayMeasure(arrayMeasure);
        }}
        onSaveSamplingDetail={(arrayDetail) => {
          setArrayMeasureDetail(arrayDetail);
        }}*/
      />
      <br />
      <AuditConclusion />
      <ImageUploader />
    </React.Fragment>
  );
}

function screenByWidth(width) {
  return width < 720 ? "sm" : "md";
}
