import "./themes/generated/variables.css";
import React, { useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";
import "./dx-styles.scss";
import LoadPanel from "devextreme-react/load-panel";
import { NavigationProvider } from "./contexts/navigation";
import { AuthProvider, useAuth } from "./contexts/auth";
import { ThemeProvider, useTheme } from "./contexts/theme";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import UnauthenticatedContent from "./UnauthenticatedContent";
import { AuditProvider } from "./pages/quality/contexts/audit";

function App() {
  const { user, loading } = useAuth();
  const { setTheme, getTheme } = useTheme();
  useEffect(() => setTheme(getTheme()), [setTheme, getTheme]);
  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <NavigationProvider>
            <AuditProvider>
              <div className={`app ${screenSizeClass}`}>
                <App />
              </div>
            </AuditProvider>
          </NavigationProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}
