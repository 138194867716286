import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { createStore } from "devextreme-aspnet-data-nojquery";
import ArrayStore from "devextreme/data/array_store";
import { qualityURL, sharedURL } from "../../api/config";

export const DSMasterOrder = () =>
  new CustomStore({
    key: "PK_Master",
    loadMode: "raw",
    load: () => {
      return fetch(`${qualityURL}/order`)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          return [];
        });
    },
  });

export const dataSourceMasterOrder = new DataSource({
  store: DSMasterOrder(),
});

export function dataSourceChecklist(process) {
  const dataSource = new DataSource({
    store: createStore({
      key: "id",
      loadUrl: `${qualityURL}/checklist/${process}`,
    }),
    sort: "text",
    group: "category.CategoryName",
    paginate: false,
    pageSize: 1,
  });
  return dataSource;
}

export const DSFacility = () =>
  new CustomStore({
    key: "PK_Facility",
    loadMode: "raw",
    load: () => {
      return fetch(`${sharedURL}/facility`)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          return [];
        });
    },
  });

  export async function arrayUser() {
    const response = await fetch(`${sharedURL}/facility`)
      .then((response) => response.json())
      .catch((error) => {
        console.error(error);
        return [];
      });
    return response;
  }


  export async function arrayFacility() {
    const response = await fetch(`${sharedURL}/facility`)
      .then((response) => response.json())
      .catch((error) => {
        console.error(error);
        return [];
      });
    return response;
  }
  
export async function arrayProcess() {
  const response = await fetch(`${sharedURL}/process`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}

export async function arrayAuditor() {
  const response = await fetch(`${qualityURL}/auditorlist`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}

export const accessRole = async (FK_User) => {
  try {
    const response = await fetch(`${qualityURL}/role/${FK_User}/12`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    return result[0];
  } catch (error) {
    console.log(error.message);
  }
};

export const preferences = async (FK_User, pref) => {
  try {
    const response = await fetch(
      `${qualityURL}/preferences/${FK_User}/${pref}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    return result[0].ClassValue;
  } catch (error) {
    console.log(error.message);
  }
};

export async function DSAuditType() {
  const response = await fetch(`${qualityURL}/audit/type`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}

export async function DSSuperviser() {
  const response = await fetch(`${qualityURL}/superviser`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}

export async function arrayProcessSuperviser(FkFacility, FkProcess) {
  const response = await fetch(`${qualityURL}/process/superviser/${FkFacility}/${FkProcess}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}

export const DSShift = (FK_Facility, FK_Process) =>
  new CustomStore({
    key: "PK_Shift",
    loadMode: "raw",
    load: () => {
      return fetch(`${sharedURL}/Shift/${FK_Facility}/${FK_Process}`)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          return [];
        });
    },
  });

export async function DSQualityLevel() {
  const response = await fetch(`${qualityURL}/level`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}

export async function DSQualityStatus() {
  const response = await fetch(`${qualityURL}/status`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}

export const DSWorkStation = (FK_Facility, FK_Process) =>
  new CustomStore({
    key: "PK_WorkStation",
    loadMode: "raw",
    load: () => {
      return fetch(`${sharedURL}/workstation/${FK_Facility}/${FK_Process}`)
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
          return [];
        });
    },
  });

export async function arrayAudit(FkAudit) {
  const response = await fetch(`${qualityURL}/audit/${FkAudit}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  //console.log(response);
  return response;
}

export async function dataArraySampling(FkAudit) {
  const response = await fetch(`${qualityURL}/audit/sampling/${FkAudit}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}

export async function arrayOrderSize() {
  const response = await fetch(`${qualityURL}/order/size/list`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}

export async function arrayQualityDefect() {
  const response = await fetch(`${qualityURL}/defect`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}

export async function arrayMeasurement() {
  const response = await fetch(`${qualityURL}/measurement`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}

export async function dataArrayMeasure(FkAudit) {
  const response = await fetch(`${qualityURL}/audit/measure/${FkAudit}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}

export async function dataArrayMeasureDetail(FK_Audit) {
  const response = await fetch(`${qualityURL}/audit/measure/detail/${FK_Audit}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}

export async function arrayLogSession(FKUser) {
  const response = await fetch(`${qualityURL}/audit/logsession/${FKUser}`)
    .then( (response) =>  response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  //console.log(response);
  return response;
}

export const dsSampling_BK = (FkAudit) => {
  const DSSourceTransfer = new CustomStore({
    key: "PK_AuditSampling",
    dateSerializationFormat: "yyyy-MM-dd",
    load: () => sendRequest(`${qualityURL}/audit/sampling/${FkAudit}`),
    insert: (values) =>
      sendRequest(`${qualityURL}/audit/sampling`, "POST", {
        values: JSON.stringify(values),
      }),
    update: (key, values) =>
      sendRequest(`${qualityURL}/audit/sampling/${key}`, "PUT", {
        values: JSON.stringify(values),
      }),
    remove: (key) =>
      sendRequest(`${qualityURL}/audit/sampling/${key}`, "DELETE", {
        key,
      }),
    requests: [],
    refreshMode: "reshape",
  });

  async function sendRequest(url, method = "GET", data = {}) {
    if (method === "GET") {
      return await fetch(url, {
        method,
        credentials: "include",
      }).then((result) =>
        result.json().then((json) => {
          if (result.ok) return json;
          throw json.Message;
        })
      );
    }

    if (method === "DELETE") {
      return await fetch(url, {
        method,
        credentials: "include",
      });
    }
    const params = Object.keys(data)
      .map((key) => `${data[key]}`)
      .join(",");

    //console.log(params);
    return fetch(url, {
      method,
      body: params,
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => text && JSON.parse(text));
      }
      return result.json().then((json) => {
        throw json.Message;
      });
    });
  }
  return DSSourceTransfer;
};

export const saveAuditData = async (
  pkAudit,
  auditArray,
  isListChecked,
  selectedItemKeys,
  arraySampling,
  arrayMeasure,
  arrayMeasureDetail,
) => {
  try {
    const myJSON = JSON.stringify({
      FK_Facility: localStorage.getItem("fkFacility"),
      FK_AuditType: auditArray.FK_AuditType,
      FK_Process: auditArray.FK_Process,
      Date_Audit: auditArray.Date_Audit,
      FK_MasterOrder: auditArray.FK_MasterOrder,
      FK_Auditor: auditArray.FK_Auditor,
      FK_Superviser: auditArray.FK_Superviser,
      FK_QualityLevel: auditArray.FK_QualityLevel,
      FK_Shift: auditArray.FK_Shift,
      FK_WorkStation: auditArray.FK_WorkStation,
      isChecklist: isListChecked,
      TotalOrder: auditArray.TotalOrder,
      Sampling: auditArray.Sampling,
      TotalBoxes: auditArray.TotalBoxes,
      FK_Status: auditArray.FK_Status,
      Remarks: auditArray.Remarks,
    });
    if (pkAudit < 0) {
      const response = await fetch(`${qualityURL}/audit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: myJSON,
      });
      if (response.ok) {
        const result = await response.json();
        const PkAudit = result.lastInsertedId;
        ////////Checklist/////////////
        if (isListChecked) {
          const myJSON1 = JSON.stringify({
            FK_Audit: PkAudit,
            CheckList: selectedItemKeys,
          });
          await fetch(`${qualityURL}/audit/checklist`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: myJSON1,
          });
        }
        ////////Sampling/////////////
        const myJSON2 = JSON.stringify({
          FK_Audit: PkAudit,
          //Samplings: arraySampling.current,
          Samplings: arraySampling,
        });
        await fetch(`${qualityURL}/audit/sampling`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: myJSON2,
        });
        /////////////Measuring/////////////////////
        const myJSON3 = JSON.stringify({
          FK_Audit: PkAudit,
          mainAudits: arrayMeasure,
          auditDetails: arrayMeasureDetail,
          //mainAudits: arrayMeasure.current,
          //auditDetails: arrayMeasureDetail.current,
        });
        await fetch(`${qualityURL}/audit/measure`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: myJSON3,
        })
          .then((response) => response.json())
          .catch((error) => {
            console.error(error);
          });
        return PkAudit;
      } else {
        console.error("Failed to save data:", response.statusText);
      }
    } else {
      const response = await fetch(`${qualityURL}/audit/${pkAudit}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: myJSON,
      });
      return pkAudit;
    }
  } catch (error) {
    console.error("Error saving data:", error);
  }
};

export const updateSampling = async (changedData) => {
  const PK_AuditSampling = changedData.PK_AuditSampling;
  const myJSON = JSON.stringify({
    FK_Audit: changedData.FK_Audit,
    FK_Size: changedData.FK_Size,
    FK_Defect: changedData.FK_Defect,
    DefectQuantity: changedData.DefectQuantity,
    Remarks: changedData.Remarks,
    FK_Status: changedData.FK_Status,
  });
  await fetch(`${qualityURL}/audit/sampling/${PK_AuditSampling}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: myJSON,
  });
  return PK_AuditSampling;
};

export const deleteSampling = async (PK_AuditSampling) => {
  await fetch(`${qualityURL}/audit/sampling/${PK_AuditSampling}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return PK_AuditSampling;
};

export const updateMeasure = async (changedData) => {
  const PK_AuditMeasure = changedData.PK_AuditMeasure;
  const myJSON = JSON.stringify({
    FK_Size: changedData.FK_Size,
  });
  await fetch(`${qualityURL}/audit/measure/${PK_AuditMeasure}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: myJSON,
  });
  return PK_AuditMeasure;
};

export const deleteMeasure = async (PK_AuditMeasure) => {
  await fetch(`${qualityURL}/audit/measure/${PK_AuditMeasure}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return PK_AuditMeasure;
};

export const updateMeasureDetail = async (changedData) => {
  const PK_AuditMeasureDetail = changedData.PK_AuditMeasureDetail;
  const myJSON = JSON.stringify({
    FK_Measure: changedData.FK_Measure,
    Specs: changedData.Specs,
    Tolerance: changedData.Tolerance,
    RealValue: changedData.RealValue,
  });
  await fetch(`${qualityURL}/audit/measure/detail/${PK_AuditMeasureDetail}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: myJSON,
  });
  return PK_AuditMeasureDetail;
};

export const deleteMeasureDetail = async (PK_AuditMeasureDetail) => {
  await fetch(`${qualityURL}/audit/measure/detail/${PK_AuditMeasureDetail}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return PK_AuditMeasureDetail;
};


export const newLogSession = async (myJSON) => {
  try {
    const response = await fetch(`${qualityURL}/audit/logsession`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: myJSON
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    //console.log('Data inserted:', data);
  } catch (error) {
    console.error('Error inserting data:', error);
  }
};

export const finishAudit = async (pkAudit) => {
  try {
    await fetch(`${qualityURL}/audit/finish/${pkAudit}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error('Error finishing audit:', error);
  }
};

