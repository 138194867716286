import React, {useState, useEffect} from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import { useAuth } from './contexts/auth';

export default function Content() {
  const [currRoutes, setCurrRoutes] = useState(routes)
  const { user } = useAuth();

  useEffect(() => {
    //const currUserType = user.User_Group.split(',');
    const currUserType = user.User_Group;
    const isAdmin = user.IsAdmin;
    const newRoutes =  routes.filter(r => currUserType.includes(r.index))
    //const newRoutes =  routes.filter(r => r.index.includes(user.User_Group))
    isAdmin ? setCurrRoutes(routes) : setCurrRoutes(newRoutes)
    //setCurrRoutes(newRoutes)
  }, [user])

  return (
    <SideNavBarLayout title={appInfo.title}>
      <Routes>
        {currRoutes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={element}
          />
        ))}
        <Route
          path='*'
          //element={<Navigate to='/home' />}
          element={user.IsScan ? <Navigate to='/sew/scan' /> : <Navigate to={currRoutes[0] ? currRoutes[0].path : '/home'}/>}
        />
      </Routes>
      <Footer className={"dx-theme-text-color"}>
        Copyright © 2020-{new Date().getFullYear()} {appInfo.title}.
        <br />
        All trademarks or registered trademarks are property of their
        respective owners.
      </Footer>
    </SideNavBarLayout>
  );
}

