import warehouseIcon from "./assets/warehouse1.svg";
import qualityIcon from "./assets/qualityLogo.svg";

export const navigation = [
  {
    text: "Home",
    path: "/home",
    icon: "home",
    index: 1,
  },
  {
    text: "Quality",
    icon: qualityIcon,
    path: "/quality",
    index: 3,
  },
  {
    text: "Inventory",
    icon: warehouseIcon ,
    path: "/inventory",
    index: 5,
  },
  {
    text: "BAM",
    icon: "chart",
    path: "/bam",
    index: 4,
  },
  {
    text: "Production",
    icon: "event",
    path: "/production",
    index: 6,
  },
  {
    text: "Planning",
    icon: "bookmark",
    path: "/planning",
    index: 7,
  },
  {
    text: "Preferences",
    icon: "preferences",
    path: "/profile",
    index: 2,
  },
];
