import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import SelectBox from "devextreme-react/select-box";
import { Button as ButtonItem } from "devextreme-react/button";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Editing,
  Summary,
  Selection,
  FilterRow,
  SearchPanel,
  Toolbar,
  Item,
  Format,
  ColumnChooser,
  ColumnFixing,
  TotalItem,
  Scrolling,
  Export,
  HeaderFilter,
  FilterPanel,
  StateStoring,
  MasterDetail,
} from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";

import { arrayCapacityColumn, arrayContractor, arrayWorkStation, DSCapacityDetail } from "../data";

export const DetailTemplate = (props) => {
  const [gridColumn, setGridColumn] = useState([]);
  const dataSource = new DataSource({
    store: DSCapacityDetail(3, 6),
    filter: ["PK_Master", "=", props.data.key],
  });

  function refreshDataGrid() {
    dataSource.reload();
  }

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      if (isSubscribed) {
        try {
          const contractorArray = await arrayContractor(6);
          const workStationArray = await arrayWorkStation(3, 6);
          const Columns = await arrayCapacityColumn(3, 6, 1);
          const updateCol = Columns.map((column) => {
            if (column.dataField === "FK_Contractor") {
              return {
                ...column,
                dataField: "FK_Contractor",
                caption: "Contractor",
                lookup: {
                  dataSource: contractorArray,
                  displayExpr: "ContractorName",
                  valueExpr: "PK_Contractor",
                },
              };
            } else if (column.dataField === "FK_WorkStation") {
              return {
                ...column,
                dataField: "FK_WorkStation",
                caption: "WorkStation",
                lookup: {
                  dataSource: workStationArray,
                  displayExpr: "WorkStation",
                  valueExpr: "PK_WorkStation",
                },
              };
            } else {
              return column;
            }
          });
          setGridColumn(updateCol);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
    return () => (isSubscribed = false);
  }, []);

  return (
    <React.Fragment>
      <div className="master-detail-caption">{`${"PK_Master"}'s Tasks:`}</div>
      {gridColumn.length > 0 ? (
        <DataGrid
          dataSource={dataSource}
          showBorders={true}
          columnAutoWidth={true}
          allowColumnResizing={true}
          columnResizingMode="widget"
          columns={gridColumn}
          width={1500}
        >
          <Editing
            refreshMode={"repaint"}
            mode="cell"
            allowAdding={true}
            allowDeleting={true}
            allowUpdating={true}
          ></Editing>
          <Summary>
            <TotalItem
              column="FK_Contractor"
              summaryType="count"
              valueFormat="#,##0"
            />
            <TotalItem
              column="ProductionGoal"
              summaryType="sum"
              displayFormat="{0}"
              valueFormat="#,##0"
            />
            <TotalItem
              column="Total"
              summaryType="sum"
              displayFormat="{0}"
              valueFormat="#,##0"
            />
            <TotalItem
              column="TotalSew"
              summaryType="sum"
              displayFormat="{0}"
              valueFormat="#,##0"
            />
            {gridColumn.map((item, index) => (
              <TotalItem
                key={index}
                column={item.column}
                summaryType={item.summaryType}
                displayFormat={item.displayFormat}
                valueFormat={item.valueFormat}
              />
            ))}
          </Summary>
        </DataGrid>
      ) : (
        <div>Loading...</div>
      )}
    </React.Fragment>
  );
};
