import React, { useState, useEffect, useCallback, useRef } from "react";
import Box, { Item } from "devextreme-react/box";
import { Button as ButtonItem} from "devextreme-react/button";
import { createStore } from "devextreme-aspnet-data-nojquery";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import RangeSelector, {
  Margin,
  Scale,
  MinorTick,
  SliderMarker,
  Label,
  Marker,
  Behavior,
  Format,
} from "devextreme-react/range-selector";
import DataGrid, {
  Column,
  Editing,
  Selection,
  Button,
  Summary,
  Scrolling,
  TotalItem,
  Item as ItemGrid,
  Toolbar,
  Pager,
  Paging,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  FilterPanel,
} from "devextreme-react/data-grid";
import "./overview.scss";
import "./grid.scss";
import Suite from "./suite.js";
import FinalAudit from "./finalAudit";
import Audit from "./audit/audit";
import { qualityURL } from "../../api/config";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { useAudit } from "./contexts/audit.js";
import { accessRole, newLogSession } from "./data.js";

export default function Overview(props) {
  const { user, signOut } = useAuth();
  const {
    pkAudit,
    setPkAudit,
    auditReadOnly,
    setAuditReadOnly,
    fkFacility,
    setFkFacility,
    fkProcess,
    setFkProcess,
    process,
    setProcess,
    fkShift,
    setFkShift,
    fkMasterOrder,
    setFkMasterOrder,
    fkAuditType,
    setFkAuditType,
    auditType,
    setAuditType,
    auditor,
    fkAuditor,
    setFkAuditor,
    fkSuperviser,
    setFkSuperviser,
    fkQualityLevel,
    setFkQualityLevel,
    fkWorkStation,
    setFkWorkStation,
    role,
    setRole,
    fkStatus,
    setFkStatus,
    setTotalBoxes,
    remarks,
    setRemarks,
    setIdLastScreen,
    setDataGridVisible,
    arraySampling,
    setArraySampling,
    setSampling,
    setSelectedItemKeys,
  } = useAudit();

  const [iconColor, setIconColor] = useState("white");
  const [startDate, setStartDate] = useState(new Date(2024, 0, 1));
  const [endDate, setEndDate] = useState(new Date());
  const [focusedRowKey, setFocusedRowKey] = useState(1);
  const [isAuditEditable, setIsAuditEditable] = useState(false);
  const [auditParams, setAuditParams] = useState(null);

  const navigate = useNavigate();
  const dataGrid = useRef(null);

  var today = new Date(new Date().toLocaleString());
  var thisYear = today.getFullYear();
  var lastMonth = today.getMonth() - 1;
  const startValue = new Date(thisYear, 0, 1);
  const endValue = new Date(thisYear, lastMonth + 2, 1);
  const range = [new Date(thisYear, lastMonth, 1), today];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userRole = await accessRole(user.idUser);
        setRole(userRole);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [user]);

  const finalHandleClick = () => {
    setIdLastScreen("1_1_4_3");
    LogSession(
      fkFacility,
      fkAuditor,
      "1_1_4_3",
      fkProcess,
      fkAuditType,
      auditType,
      role.FK_Role
    );
    if (props && props.updateComponent) {
      props.updateComponent(
        <Audit
          props={{
            User: user,
            FKFacility: fkFacility,
            FKProcess: fkProcess,
            Process: process,
            pkAuditToLoad: null,
          }}
        />
      );
    } else {
      // Handle the case where props or updateComponent is undefined
    }
  };

  const filterAudit = useCallback(
    ({ value }) => {
      setStartDate(value[0]);
      setEndDate(value[1]);
    },
    [setStartDate, setEndDate]
  );

  const DSOverview = () =>
    createStore({
      key: "PK_Audit",
      loadUrl:
        role.FK_Role > 5
          ? `${qualityURL}/audit/overview/${12}/${startDate}/${endDate}`
          : `${qualityURL}/audit/overview/auditor/${
              user.idUser
            }/${12}/${startDate}/${endDate}`,
    });

  const overviewDataSource = new DataSource({
    key: "PK_Audit",
    store: DSOverview(),
    requireTotalCount: true,
  });

  function refreshDataGrid() {
    overviewDataSource.reload();
  }

  function onContentReady(e) {
    e.component.columnOption("command:edit", "visibleIndex", -1);
  }

  function onRowDblClick(e) {
    setIdLastScreen("1_1_4_3");
    LogSession(
      e.data.FK_Facility,
      e.data.FK_Auditor,
      "1_1_4_3",
      e.data.FK_Process,
      e.data.FK_AuditType,
      e.data.AuditType,
      role.FK_Role
    );
    setPkAudit(e.data.PK_Audit);
    setFkMasterOrder(e.data.FK_MasterOrder);
    setFkFacility(e.data.FK_Facility);
    setFkProcess(e.data.FK_Process);
    setFkShift(e.data.FK_Shift);
    setFkAuditor(e.data.FK_Auditor);
    setFkAuditType(e.data.FK_AuditType);
    setFkSuperviser(e.data.FK_Superviser);
    setFkQualityLevel(e.data.FK_QualityLevel);
    setFkWorkStation(e.data.FK_WorkStation);
    setFkStatus(e.data.FK_Status);
    setTotalBoxes(e.data.TotalBoxes);
    setRemarks(e.data.Remarks);
    setDataGridVisible(true);
    setAuditReadOnly(true);
    fetch(`${qualityURL}/audit/checklist/${e.data.PK_Audit}`)
      .then((res) => res.json())
      .then((data) => {
        const fkChecklistValues = data.map((item) => item.FK_Checklist);
        setSelectedItemKeys(fkChecklistValues);
      });
    //setSampling(arraySampling.reduce((total, item) => total + item["DefectQuantity"], 0));

    if (props && props.updateComponent) {
      props.updateComponent(
        <Audit
          props={{
            auditReadOnly: true,
            auditFinish: e.data.isClosed,
          }}
        />
      );
    }
  }

  function onEditingStart(e) {
    e.cancel = true;
    setIdLastScreen("1_1_4_3");
    LogSession(
      e.data.FK_Facility,
      e.data.FK_Auditor,
      "1_1_4_3",
      e.data.FK_Process,
      e.data.FK_AuditType,
      e.data.AuditType,
      role.FK_Role
    );
    setPkAudit(e.data.PK_Audit);
    setFkMasterOrder(e.data.FK_MasterOrder);
    setFkFacility(e.data.FK_Facility);
    setFkProcess(e.data.FK_Process);
    setFkShift(e.data.FK_Shift);
    setFkAuditor(e.data.FK_Auditor);
    setFkAuditType(e.data.FK_AuditType);
    setFkSuperviser(e.data.FK_Superviser);
    setFkQualityLevel(e.data.FK_QualityLevel);
    setFkWorkStation(e.data.FK_WorkStation);
    setFkStatus(e.data.FK_Status);
    setTotalBoxes(e.data.TotalBoxes);
    setRemarks(e.data.Remarks);
    setDataGridVisible(true);
    //setSampling(arraySampling.reduce((total, item) => total + item["DefectQuantity"], 0));
    setAuditReadOnly(false);
    fetch(`${qualityURL}/audit/checklist/${e.data.PK_Audit}`)
      .then((res) => res.json())
      .then((data) => {
        const fkChecklistValues = data.map((item) => item.FK_Checklist);
        setSelectedItemKeys(fkChecklistValues);
      });
    if (props && props.updateComponent) {
      props.updateComponent(
        <Audit
          props={{
            auditReadOnly: false,
            auditFinish: e.data.isClosed,
          }}
        />
      );
    }
  }

  const onReauditIconClick = useCallback((e) => {
    console.log(e.row.data.PK_Audit);
  }, []);

  function onSelectionChanged({ selectedRowsData }) {
    const data = selectedRowsData[0];
    data ? setFocusedRowKey(data.PK_Audit) : setFocusedRowKey(0);
    role > 2 || data.isClosed === true
      ? setIsAuditEditable(true)
      : setIsAuditEditable(false);
  }
  function onCellPrepared(e) {
    if (
      e.rowType === "data" &&
      e.column.command === "edit" &&
      role.FK_Role < 6
    ) {
      if (e.data && e.data.isClosed === true) {
        e.cellElement.querySelector(".dx-link-edit").style.display = "none";
      }
    }
  }

  function LogSession(
    Fkfacility,
    FkUser,
    IdScreen,
    FkProcess,
    FkAuditType,
    auditType,
    FkRole
  ) {
    const myJSON = JSON.stringify({
      FK_Facility: Fkfacility,
      FK_User: FkUser,
      ScreenId: IdScreen,
      FK_Process: FkProcess,
      FK_AuditType: FkAuditType,
      auditType: auditType,
      FK_Role: FkRole,
      DateSession: new Date(),
    });
    newLogSession(myJSON);
  }

  return (
    <React.Fragment>
      <Box direction="row" width="100%" height={70}>
        <Item ratio={1}>
          <button className="button1">
            <span>
              New
              <br /> AuditProcess
            </span>
          </button>
        </Item>
        <Item ratio={0} baseSize={20}>
          <div className="rect demo-trans"></div>
        </Item>
        <Item ratio={1}>
          <button className="button2" onClick={finalHandleClick}>
            <span>
              New <br />
              Final Audit
            </span>
          </button>
        </Item>
        <Item ratio={0} baseSize={20}>
          <div className="rect demo-trans"></div>
        </Item>
        <Item ratio={1}>
          <button className="button3">
            <span>
              Quality <br />
              Inspection
            </span>
          </button>
        </Item>
        <Item ratio={0} baseSize={20}>
          <div className="rect demo-trans"></div>
        </Item>
        <Item ratio={1}>
          <button className="button4">
            <span>
              Audit
              <br /> Specifications{" "}
            </span>
          </button>
        </Item>
      </Box>
      <br />
      <RangeSelector
        id="range-selector"
        title="Select an Audit Period"
        defaultValue={range}
        onValueChanged={filterAudit}
      >
        <Margin top={50} />
        <Scale
          startValue={startValue}
          endValue={endValue}
          minorTickInterval="day"
          tickInterval="month"
        >
          <MinorTick visible={false} />
          <Marker visible={false} />
          <Label format="MMM" />
        </Scale>
        <Behavior valueChangeMode={"onHandleMove"} />
        <SliderMarker format="dd EEEE" />
        {/*<SliderMarker format="monthAndDay" />*/}
      </RangeSelector>
      <DataGrid
        //id="gridContainerTransferMaterial"
        ref={dataGrid}
        dataSource={role.FK_Role ? overviewDataSource : null}
        focusedRowEnabled={true}
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        showBorders={true}
        height="100%"
        columnAutoWidth={true}
        onEditingStart={onEditingStart}
        onCellPrepared={onCellPrepared}
        showColumnHeaders={true}
        onContentReady={onContentReady}
        onRowDblClick={onRowDblClick}
      >
        <Selection mode="single" />
        <SearchPanel visible={true} />
        <Scrolling mode="standard" />
        <Paging defaultPageSize={10} />
        <HeaderFilter visible={true} />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={true}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Editing
          useIcons={true}
          allowUpdating={true}
          allowAdding={false}
          allowDeleting={role.FK_Role > 4 ? true : false}
          mode="row"
        />
        <Column type="buttons" width={110}>
          <Button name="edit" />
          <Button name="delete" />
          <Button
            hint="ReAudit"
            icon="copy"
            visible={true}
            disabled={false}
            onClick={onReauditIconClick}
          />
        </Column>
        <Column
          dataField="PK_Audit"
          caption="ID Audit"
          dataType="integer"
          visible={true}
        />
        <Column
          dataField="FK_MasterOrder"
          caption="Master"
          dataType="integer"
          visible={true}
        />
        <Column
          dataField="FacilityCode"
          caption="Facility"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Date_Audit"
          caption="Date"
          dataType="date"
          visible={true}
        />
        <Column
          dataField="FK_Process"
          caption="FK_Process"
          dataType="integer"
          visible={false}
        />
        <Column
          dataField="Process"
          caption="Process"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="WorkStation"
          caption="WorkStation"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Customer"
          caption="Customer"
          dataType="string"
          visible={true}
        />
        <Column dataField="PO" caption="PO" dataType="string" visible={true} />
        <Column
          dataField="StorePO"
          caption="StorePO"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Style"
          caption="Style"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Color"
          caption="Color"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="BISColor"
          caption="BIS Color"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Art"
          caption="Art"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="DesignName"
          caption="Design Name"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Size"
          caption="Size"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Auditor"
          caption="Auditor Name"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="AuditType"
          caption="Audit Type"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="TotalOrder"
          caption="Total Order"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="TotalBoxes"
          caption="Total Boxes"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Total_Defects"
          caption="Total Defects"
          dataType="number"
          visible={true}
        />
        <Column
          dataField="Status"
          caption="Status"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Remarks"
          caption="Remarks"
          dataType="string"
          visible={true}
        />

        <Column
          dataField="isActive"
          caption="Active"
          dataType="boolean"
          visible={true}
        />
        <Column
          dataField="isClosed"
          caption="Closed"
          dataType="boolean"
          visible={true}
        />
        <Column
          dataField="AuditYear"
          caption="Year"
          dataType="number"
          visible={true}
        />
        <Column
          dataField="AuditMonth"
          caption="Month"
          dataType="number"
          visible={true}
        />
        <Column
          dataField="DateCreate"
          caption="Date Create"
          dataType="date"
          visible={true}
        />
        <Column
          dataField="isReaudit"
          caption="Reaudit"
          dataType="boolean"
          visible={true}
        />
        <Column
          dataField="FK_Auditor"
          caption="FK_Auditor"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="FK_Superviser"
          caption="FK_Superviser"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="FK_QualityLevel"
          caption="FK_QualityLevel"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="FK_WorkStation"
          caption="FK_WorkStation"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="FK_AuditType"
          caption="FK_AuditType"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="FK_Shift"
          caption="FK_Shift"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="FK_Status"
          caption="FK_Status"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="FK_Facility"
          caption="FK_Facility"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="FK_ReAudit"
          caption="FK_ReAudit"
          dataType="number"
          visible={false}
        />
        <Toolbar>
          <ItemGrid location="after">
            <ButtonItem icon="refresh" visible={true} onClick={refreshDataGrid} />
          </ItemGrid>
          <ItemGrid name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
      </DataGrid>
    </React.Fragment>
  );
}
