import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import DataGrid, { Column } from "devextreme-react/data-grid";

import { useTheme } from "../../../contexts/theme";
import { getStatusPdById, } from "../data";
import "./status.scss";

export default function PdSummary(props) {
  const { FK_Style } = props || {};
  const { getTheme } = useTheme();
  const [themeColor, setThemeColor] = useState("purple");
  const [styleStatus, setStyleStatus] = useState([]);

  let currentTheme = getTheme().split(".")[0];
  useEffect(() => {
    function themeResult() {
      setThemeColor(currentTheme);

    }
    themeResult();
  }, [currentTheme]);

  useEffect(() => {
    async function Styleresult() {
      if (FK_Style) {
        const result = await getStatusPdById(FK_Style);
        setStyleStatus(result);
      }
    }
    Styleresult();
  }, [FK_Style]);

  const renderGridCell = (data) => {
    return <div style={{ fontWeight: "bold", fontSize: 20 }}>{data.text}</div>;
  };
  const renderGridStatusCell = (data) => {
    return <div style={{ fontWeight: "normal", fontSize: 20, color: data.text === 'OK' ? 'green' : data.text === 'Pending' ? themeColor : 'grey' }}>{data.text}</div>;
  };

  return (
    <React.Fragment>
      <DataGrid
        id="gridContainer"
        class="borderlessGrid"
        dataSource={styleStatus}
        height={600}
        keyExpr="FK_Style"
        showBorders={false}
        showColumnHeaders={false}
      >
        <Column dataField="FK_Style" caption="FK_Style" visible={false} />
        <Column
          dataField="Process"
          caption="Description"
          width={250}
          cellRender={renderGridCell}
        />
        <Column dataField="Status" caption="status" cellRender={renderGridStatusCell}/>
      </DataGrid>
    </React.Fragment>
  );
}
