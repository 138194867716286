import React from "react";

const htmFrame =
  "https://app.powerbi.com/view?r=eyJrIjoiZGExM2MzNzgtMDA2MS00YzhkLTkzMmItZGI4ODU0YmUxYTFiIiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9";

export default function DashboardTemplate() {
  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <iframe
        title="DPR Planner"
        width="100%"
        height="1800"
        src={htmFrame}
        style={{ border: "none" }} 
        allowFullScreen={true}
      />
    </div>
  );
}
