import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import SelectBox from "devextreme-react/select-box";
import { Button as ButtonItem } from "devextreme-react/button";
import DataGrid, {
  Column,
  Button,
  Pager,
  Paging,
  Editing,
  Summary,
  Selection,
  Lookup,
  FilterRow,
  SearchPanel,
  Toolbar,
  Item,
  Format as FormatNumber,
  ColumnChooser,
  ColumnFixing,
  TotalItem,
  Scrolling,
  Export,
  HeaderFilter,
  FilterPanel,
  StateStoring,
  MasterDetail,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import DataSource from "devextreme/data/data_source";
import TabPanel, { Item as ItemTab } from "devextreme-react/tab-panel";

import { useAuth } from "../../../contexts/auth";
import DashboardTemplate from "./pdDashboard";
import { accessRights, DSStatusPd, arrayStatusPd } from "../data";
import "../planGrid.scss";

export default function PdStatus(props) {
  const { user } = useAuth();
  const [role, setRole] = useState();
  const { PK_Master, FK_Style, Style } = props || {};
  const [stileStatus, setStyleStatus] = useState(0);
  const dataGrid = useRef(null);
  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const year = String(today.getFullYear()).slice(2);
  const formattedDate = month + day + year;

  const dataSource = new DataSource({
    store: DSStatusPd(stileStatus, user.idUser),
  });

  useEffect(() => {
    async function Accessresult() {
      if (user) {
        const access = await accessRights(user.idUser, 6, "30_1_2");
        setRole(access.FK_Role);
      }
    };
    Accessresult();
  }, [user]);

  function refreshDataGrid() {
    dataSource.reload();
  }

  function onValueChanged(e) {
    var result = e.value === null ? 2 : e.value;
    setStyleStatus(result);
  }

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`Sewing`);
    exportDataGrid({
      component: dataGrid.current.instance,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `PD_Process_Status_${formattedDate}.xlsx`
        );
      });
    });
  };

  return (
    <React.Fragment>
      <DataGrid
        id="gridContainer"
        ref={dataGrid}
        dataSource={dataSource}
        height="100%"
        focusedRowEnabled={true}
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        allowColumnReordering={false}
        allowColumnResizing={true}
        columnResizingMode="widget"
        showBorders={true}
        columnAutoWidth={true}
        showColumnHeaders={true}
      >
        <Selection mode="single" />
        <SearchPanel visible={true} />
        <Scrolling mode="standard" />
        <Paging defaultPageSize={10} />
        <HeaderFilter visible={true} />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <ColumnFixing enabled={false} />
        <ColumnChooser enabled={true} mode={"dragAndDrop"} /> {/*select*/}
        <Pager
          visible={true}
          allowedPageSizes={true}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Editing
          refreshMode={"repaint"}
          mode="cell"
          allowAdding={false}
          allowDeleting={false}
          allowUpdating={role > 2 ? true : false}
        ></Editing>
        <Column dataField="FK_Style" caption="id" width={110} allowEditing={false}></Column>
        <Column dataField="StyleName" caption="Style" allowEditing={false}></Column>
        <Column dataField="StyleDescription" caption="Description" allowEditing={false}></Column>
        <Column dataField="StyleCreation" caption="Creation"></Column>
        <Column dataField="StyleConsumption" caption="Consumption"></Column>
        <Column dataField="StyleLabDip" caption="Labdip"></Column>
        <Column dataField="SampleFit" caption="Fit"></Column>
        <Column dataField="SamplePP" caption="PP"></Column>
        <Column dataField="SampleTesting" caption="Testing"></Column>
        <Column dataField="SamplePhoto" caption="Photo"></Column>
        <Column dataField="Bom" caption="Bom"></Column>
        <Column dataField="SpecsSew" caption="Specs Sew"></Column>
        <Column dataField="SpecsPack" caption="Specs Pack"></Column>
        <Summary>
          <TotalItem
            column="FK_Style"
            summaryType="count"
            valueFormat="#,##0"
          />
        </Summary>
        <Toolbar>
          <Item location="before">
            <SelectBox
              width="350"
              showClearButton={true}
              dataSource={arrayStatusPd}
              label="Select Style Status"
              defaultValue={stileStatus}
              valueExpr="id"
              displayExpr="Status"
              onValueChanged={onValueChanged}
            />
          </Item>
          <Item location="after">
            <ButtonItem
              icon="refresh"
              visible={true}
              onClick={refreshDataGrid}
            />
          </Item>
          <Item location="after">
            <ButtonItem icon="export" visible={true} onClick={onExporting} />
          </Item>
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
        <Export enabled={true} allowExportSelectedData={true} />
      </DataGrid>
    </React.Fragment>
  );
}
