import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import SelectBox from "devextreme-react/select-box";
import TextBox, { TextBoxTypes } from "devextreme-react/text-box";
import { Button as ButtonItem } from "devextreme-react/button";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Editing,
  Lookup,
  Summary,
  Selection,
  FilterRow,
  SearchPanel,
  Toolbar,
  Item,
  Format as FormatNumber,
  ColumnChooser,
  ColumnFixing,
  TotalItem,
  Scrolling,
  Export,
  HeaderFilter,
  FilterPanel,
  StateStoring,
  RequiredRule,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";

import { useAuth } from "../../../contexts/auth";
import {
  accessRights,
  DSFacility,
  DSWeekNumber,
  DSCutomer,
  getActualWeek,
  dsProductionTrack,
} from "../data";
import "../planGrid.scss";

export default function Projection(props) {
  const { user } = useAuth(); //user.idUser
  const [role, SetRole] = useState(2);
  const [facility, setFacility] = useState(user.FK_Facility);
  const [actualWeek, setActualWeek] = useState(null);
  const [actualYear, setActualYear] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [remarks, setRemarks] = useState();
  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const year = String(today.getFullYear()).slice(2);
  const formattedDate = month + day + year;
  const dataGridRef = useRef(null);
  const nameLabel = { "aria-label": "Remarks" };

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      try {
        const week = await getActualWeek(today);
        if (isSubscribed) {
          setActualWeek(week.FkWeek);
          setActualYear(Number(new Date().getFullYear()));
          const roleResult = await accessRights(user.idUser, 1004, '20_20')
          SetRole(roleResult != undefined ?  roleResult : 2);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      try {
        if (isSubscribed) {
          if (actualWeek != null) {
            setDataSource(
              dsProductionTrack(facility, props.data.fkProcess, actualWeek)
            );
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [facility, actualWeek, props.data.fkProcess]);

  function onFacilityValueChanged(e) {
    setFacility(e.value);
    setDataSource(dsProductionTrack(e.value, props.data.fkProcess, actualWeek));
  }
  function onWeekValueChanged(e) {
    setActualWeek(e.value);
    setDataSource(dsProductionTrack(facility, props.data.fkProcess, e.value));
  }
  function onRemarksValueChanged(e) {
    setRemarks(e.value);
  }

  const onAddingNew = () => {
    const dataGridInstance = dataGridRef.current.instance;
    if (dataGridInstance) {
      dataGridInstance.addRow();
    }
  };
  const onInitNewRow = (e) => {
    e.data.PK_Planning = 0;
    e.data.FK_Facility = facility;
    e.data.FK_Process = props.data.fkProcess;
    e.data.FK_Year = actualYear;
    e.data.FK_Week = actualWeek;
    e.data.Remarks = remarks;
    e.data.isProjection = false;
    e.data.GoalUnit = null;
    e.data.GoalSetup = null;
    e.data.Billing = null;
    e.data.DateCreate = today;
    e.data.FK_User = user.idUser;
  };

  const refreshDataGrid = useCallback(() => {
    dataGridRef.current.instance.refresh();
  }, []);

  const onRefresh = () => {
    setDataSource(
      dsProductionTrack(facility, props.data.fkProcess, actualWeek)
    );
  };

  return (
    <React.Fragment>
      <DataGrid
        id="gridContainer"
        ref={dataGridRef}
        dataSource={dataSource}
        height="100%"
        focusedRowEnabled={false}
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        allowColumnReordering={false}
        allowColumnResizing={true}
        showBorders={true}
        columnAutoWidth={true}
        showColumnHeaders={true}
        onInitNewRow={onInitNewRow}
        onSaved={refreshDataGrid}
        /* onExporting={onExporting}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}*/
      >
        <SearchPanel visible={true} />
        <Scrolling mode="standard" />
        <Paging defaultPageSize={10} />
        <HeaderFilter visible={true} />
        <FilterPanel visible={true} />
        <FilterRow visible={false} />
        <ColumnFixing enabled={false} />
        <ColumnChooser enabled={true} mode={"dragAndDrop"} /> {/*select*/}
        <Pager
          visible={true}
          allowedPageSizes={true}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Selection
          mode="single"
          selectAllMode="allPages"
          showCheckBoxesMode="always"
        />{" "}
        {/* select  allPages or "page" ----- showbox none or "onClick" | "onLongTap" | "always" */}
        <Editing
          refreshMode={"repaint"}
          mode="cell"
          allowAdding={role.FK_Role > 3}
          allowDeleting={role.FK_Role > 4}
          allowUpdating={role.FK_Role > 2}
        ></Editing>
        <Column
          dataField="PK_Planning"
          caption="ID"
          dataType="number"
          visible={false}
          allowEditing={false}
        />
        <Column
          dataField="FK_Facility"
          caption="Facility"
          dataType="numberv"
          visible={false}
        />
        <Column
          dataField="FK_Process"
          caption="Process"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="FK_Year"
          caption="Year"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="FK_Week"
          caption="Week"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="FK_Customer"
          caption="Customer"
          dataType="number"
          visible={true}
        >
          <Lookup
            dataSource={DSCutomer}
            valueExpr="PK_Customer"
            displayExpr="Customer"
          />
           <RequiredRule />
        </Column>
        <Column
          dataField="Remarks"
          caption="Remarks"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="isProjection"
          caption="Projection"
          dataType="boolean"
          visible={true}
        />
        <Column
          dataField="GoalUnit"
          caption={props.data.fkProcess == 8 ? "Hits" : "Units"}
          dataType="number"
          visible={true}
        >
          <FormatNumber type="fixedPoint" precision={0} />
          <RequiredRule />
        </Column>
        <Column
          dataField="GoalSetup"
          caption="Setups"
          dataType="number"
          visible={props.data.fkProcess == 8}
        >
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column
          dataField="Billing"
          caption="Billing"
          dataType="float"
          visible={props.data.colBilling}
        >
          <FormatNumber type="currency" precision={2} />
        </Column>
        <Column
          dataField="DateCreate"
          caption="Date"
          dataType="date"
          visible={false}
        />
        <Column
          dataField="FK_User"
          caption="FkUser"
          dataType="number"
          visible={false}
        />
        <Summary>
          <TotalItem
            column="PK_Planning"
            summaryType="count"
            valueFormat="#,##0"
          />
          <TotalItem
            column="GoalUnit"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem
            column="GoalSetup"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem
            column="Billing"
            summaryType="sum"
            displayFormat="${0}"
            valueFormat="#,##0.00"
          />
        </Summary>
        <Toolbar>
          <Item location="before">
            <SelectBox
              width="250"
              displayExpr="FacilityCode"
              showClearButton={true}
              dataSource={DSFacility}
              label="Select Facility"
              defaultValue={facility}
              valueExpr="PK_Facility"
              onValueChanged={onFacilityValueChanged}
              readOnly={ role.FK_Role < 6 && role.FK_Role > 2 }
            />
          </Item>

          <Item location="before">
            {actualWeek !== null ? (
              <SelectBox
                width="250"
                displayExpr="WeekName"
                showClearButton={false}
                dataSource={DSWeekNumber}
                label="Select Week"
                defaultValue={actualWeek}
                valueExpr="FK_Week"
                onValueChanged={onWeekValueChanged}
              />
            ) : (
              <div style={{ width: "250px" }}></div> // Placeholder to maintain layout
            )}
          </Item>

          <Item location="before">
            <TextBox
              width="250"
              showClearButton={true}
              defaultValue={null}
              label="Remarks"
              inputAttr={nameLabel}
              onValueChanged={onRemarksValueChanged}
            />
          </Item>

          <Item location="after">
            <ButtonItem icon="add" visible={role.FK_Role > 3} onClick={onAddingNew} />
          </Item>
          <Item location="after">
            <ButtonItem icon="refresh" visible={true} onClick={onRefresh} />
          </Item>
          <Item location="after">
            <ButtonItem
              icon="exportselected"
              visible={true}
              //onClick={exportSelected}
            />
          </Item>
          <Item
            name="columnChooserButton"
            locateInMenu="auto"
            location="after"
          />
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
        <Export enabled={true} allowExportSelectedData={true} />
      </DataGrid>
    </React.Fragment>
  );
}
