import React, { useState, useEffect, useCallback, useRef } from "react";
import SelectBox from "devextreme-react/select-box";
import notify from "devextreme/ui/notify";
import { Button as ButtonItem } from "devextreme-react/button";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Editing,
  Lookup,
  Summary,
  Selection,
  FilterRow,
  SearchPanel,
  Toolbar,
  Item,
  Format as FormatNumber,
  ColumnChooser,
  ColumnFixing,
  TotalItem,
  Scrolling,
  Export,
  HeaderFilter,
  FilterPanel,
  StateStoring,
} from "devextreme-react/data-grid";
import { DSFacility, DSProductionSummary } from "../data";
import "../planGrid.scss";
import { useAuth } from "../../../contexts/auth.js";

export default function ProductionSummary({ data, ChangeFkFacility }) {
  const { user } = useAuth();
  const [fkFacility, setFkFacility] = useState(data.fkFacility);
  const dataGridRef = useRef(null);

  function onFacilityValueChanged(e) {
    setFkFacility(e.value);
    ChangeFkFacility(e.value);
  }

  return (
    <React.Fragment>
      <DataGrid
        id="gridContainer"
        ref={dataGridRef}
        dataSource={DSProductionSummary(fkFacility, data.fkProcess)}
        height="100%"
        focusedRowEnabled={false}
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        allowColumnReordering={false}
        allowColumnResizing={true}
        showBorders={true}
        columnAutoWidth={true}
        showColumnHeaders={true}
        //onInitNewRow={onInitNewRow}
        //onSaved={refreshDataGrid}
        /* onExporting={onExporting}
            onCellPrepared={onCellPrepared}
            onSelectionChanged={onSelectionChanged}*/
      >
        <SearchPanel visible={true} />
        <Scrolling mode="standard" />
        <Paging defaultPageSize={10} />
        <HeaderFilter visible={true} />
        <FilterPanel visible={true} />
        <FilterRow visible={false} />
        <ColumnFixing enabled={false} />
        <ColumnChooser enabled={false} mode={"dragAndDrop"} /> {/*select*/}
        <Pager
          visible={true}
          allowedPageSizes={true}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Selection
          mode="single"
          selectAllMode="allPages"
          showCheckBoxesMode="always"
        />{" "}
        <Editing
          mode="cell"
          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}
        ></Editing>
        <Column
          dataField="PK_Production"
          caption="ID"
          dataType="number"
          visible={true}
          allowEditing={false}
        />
        <Column
          dataField="FkWeek"
          caption="Week"
          dataType="number"
          visible={true}
          allowEditing={false}
        />
        <Column
          dataField="DateProduction"
          caption="Date"
          dataType="date"
          visible={true}
          allowEditing={false}
        />
        <Column
          dataField="Hits"
          caption={data.fkProcess===12 ? "Units" : "Hits"}
          dataType="number"
          visible={true}
          allowEditing={false}
          >
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column
          dataField="Setup"
          caption="Setups"
          dataType="number"
          visible={
            data.fkProcess === 8 ||
            data.fkProcess === 9 ||
            data.fkProcess === 10
              ? true
              : false
          }
          allowEditing={false}
        />
        <Column
          dataField="TotalPress"
          caption="Total Presses"
          dataType="number"
          visible={
            data.fkProcess === 8 ||
            data.fkProcess === 9 ||
            data.fkProcess === 10
              ? true
              : false
          }
          allowEditing={false}
        />
        <Column
          dataField="DateCreate"
          caption="Date Create"
          dataType="date"
          visible={true}
          allowEditing={false}
        />
        <Column
          dataField="UserName"
          caption="User"
          dataType="text"
          visible={true}
          allowEditing={false}
        />
        <Summary>
            <TotalItem
              column="Hits"
              summaryType="sum"
              displayFormat="{0}"
              valueFormat="#,##0"
            />
            <TotalItem
              column="Setup"
              summaryType="sum"
              displayFormat="{0}"
              valueFormat="#,##0"
            />
          </Summary>
        <Toolbar>
          <Item location="before">
            <SelectBox
              width="250"
              displayExpr="FacilityCode"
              showClearButton={false}
              dataSource={DSFacility}
              label="Select Facility"
              defaultValue={fkFacility}
              valueExpr="PK_Facility"
              onValueChanged={onFacilityValueChanged}
              readOnly={false}
            />
          </Item>
          <Item location="after">
            <ButtonItem icon="refresh" visible={true} />
          </Item>
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
      </DataGrid>
    </React.Fragment>
  );
}
