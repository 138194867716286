import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import SelectBox from "devextreme-react/select-box";
import { Button as ButtonItem } from "devextreme-react/button";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Summary,
  Selection,
  FilterRow,
  SearchPanel,
  Toolbar,
  Item,
  Format as FormatNumber,
  ColumnChooser,
  ColumnFixing,
  TotalItem,
  Scrolling,
  Export,
  HeaderFilter,
  FilterPanel,
  StateStoring,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import DataSource from "devextreme/data/data_source";

import { useAuth } from "../../contexts/auth";
import { DSFacility, DSInventoryByKey, DSInventory } from "./data";
import "./wms.scss";

export default function GlobalInventory() {
  const { user, signOut } = useAuth();
  const [facility, setFacility] = useState();
  const dataGrid = useRef(null);

  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const year = String(today.getFullYear()).slice(2);
  const formattedDate = month + day + year;

  function onValueChanged(e) {
    setFacility(e.value);
  }

  const dataSourceKey = new DataSource({
    store: DSInventoryByKey(facility),
  });

  const dataSource = new DataSource({
    store: DSInventory,
  });

  function refreshDataGrid() {
    facility ? dataSourceKey.reload() : dataSource.reload();
  }

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`Inventory`);
    exportDataGrid({
      component: dataGrid.current.instance,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `GlobalInvenotry_${formattedDate}.xlsx`
        );
      });
    });
  };

  return (
    <React.Fragment>
      <DataGrid
        id="gridInventoryContainer"
        ref={dataGrid}
        dataSource={facility ? dataSourceKey : dataSource}
        height="100%"
        focusedRowEnabled={false}
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        allowColumnReordering={true}
        showBorders={true}
        columnAutoWidth={true}
        showColumnHeaders={true}
        onExporting={onExporting}
      >
        <SearchPanel visible={true} />
        <Scrolling mode="standard" />
        <Paging defaultPageSize={10} />
        <HeaderFilter visible={true} />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <ColumnFixing enabled={true} />
        <ColumnChooser enabled={true} />
        <Pager
          visible={true}
          allowedPageSizes={true}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey="globalInventory_1"
        />
        <Column
          dataField="Facility_id"
          sortOrder={"asc"}
          caption="ID"
          dataType="number"
          width={100}
          visible={false}
        />
        <Column
          dataField="Division"
          caption="Division"
          dataType="string"
          visible={true}
          width={120}
          fixed={false}
        />
        <Column
          dataField="Facility_Code"
          caption="Facility"
          dataType="string"
          visible={true}
          width={100}
          fixed={false}
        />
        <Column
          dataField="Style_Name"
          caption="Style"
          dataType="string"
          visible={true}
          width={150}
          fixed={false}
        />
        <Column
          dataField="Customer"
          caption="Customer"
          dataType="string"
          visible={true}
          width={120}
          fixed={false}
        />
        <Column
          dataField="Color_Name"
          caption="Color"
          dataType="string"
          visible={true}
          width={150}
        />
        <Column
          dataField="Part_No"
          caption="Part Number"
          dataType="string"
          visible={true}
          width={150}
        />
        <Column
          dataField="Concat"
          caption="HD Front Print"
          dataType="string"
          visible={false}
          width={150}
        />
        <Column
          dataField="Description"
          caption="Description"
          dataType="string"
          visible={true}
          width={150}
        />
        <Column
          dataField="hasTransfer"
          caption="Transfer"
          dataType="boolean"
          visible={true}
          width={150}
        />
        <Column
          dataField="isPrinted"
          caption="Printed"
          dataType="boolean"
          visible={true}
          width={150}
        />
        <Column
          dataField="Art_Name"
          caption="Art"
          dataType="string"
          visible={true}
          width={150}
        />
        <Column
          dataField="Inventory_Type"
          caption="Type"
          dataType="string"
          visible={true}
          width={150}
        />
        <Column
          dataField="Quality_Level"
          caption="Quality"
          dataType="string"
          visible={true}
          width={150}
        />
        <Column
          dataField="Warehouse"
          caption="Warehouse"
          dataType="string"
          visible={true}
          width={150}
        />
        <Column
          dataField="Size_GRP"
          caption="GRP"
          dataType="number"
          visible={true}
          width={100}
        />
        <Column dataField="S01" caption="XXS" dataType="number" visible={true}>
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column dataField="S02" caption="XS" dataType="number" visible={true}>
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column dataField="S03" caption="S" dataType="number" visible={true}>
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column dataField="S04" caption="M" dataType="number" visible={true}>
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column dataField="S05" caption="L" dataType="number" visible={true}>
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column dataField="S06" caption="XL" dataType="number" visible={true}>
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column dataField="S07" caption="2XL" dataType="number" visible={true}>
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column
          dataField="S08"
          caption="3XL"
          dataType="number"
          visible={true}
        />
        <Column dataField="S09" caption="4XL" dataType="number" visible={true}>
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column dataField="S10" caption="5XL" dataType="number" visible={true}>
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column dataField="S11" caption="6XL" dataType="number" visible={true}>
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column dataField="S12" caption="7XL" dataType="number" visible={true}>
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column
          dataField="Total"
          caption="Total"
          dataType="number"
          visible={true}
        >
          <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Summary>
          <TotalItem
            column="Style_Name"
            summaryType="count"
            valueFormat="#,##0"
          />
          <TotalItem
            column="S01"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem
            column="S02"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem
            column="S03"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem
            column="S04"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem column="S05" summaryType="sum" displayFormat="{0}" />
          <TotalItem
            column="S06"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem
            column="S07"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem
            column="S08"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem
            column="S09"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem
            column="S10"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem
            column="S11"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem
            column="S12"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
          <TotalItem
            column="Total"
            summaryType="sum"
            displayFormat="{0}"
            valueFormat="#,##0"
          />
        </Summary>
        <Toolbar>
          <Item location="before">
            <SelectBox
              width="350"
              displayExpr="FacilityCode"
              showClearButton={true}
              dataSource={DSFacility}
              label="Select Facility"
              defaultValue={facility}
              valueExpr="PK_Facility"
              onValueChanged={onValueChanged}
            />
          </Item>
          <Item location="after">
            <ButtonItem
              icon="refresh"
              visible={true}
              onClick={refreshDataGrid}
            />
          </Item>
          <Item location="after">
            <ButtonItem icon="export" visible={true} onClick={onExporting} />
          </Item>
          <Item
            name="columnChooserButton"
            locateInMenu="auto"
            location="after"
          />
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
        <Export enabled={true} allowExportSelectedData={true} />
      </DataGrid>
    </React.Fragment>
  );
}
