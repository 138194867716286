import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import SelectBox from "devextreme-react/select-box";
import { Button as ButtonItem } from "devextreme-react/button";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Editing,
  Summary,
  Selection,
  FilterRow,
  SearchPanel,
  Toolbar,
  Item,
  Format as FormatNumber,
  ColumnChooser,
  ColumnFixing,
  TotalItem,
  Scrolling,
  Export,
  HeaderFilter,
  FilterPanel,
  StateStoring,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";

import { useAuth } from "../../../contexts/auth";
import {
  DSCapacityOpenOrder,
  insertCapacityMaster,
} from "../data";
import "../planGrid.scss";

export default function OpenOrder() {
  const { user } = useAuth(); //user.idUser
  const [columnOrder, setColumnOrder] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const year = String(today.getFullYear()).slice(2);
  const formattedDate = month + day + year;
  const dataGrid = useRef(null);

  const dataSource = new DataSource({
    store: DSCapacityOpenOrder,
  });

  function refreshDataGrid() {
    dataSource.reload();
  }

  async function exportSelected() {
    if (selectedRowKeys.length > 0) {
      await insertCapacityMaster(3, 6, selectedRowKeys, user.idUser);
      notify(
        { message: "Master Id's Inserted successfully completed", shading: true },
        "success",
        1000
      );
    }
  };

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`Sewing`);
    exportDataGrid({
      component: dataGrid.current.instance,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `SewCapacity_${formattedDate}.xlsx`
        );
      });
    });
  };

  function onCellPrepared(e) {
    if (e.rowType === "header" && e.cellElement) {
      e.cellElement.style.textAlign = "center";
    }
    if (e.rowType === "data" && e.cellElement) {
      e.cellElement.style.textAlign = "left";
    }
  }

  const onSelectionChanged = useCallback(
    ({ selectedRowKeys: changedRowKeys, selectedRowsData }) => {
      setSelectedRowKeys(changedRowKeys);
    },
    []
  );

  return (
    <React.Fragment>
      <DataGrid
        id="gridContainer"
        ref={dataGrid}
        dataSource={dataSource}
        height="100%"
        focusedRowEnabled={false}
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        allowColumnReordering={false}
        allowColumnResizing={true}
        showBorders={true}
        columnAutoWidth={true}
        showColumnHeaders={true}
        onExporting={onExporting}
        onCellPrepared={onCellPrepared}
        onSelectionChanged={onSelectionChanged}
      >
        <SearchPanel visible={true} />
        <Scrolling mode="standard" />
        <Paging defaultPageSize={10} />
        <HeaderFilter visible={true} />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <ColumnFixing enabled={false} />
        <ColumnChooser enabled={true} mode={"dragAndDrop"} /> {/*select*/}
        <Pager
          visible={true}
          allowedPageSizes={true}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Selection
          mode="multiple"
          selectAllMode="allPages"
          showCheckBoxesMode="always"
        />{" "}
        {/* select  allPages or "page" ----- showbox none or "onClick" | "onLongTap" | "always" */}
        <Editing
          refreshMode={"repaint"}
          mode="cell"
          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}
        ></Editing>
        <Toolbar>
          <Item location="before">
            <SelectBox
              width="350"
              displayExpr="FacilityCode"
              showClearButton={true}
              //dataSource={DSFacility}
              label="Select Facility"
              //defaultValue={facility}
              valueExpr="PK_Facility"
              //onValueChanged={onValueChanged}
            />
          </Item>
          <Item location="after">
            <ButtonItem
              icon="exportselected"
              visible={true}
              onClick={exportSelected}
            />
          </Item>
          <Item location="after">
            <ButtonItem
              icon="refresh"
              visible={true}
              onClick={refreshDataGrid}
            />
          </Item>
          <Item location="after">
            <ButtonItem icon="export" visible={true} onClick={onExporting} />
          </Item>
          <Item
            name="columnChooserButton"
            locateInMenu="auto"
            location="after"
          />
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
        <Export enabled={true} allowExportSelectedData={true} />
      </DataGrid>
    </React.Fragment>
  );
}
