import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import SelectBox from "devextreme-react/select-box";
import { Button as ButtonItem } from "devextreme-react/button";
import DataGrid, {
  Column,
  Button,
  Pager,
  Paging,
  Editing,
  Summary,
  Selection,
  Lookup,
  FilterRow,
  SearchPanel,
  Toolbar,
  Item,
  Format as FormatNumber,
  ColumnChooser,
  ColumnFixing,
  TotalItem,
  Scrolling,
  Export,
  HeaderFilter,
  FilterPanel,
  StateStoring,
  MasterDetail,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import DataSource from "devextreme/data/data_source";

import { useAuth } from "../../../contexts/auth";
import { DetailTemplate } from "./sewingDetailTemplate.js";
import {
  arrayCapacityColumn,
  arrayContractor,
  arrayWorkStation,
  insertCapacityDetail,
  DSCapacityOpenOrder,
  updateCapacityColumnVisible,
  DSCapacity,
  DSContractor,
  DSWorkStation,
} from "../data";
import "../planGrid.scss";

export default function SewingCapacity() {
  const { user } = useAuth(); //user.idUser
  const [focusedRowKey, setFocusedRowKey] = useState(null);
  const [columnOrder, setColumnOrder] = useState([]);
  const [gridColumn, setGridColumn] = useState([]);
  //const [pkCapacity, setPkCapacity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const year = String(today.getFullYear()).slice(2);
  const formattedDate = month + day + year;
  const dataGrid = useRef(null);

  let pkCapacity = 0;

  const allowEdit = useCallback((e) => {
    return e.PK_CapacityDetailCount === 1;
  }, []);

  const dataSource = new DataSource({
    store: DSCapacity(3, 6),
  });

  function refreshDataGrid() {
    dataSource.reload();
  }

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      if (isSubscribed) {
        try {
          const contractorArray = await arrayContractor(6);
          const workStationArray = await arrayWorkStation(3, 6);
          const Columns = await arrayCapacityColumn(3, 6, 0);
          const updateCol = Columns.map((column) => {
            if (column.dataField === "FK_Contractor") {
              return {
                ...column,
                dataField: "FK_Contractor",
                caption: "Contractor",
                lookup: {
                  dataSource: contractorArray,
                  displayExpr: "ContractorName",
                  valueExpr: "PK_Contractor",
                },
              };
            } else if (column.dataField === "FK_WorkStation") {
              return {
                ...column,
                dataField: "FK_WorkStation",
                caption: "WorkStation",
                lookup: {
                  dataSource: workStationArray,
                  displayExpr: "WorkStation",
                  valueExpr: "PK_WorkStation",
                },
              };
            } else {
              return column;
            }
          });
          setGridColumn(updateCol);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
    return () => (isSubscribed = false);
  }, []);

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`Sewing`);
    exportDataGrid({
      component: dataGrid.current.instance,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `SewCapacity_${formattedDate}.xlsx`
        );
      });
    });
  };

  function onCellPrepared(e) {
    if (e.rowType === "header" && e.cellElement) {
      e.cellElement.style.textAlign = "center";
    }
    if (e.rowType === "data" && e.cellElement) {
      e.cellElement.style.textAlign = "left";
    }
  }

  async function onOptionChanged(e) {
    if (e.fullName && e.fullName.indexOf("columns[") === 0) {
      const columnField = e.fullName.split("]")[0].split("[")[1]; // Extracts the column index
      const column = e.component.columnOption(Number(columnField));

      if (column) {
        if (e.fullName.includes(".visible")) {
          const message = column.visible
            ? `Column shown: ${column.PK_Capacity_ColumnProcess}`
            : `Column hidden: ${column.PK_Capacity_ColumnProcess}`;
          //console.log(message);
          await updateCapacityColumnVisible(
            column.PK_Capacity_ColumnProcess,
            column.visible ? 1 : 0
          );
        }
      }
    }
  };

  function onRowPrepared(e) {
    if (e.rowType == "data" && e.data.PK_CapacityDetailCount === 1) {
      e.rowElement
      .querySelector(".dx-command-expand")
      .firstChild.classList.remove("dx-datagrid-group-closed");
      e.rowElement
      .querySelector(".dx-command-expand")
      .classList.remove("dx-datagrid-expand");
    }
    if (e.rowType == "data") {
      e.rowElement.style.height = "30px";
    }
  };

  function onSelectionChanged({ selectedRowsData }) {
    const data = selectedRowsData[0];
    pkCapacity = data.PK_Capacity;
  };

  function insertDataGrid() {
    if(pkCapacity !== undefined);
      {
        insertCapacityDetail(pkCapacity, 0, user.idUser);
        refreshDataGrid()
      }
  }

  return (
    <React.Fragment>
      {gridColumn.length > 0 ? (
        <DataGrid
          id="gridContainer"
          ref={dataGrid}
          dataSource={dataSource}
          height="100%"
          focusedRowEnabled={true}
          focusedRowKey={focusedRowKey}
          hoverStateEnabled={true}
          rowAlternationEnabled={true}
          allowColumnReordering={false}
          allowColumnResizing={true}
          columnResizingMode="widget"
          showBorders={true}
          columnAutoWidth={true}
          showColumnHeaders={true}
          onExporting={onExporting}
          columns={gridColumn}
          onCellPrepared={onCellPrepared}
          onRowPrepared={onRowPrepared}
          onOptionChanged={onOptionChanged}
          //onFocusedRowChanged={onFocusedRowChanged}
          onSelectionChanged={onSelectionChanged}
        >
          <Selection mode="single" />
          <SearchPanel visible={true} />
          <Scrolling mode="standard" />
          <Paging defaultPageSize={10} />
          <HeaderFilter visible={true} />
          <FilterPanel visible={true} />
          <FilterRow visible={true} />
          <ColumnFixing enabled={false} />
          <ColumnChooser enabled={true} mode={"dragAndDrop"} /> {/*select*/}
          <Pager
            visible={true}
            allowedPageSizes={true}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <StateStoring
            enabled={false}
            type="localStorage"
            storageKey="capacitySew_2"
          />
          <Editing
            refreshMode={"repaint"}
            mode="cell"
            allowAdding={true}
            allowDeleting={(e) => allowEdit(e.row.data)}
            allowUpdating={(e) => allowEdit(e.row.data)}
          ></Editing>
          <MasterDetail enabled={true} component={DetailTemplate} />
          <Summary>
            <TotalItem
              column="PK_Master"
              summaryType="count"
              valueFormat="#,##0"
            />
            <TotalItem
              column="TotalOrder"
              summaryType="sum"
              displayFormat="{0}"
              valueFormat="#,##0"
            />
            <TotalItem
              column="TotalSew"
              summaryType="sum"
              displayFormat="{0}"
              valueFormat="#,##0"
            />
            <TotalItem
              column="Total"
              summaryType="sum"
              displayFormat="{0}"
              valueFormat="#,##0"
            />
            {gridColumn.map((item, index) => (
              <TotalItem
                key={index}
                column={item.column}
                summaryType={item.summaryType}
                displayFormat={item.displayFormat}
                valueFormat={item.valueFormat}
              />
            ))}
          </Summary>
          <Toolbar>
            <Item location="before">
              <SelectBox
                width="350"
                displayExpr="FacilityCode"
                showClearButton={true}
                //dataSource={DSFacility}
                label="Select Facility"
                //defaultValue={facility}
                valueExpr="PK_Facility"
                //onValueChanged={onValueChanged}
              />
            </Item>
            <Item location="after">
              <ButtonItem icon="plus" visible={true} onClick={insertDataGrid} />
            </Item>
            <Item location="after">
              <ButtonItem
                icon="refresh"
                visible={true}
                onClick={refreshDataGrid}
              />
            </Item>
            <Item location="after">
              <ButtonItem icon="export" visible={true} onClick={onExporting} />
            </Item>
            <Item
              name="columnChooserButton"
              locateInMenu="auto"
              location="after"
            />
            <Item name="searchPanel" locateInMenu="auto" location="after" />
          </Toolbar>
          <Export enabled={true} allowExportSelectedData={true} />
        </DataGrid>
      ) : (
        <div>Loading...</div>
      )}
    </React.Fragment>
  );
}
