const menuItems = [
  {
    id: "1",
    name: "Quality Management",
    items: [
      {
        id: "1_1",
        name: "Process",
        price: 220,
        //icon: 'http://192.168.2.4:8087/Genisys/Image_Art/Icon/scissor-1-color.svg',
        items: [
          {
            id: "1_1_1",
            name: "Cutting",
            //icon: 'images/products/10.png',
            price: 160,
          },
          {
            id: "1_1_2",
            name: "Sewing",
            //icon: 'images/products/2.png',
            price: 270,
          },
          {
            id: "1_1_3",
            name: "Screen Printing",
            //icon: 'images/products/2.png',
            price: 270,
          },
          {
            id: "1_1_4",
            name: "Packing",
            //icon: 'images/products/2.png',
            price: 270,
            items: [
              {
                id: "1_1_4_1",
                name: "Overview",
              },
              {
                id: "1_1_4_2",
                name: "Process Audit",
              },
              {
                id: "1_1_4_3",
                name: "Final Audit",
              },
            ]
          },
          {
            id: "1_1_5",
            name: "Sublimation & Digital Print",
            //icon: 'images/products/2.png',
            price: 270,
          },
          {
            id: "1_1_6",
            name: "Garment Dye",
            //icon: 'images/products/2.png',
            price: 270,
          },
        ],
      },
      {
        id: "1_2",
        name: "Retrobrand Web",
        //icon: 'images/products/2.png',
        price: 270,
      },
      {
        id: "1_3",
        name: "Seconds & Irregular",
        //icon: 'images/products/2.png',
        price: 270,
      },
      {
        id: "1_4",
        name: "TOPs Follow up",
        //icon: 'images/products/2.png',
        price: 270,
      },
      {
        id: "1_5",
        name: "Auditoria 4 Puntos",
        //icon: 'images/products/2.png',
        price: 270,
      },
      {
        id: "1_6",
        name: "Emergency Audit",
        //icon: 'images/products/2.png',
        price: 270,
      },
    ],
  },
  {
    id: "2",
    name: "Quality Reporting",
    items: [
      {
        id: "2_1",
        name: "Process",
        price: 220,
        items: [
          {
            id: "2_1_1",
            name: "Cutting",
            //icon: 'images/products/10.png',
            price: 160,
          },
          {
            id: "2_1_2",
            name: "Sewing",
            //icon: 'images/products/2.png',
            price: 270,
          },
          {
            id: "2_1_3",
            name: "Screen Printing",
            //icon: 'images/products/2.png',
            price: 270,
          },
          {
            id: "2_1_4",
            name: "Packing",
            //icon: 'images/products/2.png',
            price: 270,
          },
          {
            id: "2_1_5",
            name: "Sublimation & Digital Print",
            //icon: 'images/products/2.png',
            price: 270,
          },
          {
            id: "2_1_6",
            name: "Garment Dye",
            //icon: 'images/products/2.png',
            price: 270,
          },
        ],
        //icon: 'images/products/1.png',
      },
      {
        id: "2_2",
        name: "Retrobrand Web",
        //icon: 'images/products/2.png',
        price: 270,
      },
      {
        id: "2_3",
        name: "Seconds & Irregular",
        //icon: 'images/products/2.png',
        price: 270,
      },
      {
        id: "2_4",
        name: "TOPs Follow up",
        //icon: 'images/products/2.png',
        price: 270,
      },
      {
        id: "2_5",
        name: "Auditoria 4 Puntos",
        //icon: 'images/products/2.png',
        price: 270,
      },
      {
        id: "2_6",
        name: "Emergency Audit",
        //icon: 'images/products/2.png',
        price: 270,
      },
    ],
  },
  {
    id: "3",
    name: "Specifications",
    items: [
      {
        id: "3_1",
        name: "Option 1",
        items: [
          {
            id: "3_1_1",
            name: "Suboption",
            //icon: 'images/products/10.png',
            price: 160,
          },
        ],
      },
      {
        id: "3_2",
        name: "Option 2",
        items: [
          {
            id: "3_2_1",
            name: "Suboption 2",
            //icon: 'images/products/12.png',
            price: 170,
          },
          {
            id: "3_2_2",
            name: "SubOption 3",
            //icon: 'images/products/13.png',
            price: 175,
          },
        ],
      },
    ],
  },
  {
    id: "4",
    name: "Preferences",
    items: [
      {
        id: "4_1",
        name: "People",
        icon: 'user',
        items: [
          {
            id: "4_1_1",
            name: "Process",
          },
          {
            id: "4_1_2",
            name: "Auditor Type",
          },
          {
            id: "4_1_3",
            name: "Auditor Role",
          },
        ],
      },
      {
        id: "4_2",
        name: "Access Rights",
        icon: 'key',
        items: [
          {
            id: "4_2_1",
            name: "Access Rights by Screen",
          },
          {
            id: "4_2_2",
            name: "Others",
          },
        ],
      },
    ],
  },
  {
    id: "5",
    name: "Dashboard",
  },
];

export default {
  getMenuItem() {
    return menuItems;
  },
};
