import React, { useState } from 'react';
import './profile.scss';
import Form from 'devextreme-react/form';
import { useAuth } from '../../contexts/auth';

export default function Profile() {
  const { user, signOut } = useAuth();
  const [notes, setNotes] = useState(
    'Sandra is a CPA and has been our controller since 2008. She loves to interact with staff so if you`ve not met her, be certain to say hi.\r\n\r\nSandra has 2 daughters both of whom are accomplished gymnasts.'
  );
  const employee = {
    ID: user.idUser,
    FirstName: user.FirstName,
    LastName: user.LastName,
    Prefix: 'Mr.',
    Picture: user.Avatar_Url,
  };

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Profile</h2>

      <div className={'content-block dx-card responsive-paddings'}>
        <div className={'form-avatar'}>
          <img
            alt={''}
            src={employee.Picture}
          />
        </div>
        <span>{employee.FirstName + ' ' + employee.LastName}</span>
      </div>

      <div className={'content-block dx-card responsive-paddings'}>
        <Form
          id={'form'}
          defaultFormData={employee}
          onFieldDataChanged={e => e.dataField === 'Notes' && setNotes(e.value)}
          labelLocation={'top'}
          colCountByScreen={colCountByScreen}
        />
      </div>
    </React.Fragment>
  );
}

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4
};
