import React, {
  useState,
  useEffect,
  useRef,
} from "react";
import {
  Form,
  GroupItem,
  Item,
} from "devextreme-react/form";
import DataGrid, {
  Column,
  Lookup,
  Editing,
  Selection,
  Popup,
  Form as FormItem,
  Scrolling,
  Item as ItemBar,
} from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";

import { qualityURL } from "../../../api/config";
import {
  dataArrayMeasure,
  arrayOrderSize,
  arrayMeasurement,
  dataArrayMeasureDetail,
  updateMeasure,
  deleteMeasure,
  updateMeasureDetail,
  deleteMeasureDetail,
} from "../data";
import { useAudit } from "../contexts/audit.js";

export default function AuditMeasurements({ props, onSaveMeasure, onSaveSamplingDetail }) {
  const {
    pkAudit,
    setPkAudit,
    auditArray,
    setAuditArray,
    fkFacility,
    setFkFacility,
    fkProcess,
    setFkProcess,
    process,
    setProcess,
    fkShift,
    setFkShift,
    master,
    setMaster,
    fkMasterOrder,
    setFkMasterOrder,
    fkAuditType,
    setFkAuditType,
    auditType,
    setAuditType,
    auditor,
    fkAuditor,
    setFkAuditor,
    fkSuperviser,
    setFkSuperviser,
    fkQualityLevel,
    setFkQualityLevel,
    fkWorkStation,
    setFkWorkStation,
    role,
    setRole,
    fkStatus,
    setFkStatus,
    remarks,
    setRemarks,
    setIdLastScreen,
    selectedItemKeys,
    setSelectedItemKeys,
    gridBoxValue,
    setGridBoxValue,
    dataGridVisible,
    setDataGridVisible,
    collapsedGroup,
    setCollapsedGroup,
    isListChecked,
    setIsListChecked,
    auditReadOnly,
    setAuditReadOnly,
    totalOrder,
    setTotalOrder,
    sampling, 
    setSampling,
    totalBoxes,
    setTotalBoxes,
    arraySampling,
    setArraySampling,
    arrayMeasure,
    setArrayMeasure,
    arrayMeasureDetail,
    setArrayMeasureDetail,
  } = useAudit();

  const [dataSourceMeasure, setDataSourceMeasure] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [dataSourceMeasureDetail, setDataSourceMeasureDetail] = useState([]);
  const [measurementList, setMeasurementList] = useState([]);

  const [detailVisible, setDetailVisible] = useState(false);
  const [pkAuditMeasure, setPkAuditMeasure] = useState(-1);
  const [newPkAuditMeasure, setNewPkAuditMeasure] = useState(-1);

  const dataGrid = useRef(null);
  const dataGridDetail = useRef(null);

  const getDetail = (key) =>
    new DataSource({
      store: new ArrayStore({
        data: dataSourceMeasureDetail,
        key: "PK_AuditMeasureDetail",
      }),
      filter: ["FK_AuditMeasure", "=", key],
    });
  const dataSource = getDetail(pkAuditMeasure);

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      if (isSubscribed) {
        setDataSourceMeasure(await dataArrayMeasure(pkAudit));
        setSizeList(await arrayOrderSize());
        setDataSourceMeasureDetail(await dataArrayMeasureDetail(pkAudit));
        setMeasurementList(await arrayMeasurement());
      }
    };
    fetchData().catch(console.error);
    return () => (isSubscribed = false);
  }, [pkAudit]);

  function onMeasureChanged({ selectedRowsData }) {
    const data = selectedRowsData[0];
    if (data) {
      setPkAuditMeasure(data.PK_AuditMeasure);
      setDetailVisible(true);
    }
  }

  function onMeasureInitNewRow(e) {
    e.data.PK_AuditMeasure = newPkAuditMeasure;
    setNewPkAuditMeasure(newPkAuditMeasure - 1);
  }

  const onRowInserted = (keyObject) => {
      //onSaveMeasure(dataSourceMeasure);
      setArrayMeasure(dataSourceMeasure)
  }

  const onGridUpdated = (e) => {
    //onSaveSamplingDetail(dataSourceMeasureDetail)
    setArrayMeasureDetail(dataSourceMeasureDetail)
 };
 
  const onGridRemoved = (keyObject) => {
    const key = keyObject.key.__KEY__;
    const index = dataSourceMeasure.findIndex(item => item.__KEY__ === key);
    if (index !== -1) {
      const updatedArray = [...dataSourceMeasure];
      updatedArray.splice(index, 1);
      setDataSourceMeasure(updatedArray);
      //onSaveMeasure(updatedArray);
      setArrayMeasure(dataSourceMeasure)
    } else {
      console.error(`Item with key ${key} not found in the array.`);
    }
  };

  const onRowUpdated = (keyObject) => {
    const key = keyObject.key.__KEY__;
    const index = dataSourceMeasure.findIndex(item => item.__KEY__ === key);
    if (index !== -1) {
      const updatedArray = [...dataSourceMeasure];
      updatedArray[index] = { ...updatedArray[index], ...keyObject.key };
      setDataSourceMeasure(updatedArray);
      //onSaveMeasure(updatedArray);
      setArrayMeasure(dataSourceMeasure)
    } else {
      console.error(`Item with key ${key} not found in the array.`);
    }
  }
 
  const onGridMeasureSaving = async (e) => {
    if (e.changes[0]?.type === "update") {
      const changedData = e.changes[0]?.data;
      if (changedData.PK_AuditMeasure) {
        const result = await updateMeasure(changedData);
      }
    }
    if (pkAudit > 0 && e.changes[0]?.type === "insert") {
      const changedData = e.changes[0]?.data;
      const myJSON = JSON.stringify({
        FK_Audit: pkAudit,
        FK_Size: changedData.FK_Size,
      });
      await fetch(`${qualityURL}/audit/measure/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: myJSON,
      });
      setDataSourceMeasure(await dataArrayMeasure(pkAudit));
    }
    setAuditArray((prevAudit) => ({
      ...prevAudit,
    }));
  };

  const onGridMeasureDeleting = async (e) => {
    const PK_AuditMeasure = e.data.PK_AuditMeasure;
    if (e.data.PK_AuditMeasure) {
      const result = await deleteMeasure(PK_AuditMeasure);
      setDataSourceMeasureDetail(await dataArrayMeasureDetail(pkAudit));
    }
  };

  function onMeasureDetailInitNewRow(e) {
    e.data.FK_AuditMeasure = pkAuditMeasure;
  }

  const onSavedMeasureDetail = async (e) => {
    if (e.changes[0]?.type === "update") {
      const changedData = e.changes[0]?.data;
      if (changedData.PK_AuditMeasureDetail) {
        const result = await updateMeasureDetail(changedData);
      }
    }
    if (pkAudit > 0 && e.changes[0]?.type === "insert") {
      const changedData = e.changes[0]?.data;
      const myJSON = JSON.stringify({
        FK_AuditMeasure: changedData.FK_AuditMeasure,
        FK_Measure: changedData.FK_Measure,
        Specs: changedData.Specs,
        Tolerance: changedData.Tolerance,
        RealValue: changedData.RealValue,
      });
      await fetch(`${qualityURL}/audit/measure/detail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: myJSON,
      });
      setDataSourceMeasureDetail(await dataArrayMeasureDetail(pkAudit));
    }
    setAuditArray((prevAudit) => ({
      ...prevAudit,
    }));
  };
  const onGridMeasureDetailDeleting = async (e) => {
    const PK_AuditMeasureDetail = e.data.PK_AuditMeasureDetail;
    if (e.data.PK_AuditMeasureDetail) {
      const result = await deleteMeasureDetail(PK_AuditMeasureDetail);
    }
  };

  const sizeEditorOptions = {
    label: "Size",
    dataSource: `${qualityURL}/order/size/${fkMasterOrder}`,
    valueExpr: "FK_Size",
    displayExpr: "SizeName",
    searchEnabled: true,
    readOnly: false,
  };

  const measureEditorOptions = {
    label: "Measurement",
    dataSource: measurementList,
    valueExpr: "PK_Measure",
    displayExpr: "Measure",
    searchEnabled: true,
    value: null,
    readOnly: false,
  };

  return (
    <React.Fragment>
      <span>Measures</span>
      <hr />
      <Form>
        <GroupItem colCount={3}>
          <GroupItem>
            <DataGrid
              ref={dataGrid}
              dataSource={dataSourceMeasure}
              hoverStateEnabled={true}
              selectAllMode="page"
              height="100%"
              showColumnHeaders={true}
              showBorders={true}
              visible={dataGridVisible}
              onSelectionChanged={onMeasureChanged}
              onInitNewRow={onMeasureInitNewRow}
              onSaved={onGridMeasureSaving}
              onRowRemoving={onGridMeasureDeleting}
              onRowInserted={onRowInserted}
              onRowUpdated={onRowUpdated}
              onRowRemoved={onGridRemoved}
            >
              <Selection mode="single" />
              <Editing
                refreshMode={"repaint"}
                mode="popup"
                allowAdding={!auditReadOnly}
                allowDeleting={!auditReadOnly}
                allowUpdating={!auditReadOnly}
              >
                <Popup
                  title="Measure Size"
                  showTitle={true}
                  width={500}
                  height={325}
                />
                <FormItem>
                  <Item itemType="group" colCount={1} colSpan={2}>
                    <Item
                      dataField="PK_AuditMeasure"
                      editorType="dxNumberBox"
                      visible={false}
                      caption={"ID"}
                    />
                    <Item
                      dataField="FK_Size"
                      editorType="dxSelectBox"
                      editorOptions={sizeEditorOptions}
                    />
                  </Item>
                </FormItem>
              </Editing>
              <Selection mode="single" />
              <Scrolling mode="standard" />
              <Column
                dataField="PK_AuditMeasure"
                caption="ID"
                dataType="number"
                visible={false}
              />
              <Column
                dataField="FK_Size"
                caption="Size"
                dataType="number"
                visible={true}
              >
                <Lookup
                  dataSource={sizeList}
                  valueExpr="PK_Size"
                  displayExpr="SizeName"
                />
              </Column>
            </DataGrid>
          </GroupItem>
          <GroupItem colSpan={2}>
            <DataGrid
              ref={dataGridDetail}
              dataSource={dataSource}
              hoverStateEnabled={true}
              height="100%"
              showColumnHeaders={true}
              showBorders={true}
              onInitNewRow={onMeasureDetailInitNewRow}
              onSaved={onSavedMeasureDetail}
              onRowRemoving={onGridMeasureDetailDeleting}
              onRowInserted={onGridUpdated}
              onRowUpdated={onGridUpdated}
              onRowRemoved={onGridUpdated}
              visible={!dataGridVisible ? false : detailVisible}
            >
              <Editing
                refreshMode={"repaint"}
                mode="popup"
                allowAdding={!auditReadOnly}
                allowDeleting={!auditReadOnly}
                allowUpdating={!auditReadOnly}
              >
                <Popup
                  title="Measure Details"
                  showTitle={true}
                  width={700}
                  height={400}
                />
                <FormItem>
                  <Item itemType="group" colCount={2} colSpan={2}>
                    <Item
                      dataField="FK_AuditMeasure"
                      editorType="dxNumberBox"
                      visible={false}
                    />
                    <Item
                      dataField="FK_Measure"
                      editorType="dxSelectBox"
                      editorOptions={measureEditorOptions}
                    />
                    <Item dataField="Specs" editorType="dxNumberBox" />
                    <Item dataField="Tolerance" editorType="dxNumberBox" />
                    <Item dataField="RealValue" editorType="dxNumberBox" />
                  </Item>
                </FormItem>
              </Editing>
              <Selection mode="single" />
              <Scrolling mode="standard" />
              <Column
                dataField="PK_AuditMeasureDetail"
                caption="ID"
                dataType="integer"
                visible={false}
              />
              <Column
                dataField="FK_AuditMeasure"
                caption="Audit Measure"
                dataType="number"
                visible={false}
              />
              <Column
                dataField="FK_Measure"
                caption="Measure"
                dataType="number"
                visible={true}
              >
                <Lookup
                  dataSource={measurementList}
                  valueExpr="PK_Measure"
                  displayExpr="Measure"
                />
              </Column>
              <Column
                dataField="Specs"
                caption="Specs"
                dataType="number"
                visible={true}
              />
              <Column
                dataField="Tolerance"
                caption="Tolerance"
                dataType="number"
                visible={true}
              />
              <Column
                dataField="RealValue"
                caption="Real"
                dataType="number"
                visible={true}
              />
            </DataGrid>
          </GroupItem>
        </GroupItem>
      </Form>
    </React.Fragment>
  );
}
