import React, { useState, useEffect, useCallback, useRef } from "react";
import { Form, GroupItem } from "devextreme-react/form";
import List from "devextreme-react/list";
import { useTheme } from "../../../contexts/theme.js";
import { useAudit } from "../contexts/audit.js";
import { qualityURL } from "../../../api/config.js";
import { preferences, dataSourceChecklist, arrayLogSession } from "../data.js";

export default function AuditChecklist({ props, onSelectedItemKeys, isChecked }) {
  const { getTheme } = useTheme();
  const {
    pkAudit,
    auditArray, 
    setAuditArray,
    fkProcess,
    selectedItemKeys, 
    setSelectedItemKeys,
    collapsedGroup,
    setCollapsedGroup,
    isListChecked,
    setIsListChecked,
    auditReadOnly,
  } = useAudit();

  const [theme, setTheme] = useState("orange.light");
  const [isCheckListChecked, setIsCheckListChecked] = useState(true);
  const [checklistDisabled, setChecklistDisabled] = useState(false);

  const [dataSourceList, setDataSourceList] = useState([]);
  const [internalSelectedItemKeys, setInternalSelectedItemKeys] = useState([]);

  const checkList = useRef(null);

  let currentTheme = getTheme();
  useEffect(() => {
    function themeResult() {
      setTheme(currentTheme);
      localStorage.setItem("lastScreen", "FinalAudit");
    }
    themeResult();
  }, [currentTheme]);

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      if (isSubscribed) {
        setChecklistDisabled(
          (await preferences(props.user.idUser, 1)) === 1 ? false : true
        );
        const session = await arrayLogSession(props.user.idUser);
        if (session.length > 0) {
          setDataSourceList(await dataSourceChecklist(session[0].FK_Process));
        }
        await fetch(`${qualityURL}/audit/checklist/${pkAudit}`)
          .then((res) => res.json())
          .then((data) => {
            const fkChecklistValues = data.map((item) => item.FK_Checklist);
            setSelectedItemKeys(fkChecklistValues);
          });
      }
    };
    fetchData().catch(console.error);
    return () => (isSubscribed = false);
  }, [pkAudit]);

  useEffect(() => {
    collapsedGroup ? expandGroup() : collapseGroup()
  }, [collapsedGroup]);

  const handleCheckListCheckboxChange = (event) => {
    setIsCheckListChecked(event.target.checked);
    if (event.target.checked === false) {
      collapseGroup();
      setCollapsedGroup(!collapsedGroup)
    }
    //isChecked(event.target.checked);
    setIsListChecked(event.target.checked);
    setAuditArray((prevAudit) => ({
      ...prevAudit,
    }));
  };
  function checkboxClassName() {
    switch (theme) {
      case "orange.light":
        return "orange-light-mode-checkbox";
      case "orange.dark":
        return "orange-dark-mode-checkbox";
      case "blue.light":
        return "blue-light-mode-checkbox";
      case "blue.dark":
        return "blue-dark-mode-checkbox";
      case "purple.light":
        return "purple-light-mode-checkbox";
      case "purple.dark":
        return "purple-dark-mode-checkbox";
    }
  }
  const collapseGroup = () => {
    if (checkList.current) {
      for (var i = 0; i < 10; i++) checkList.current.instance.collapseGroup(i);
      //setCollapsedGroup(true);
    }
  };
  const expandGroup = () => {
    if (checkList.current) {
      for (var i = 0; i < 10; i++) checkList.current.instance.expandGroup(i);
      //setCollapsedGroup(false);
    }
  };

  function onContentReady(e) {
    var items = e.component.option("items");
    for (var i = 0; i < items.length; i++) e.component.collapseGroup(i);
    //setCollapsedGroup(true);
  }

  const onSelectedItemKeysChange = useCallback(
    ({ name, value }) => {
      if (name === "selectedItemKeys") {
        //console.log(value)
        setSelectedItemKeys(value);
        //onSelectedItemKeys(value);

        //console.log(Audit)
        setAuditArray((prevAudit) => ({
          ...prevAudit,
        }));
      }
    },
    [setSelectedItemKeys]
  );

  async function onSelectionChanged(e) {
    const addedItems = e.addedItems;
    const removedItems = e.removedItems;
    if (pkAudit > 0 && addedItems.length > 0 && !auditReadOnly) {
      const myJSON = JSON.stringify({
        FK_Audit: pkAudit,
        FK_Checklist: addedItems[0].id,
      });
      await fetch(`${qualityURL}/audit/checklist/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: myJSON,
      });
    }
    if (pkAudit > 0 && removedItems.length > 0 && !auditReadOnly) {
      const myJSON = JSON.stringify({
        FK_Audit: pkAudit,
        FK_Checklist: removedItems[0].id,
      });
      await fetch(`${qualityURL}/audit/delete/checklist/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: myJSON,
      });
    }
  }

 /* function templateChecklist() {
    if (pkAudit > 0) {
      //return internalSelectedItemKeys.length > 0 && (
      return selectedItemKeys.length > 0 && (
        <List
          ref={checkList}
          dataSource={dataSourceList}
          height="400"
          grouped={true}
          selectionMode={"multiple"}
          selectedItemKeys={selectedItemKeys} //internalSelectedItemKeys
          showSelectionControls={true}
          collapsibleGroups={true}
          onContentReady={onContentReady}
          onOptionChanged={onSelectedItemKeysChange}
          onSelectionChanged={onSelectionChanged}
          selectByClick={true}
          visible={isCheckListChecked}
        />
      );
    } else {
      return (
        <List
          ref={checkList}
          dataSource={dataSourceList}
          height="400"
          grouped={true}
          selectionMode={"multiple"}
          selectedItemKeys={selectedItemKeys}
          showSelectionControls={true}
          collapsibleGroups={true}
          onContentReady={onContentReady}
          onOptionChanged={onSelectedItemKeysChange}
          onSelectionChanged={onSelectionChanged}
          selectByClick={true}
          visible={isCheckListChecked}
        />
      );
    }
  };*/

  return (
    <React.Fragment>
      <div className="sidebyside-container">
        <span className="leftside">Checklist</span>
        <div className="rightside">
          <input
            id="cbchecklist"
            type="checkbox"
            name="cb-checklist"
            value={"checklist"}
            checked={isCheckListChecked}
            onChange={handleCheckListCheckboxChange}
            className={checkboxClassName()}
            disabled={checklistDisabled}
          />
        </div>
      </div>
      <hr />
      <Form>
        <GroupItem className="list-container">
          {/*{ templateChecklist() }*/}
          <List
          ref={checkList}
          dataSource={dataSourceList}
          height="400"
          grouped={true}
          selectionMode={"multiple"}
          selectedItemKeys={selectedItemKeys}
          showSelectionControls={true}
          collapsibleGroups={true}
          onContentReady={onContentReady}
          onOptionChanged={onSelectedItemKeysChange}
          onSelectionChanged={onSelectionChanged}
          selectByClick={true}
          visible={isCheckListChecked}
        />
        </GroupItem>
      </Form>
      <br />
    </React.Fragment>
  );
}
