import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { SpeedDialAction } from "devextreme-react/speed-dial-action";
import notify from "devextreme/ui/notify";
import { saveAuditData, arrayAudit, finishAudit } from "../data";

import { useAuth } from "../../../contexts/auth.js";
import { useAudit } from "../contexts/audit.js";


export default function SpeedAction({ showLessColumns, onSaveAuditClick }) {
  const { user, signOut } = useAuth();
  const {
    pkAudit,
    setPkAudit,
    auditReadOnly,
    setAuditReadOnly,
    auditArray,
    setAuditArray,
    setFkShift,
    setMaster,
    fkMasterOrder,
    setFkMasterOrder,
    setFkAuditType,
    setFkQualityLevel,
    setFkWorkStation,
    role,
    setFkStatus,
    setRemarks,
    selectedItemKeys,
    setSelectedItemKeys,
    setGridBoxValue,
    setDataGridVisible,
    collapsedGroup,
    setCollapsedGroup,
    isListChecked,
    setTotalOrder,
    setSampling,
    arraySampling,
    setArraySampling,
    arrayMeasure,
    arrayMeasureDetail,
    setAuditFinish,
    setFkAuditor,
  } = useAudit();

  const [showLabel, setShowLabel] = useState("Show less...");
  const [collapsedLabel, setCollapsedLabel] = useState();
  const [visible, setVisible] = useState(false);

  const formOrder = useRef(null);

  useEffect(() => {
    collapsedGroup
      ? setCollapsedLabel("Collapse Checklist")
      : setCollapsedLabel("Expanse Checklist");
  }, [collapsedGroup]);

  function onNewClick() {
    setPkAudit(pkAudit < 0 ? (prevCount) => prevCount - 1 : -1);
    setFkAuditor(user.idUser);
    setCollapsedGroup(true);
    setArraySampling([]);
    setTotalOrder(0);
    setSampling(0);
    setAuditReadOnly(false);
    setGridBoxValue([]);
    setMaster([]);
    setFkMasterOrder(null);
    setFkAuditType(2);
    setFkQualityLevel(1);
    setFkShift(1);
    setFkWorkStation(1);
    setDataGridVisible(false);
    setFkStatus(3);
    setRemarks(null);
    setSelectedItemKeys([]);
  }

  async function onSaveClick() {
    //console.log("selectedItemKeys: ", selectedItemKeys);
    const result = await saveAuditData(
      pkAudit,
      auditArray,
      isListChecked,
      selectedItemKeys,
      arraySampling,
      arrayMeasure,
      arrayMeasureDetail
    );
    setPkAudit(result);
    const audit = await arrayAudit(result);
    onSaveAuditClick(audit[0]);

    const message =
      pkAudit > 0
        ? "Update successfully completed"
        : "Saved successfully completed";
    notify({ message: message, shading: true }, "success", 1000);
  }

  function deleteRow() {}

  function onCollapsedGroup() {
    //onCollapsedClick(!collapsedGroup);
    setCollapsedGroup(!collapsedGroup);
    setAuditArray((prevAudit) => ({
      ...prevAudit,
    }));
  }

  function onShowLessClick() {
    showLessColumns(visible);
    setVisible(!visible);
    setShowLabel(visible ? "Show less...." : "Show more...");
    setAuditArray((prevAudit) => ({
      ...prevAudit,
    }));
  }

  function onClickfinish() {
    finishAudit(pkAudit);
    const message = "Audit successfully completed";
    notify({ message: message, shading: true }, "success", 1000);
  }

  return (
    <React.Fragment>
      <SpeedDialAction
        icon="add"
        label="New Audit"
        index={1}
        visible={true}
        onClick={onNewClick}
      />
      <SpeedDialAction
        icon="check"
        label={"Save"}
        index={2}
        visible={
          fkMasterOrder > 0
            ? role.FK_Role > 2
              ? auditReadOnly
                ? false
                : true
              : false
            : false
        }
        onClick={onSaveClick}
      />
      <SpeedDialAction
        icon="trash"
        label="Delete Audit"
        index={3}
        visible={
          fkMasterOrder
            ? role.FK_Role > 4
              ? auditReadOnly
                ? false
                : true
              : false
            : false
        }
        onClick={deleteRow}
      />
      <SpeedDialAction
        icon="checklist"
        label={collapsedLabel}
        index={4}
        visible={fkMasterOrder ? (isListChecked ? true : false) : false}
        onClick={onCollapsedGroup}
      />
      <SpeedDialAction
        icon="fieldchooser"
        label={showLabel}
        index={5}
        visible={fkMasterOrder ? true : false}
        onClick={onShowLessClick}
      />
      <SpeedDialAction
        icon="bookmark"
        label={"Finish Audit"}
        index={6}
        visible={pkAudit > 0 ? (auditReadOnly ? false : true) : false}
        onClick={onClickfinish}
      />
    </React.Fragment>
  );
}
