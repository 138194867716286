import CustomStore from "devextreme/data/custom_store";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { sharedURL, planningURL, productionTrackURL } from "../../api/config";

export const accessRights = async (FK_User, FK_RoutIndex, MenuIndex) => {
  try {
    const response = await fetch(
      `${sharedURL}/auth/accessrights/${FK_User}/${FK_RoutIndex}/${MenuIndex}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    return result[0];
  } catch (error) {
    console.log(error.message);
  }
};

export const getActualWeek = async (actualDate) => {
  try {
    const response = await fetch(`${sharedURL}/weekbydate/${actualDate}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    return result[0];
  } catch (error) {
    console.log(error.message);
  }
};

export const DSFacility = new CustomStore({
  key: "PK_Facility",
  loadMode: "raw",
  load: () => {
    return fetch(`${sharedURL}/facility`).then((response) => response.json());
  },
});

export const DSProcess = new CustomStore({
  key: "PK_Process",
  loadMode: "raw",
  load: () => {
    return fetch(`${sharedURL}/process`).then((response) => response.json());
  },
});

export const DSShift = (FK_Facility, FK_Process) =>
  new CustomStore({
    key: "PK_Shift",
    loadMode: "raw",
    load: () => {
      return fetch(
        `${productionTrackURL}/production/shift/${FK_Facility}/${FK_Process}`
      ).then((response) => response.json());
    },
  });

export const DSProductionSummary = (FK_Facility, FK_Process) =>
  new CustomStore({
    key: "PK_Production",
    loadMode: "raw",
    dateSerializationFormat: "yyyy-MM-dd",
    load: () => {
      return fetch(
        `${productionTrackURL}/production/summary/${FK_Facility}/${FK_Process}`
      ).then((response) => response.json());
    },
  });

export const DSWeekNumber = new CustomStore({
  key: "Fk_Week",
  loadMode: "raw",
  load: () => {
    return fetch(`${sharedURL}/weeknumber`).then((response) => response.json());
  },
});
export const DSCutomer = new CustomStore({
  key: "PK_Customer",
  loadMode: "raw",
  load: () => {
    return fetch(`${productionTrackURL}/customer`).then((response) =>
      response.json()
    );
  },
});

export const DSCutomerOnline = new CustomStore({
  key: "PK_Customer",
  loadMode: "raw",
  load: () => {
    return fetch(`${productionTrackURL}/online/customer`).then((response) =>
      response.json()
    );
  },
});

export const DSPress = (FK_Facility, FK_Process) =>
  new CustomStore({
    key: "PK_Press",
    loadMode: "raw",
    load: () => {
      return fetch(
        `${productionTrackURL}/process/press/${FK_Facility}/${FK_Process}`
      ).then((response) => response.json());
    },
  });

export const DSPlacement = new CustomStore({
  key: "PK_Placement",
  loadMode: "raw",
  load: () => {
    return fetch(`${productionTrackURL}/placement`).then((response) =>
      response.json()
    );
  },
});

export async function arrayOpenOrder() {
  try {
    const url = `${planningURL}/capacity/openorder`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to fetch data:", error);
    return [];
  }
}

export async function arrayProductionDetailPivot(FK_Facility, FK_Process) {
  try {
    const url = `${productionTrackURL}/production/detail/report/${FK_Facility}/${FK_Process}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to fetch data:", error);
    return [];
  }
}

function formatDate(dateString) {
  if (!dateString) {
    return "";
  } else {
    const date = new Date(dateString);
    let month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  }
}

export const dsProductionTrack = (FK_Facility, FK_Process, Fk_Week) => {
  const DataSourceProductionTrack = new CustomStore({
    key: "PK_Planning",
    dateSerializationFormat: "yyyy-MM-dd",
    load: () =>
      sendRequest(
        `${productionTrackURL}/${FK_Facility}/${FK_Process}/${Fk_Week}`
      ),
    insert: (values) => sendRequest(`${productionTrackURL}`, "POST", values),
    update: (key, values) =>
      sendRequest(`${productionTrackURL}/${key}`, "PUT", values),
    remove: (key) => sendRequest(`${productionTrackURL}/${key}`, "DELETE"),
    requests: [],
    refreshMode: "reshape",
  });

  async function sendRequest(url, method = "GET", data = {}) {
    if (method === "GET") {
      return await fetch(url, {
        method,
        credentials: "include",
      }).then((result) =>
        result.json().then((json) => {
          if (result.ok) return json;
          throw json.Message;
        })
      );
    }

    if (method === "DELETE") {
      return await fetch(url, {
        method,
        credentials: "include",
      });
    }

    // For POST and PUT requests
    //console.log( JSON.stringify(data))
    return await fetch(url, {
      method,
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => (text ? JSON.parse(text) : {}));
      }
      return result.json().then((json) => {
        throw json.Message;
      });
    });
  }

  return DataSourceProductionTrack;
};

export const dsProcessCapacity = (FK_Facility, FK_Process, Fk_Year) => {
  const DataSourceProductionTrack = new CustomStore({
    key: "PK_ProcessCapacity",
    dateSerializationFormat: "yyyy-MM-dd",
    load: () =>
      sendRequest(
        `${productionTrackURL}/processcapacity/${FK_Facility}/${FK_Process}/${Fk_Year}`
      ),
    insert: (values) =>
      sendRequest(`${productionTrackURL}/processcapacity`, "POST", values),
    update: (key, values) =>
      sendRequest(
        `${productionTrackURL}/processcapacity/${key}`,
        "PUT",
        values
      ),
    remove: (key) =>
      sendRequest(`${productionTrackURL}/processcapacity/${key}`, "DELETE"),
    requests: [],
    refreshMode: "reshape",
  });

  async function sendRequest(url, method = "GET", data = {}) {
    if (method === "GET") {
      return await fetch(url, {
        method,
        credentials: "include",
      }).then((result) =>
        result.json().then((json) => {
          if (result.ok) return json;
          throw json.Message;
        })
      );
    }

    if (method === "DELETE") {
      return await fetch(url, {
        method,
        credentials: "include",
      });
    }

    // For POST and PUT requests
    //console.log( JSON.stringify(data))
    return await fetch(url, {
      method,
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => (text ? JSON.parse(text) : {}));
      }
      return result.json().then((json) => {
        throw json.Message;
      });
    });
  }

  return DataSourceProductionTrack;
};

export async function insertCapacity(data) {
  var url = `${productionTrackURL}/processcapacity`;
  return await fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => (text ? JSON.parse(text) : {}));
    }
    return result.json().then((json) => {
      throw json.Message;
    });
  });
}

export const dsProductionDetail = (
  FK_Facility,
  FK_Process,
  FK_Shift,
  DateProduction
) => {
  const DataSourceProductionTrack = new CustomStore({
    key: "PK_ProductionDetail",
    dateSerializationFormat: "yyyy-MM-dd",
    load: () =>
      sendRequest(
        `${productionTrackURL}/production/detail/${FK_Facility}/${FK_Process}/${FK_Shift}/${DateProduction}`
      ),
    insert: (values) =>
      sendRequest(`${productionTrackURL}/production/detail`, "POST", values),
    update: (key, values) =>
      sendRequest(
        `${productionTrackURL}/production/detail/${key}`,
        "PUT",
        values
      ),
    remove: (key) =>
      sendRequest(`${productionTrackURL}/production/detail/${key}`, "DELETE"),
    requests: [],
    refreshMode: "reshape",
  });

  async function sendRequest(url, method = "GET", data = {}) {
    if (method === "GET") {
      return await fetch(url, {
        method,
        credentials: "include",
      }).then((result) =>
        result.json().then((json) => {
          if (result.ok) return json;
          throw json.Message;
        })
      );
    }

    if (method === "DELETE") {
      return await fetch(url, {
        method,
        credentials: "include",
      });
    }

    // For POST and PUT requests
    //console.log( JSON.stringify(data))
    return await fetch(url, {
      method,
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => (text ? JSON.parse(text) : {}));
      }
      return result.json().then((json) => {
        throw json.Message;
      });
    });
  }

  return DataSourceProductionTrack;
};

export const DSDprReport = (FK_Facility, FK_Process, FK_Year) =>
  new CustomStore({
    key: "FK_Customer",
    loadMode: "raw",
    load: async () => {
      const response = await fetch(
        `${productionTrackURL}/dpr/report/${FK_Facility}/${FK_Process}/${FK_Year}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    },
    update: (key, values) => {
      return key ? console.log(key, values) : [];
    },
  });

export async function arrayDprReport(FK_Facility, FK_Process, FK_Year) {
  try {
    const url = `${productionTrackURL}/dpr/report/${FK_Facility}/${FK_Process}/${FK_Year}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to fetch data:", error);
    return [];
  }
}

export async function arrayDprColumn() {
  try {
    const url = `${productionTrackURL}/dpr/column`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to fetch data:", error);
    return [];
  }
}


