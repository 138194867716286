import React, { useState, useEffect, useCallback, useRef } from "react";
import SelectBox from "devextreme-react/select-box";
import {
  PivotGrid,
  Scrolling,
  Export,
  HeaderFilter,
  Search,
  FieldChooser,
  FieldPanel,
} from "devextreme-react/pivot-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportPivotGrid } from "devextreme/excel_exporter";

import { accessRights, DSFacility, getActualWeek } from "../data";
import { useAuth } from "../../../contexts/auth.js";
import { productionTrackURL } from "../../../api/config";

export default function ProductionPivotReport({ data }) {
  const { user } = useAuth();
  const [role, SetRole] = useState(2);
  const [actualWeek, setActualWeek] = useState(null);
  const [fkFacility, setFkFacility] = useState(user.FK_Facility);
  const [fkProcess, setFkProcess] = useState(data.fkProcess);
  const today = new Date();
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      const week = await getActualWeek(today);
      try {
        if (isSubscribed) {
          const roleResult = await accessRights(user.idUser, 1004, "30");
          SetRole(roleResult != undefined ? roleResult : 2);
          setActualWeek(week.FkWeek);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    return () => {
      isSubscribed = false;
    };
  }, []);

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Sales");
    exportPivotGrid({
      component: e.component,
      worksheet,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "Sales.xlsx"
        );
      });
    });
  };

  const pivotDataSouce = createStore({
    key: "FK_Production",
    loadUrl: `${productionTrackURL}/production/detail/report/${fkFacility}/${fkProcess}`,
  });

  function onFacilityValueChanged(e) {
    setFkFacility(e.value);
  }

  const dataSource = new PivotGridDataSource({
    fields: [
      {
        caption: "Week",
        dataField: "FK_Week",
        area: "filter",
        filterValues: [actualWeek],
      },
      {
        dataField: "DateProduction",
        dataType: "date",
        area: "filter",
      },
      {
        dataField: "Shift",
        dataType: "string",
        area: "filter",
      },
      {
        caption: "Customer",
        width: 120,
        dataField: "Customer",
        area: "row",
      },
      {
        caption: "Day",
        width: 80,
        dataField: "ProductionDay",
        dataType: "string",
        area: "column",
        sortingMethod: (a, b) => {
          return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b);
        },
      },
      {
        caption: "PressName",
        width: 60,
        dataField: "PressName",
        area: "column",
      },
      {
        caption: "Placement",
        width: 80,
        dataField: "Placement",
        area: "column",
      },
      {
        dataField: "Hits",
        caption: "Hits",
        dataType: "number",
        summaryType: "sum",
        format: "fixedPoint",
        area: "data",
      },
      {
        dataField: "Setup",
        caption: "Setups",
        dataType: "number",
        summaryType: "sum",
        area: "data",
      },
    ],
    store: pivotDataSouce,
  });

  return (
    <React.Fragment>
      <SelectBox
        width="250"
        displayExpr="FacilityCode"
        showClearButton={false}
        dataSource={DSFacility}
        label="Select Facility"
        defaultValue={fkFacility}
        valueExpr="PK_Facility"
        onValueChanged={onFacilityValueChanged}
        readOnly={role.FK_Role < 6 && role.FK_Role > 2}
      />
      <br />
      <PivotGrid
        allowSorting={true}
        allowSortingBySummary={true}
        allowFiltering={true}
        height={1200}
        showBorders={true}
        rowHeaderLayout="tree"
        dataSource={dataSource}
        onExporting={onExporting}
      >
        <Scrolling mode="virtual" />
        <Export enabled={true} />
      </PivotGrid>
    </React.Fragment>
  );
}
