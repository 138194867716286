import React, { useState, useEffect } from "react";

export default function DashboardTemplate({ props }) {
  const [htmFrame, setHtmFrame] = useState();

  useEffect(() => {
    if (props.salesRepCode === "BHAG")
      setHtmFrame(
        "https://app.powerbi.com/view?r=eyJrIjoiZmQ3ODAxZGQtYzA2Ni00Njk1LWJjYmItNzFkZTAyYmQ2ODA3IiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9&pageName=ReportSection"
      );
    if (props.salesRepCode === "CKIM")
      setHtmFrame(
        "https://app.powerbi.com/view?r=eyJrIjoiYTg0MTRlOTctMjRiOC00NThiLTk2NzQtMzI3YmYzY2UxN2VhIiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9&pageName=ReportSection"
      );
    if (props.salesRepCode === "DMUL")
      setHtmFrame(
        "https://app.powerbi.com/view?r=eyJrIjoiYmJhZTg1YzYtZTUxOC00YWJiLTlkM2QtMTViNGZjMGI5YWZiIiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9&pageName=ReportSection"
      );
    if (props.salesRepCode === "JHOF")
      setHtmFrame(
        "https://app.powerbi.com/view?r=eyJrIjoiZWM4MTQxNjEtOTYyMy00ZjA4LWFiNjktOTRjNmNmZjhkODRhIiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9&pageName=ReportSection"
      );
    if (props.salesRepCode === "JTG")
      setHtmFrame(
        "https://app.powerbi.com/view?r=eyJrIjoiNDhkMWNlOGUtMTk0MC00YTkyLTlhZDctMTJhNWQwZGIwYjRkIiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9&pageName=ReportSection"
      );
    if (props.salesRepCode === "MGLN")
      setHtmFrame(
        "https://app.powerbi.com/view?r=eyJrIjoiZDU0MDc4OWQtOGQzYS00MzBlLTg3ODctYmY0MGQ5M2FkOGVhIiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9&pageName=ReportSection"
      );
    if (props.salesRepCode === "NED")
      setHtmFrame(
        "https://app.powerbi.com/view?r=eyJrIjoiMTlmNDY5ZDQtNTBlMS00Zjc2LWI2ZjItMGNhNDZjYWI3ZDk1IiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9&pageName=ReportSection"
      );
    if (props.salesRepCode === "PLIP")
      setHtmFrame(
        "https://app.powerbi.com/view?r=eyJrIjoiYzA0OGZjY2QtYzQ3Ni00ZjJhLTg2NjYtYjlhOGU2MjY0MTcyIiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9&pageName=ReportSection"
      );
    if (props.salesRepCode === "RBJN")
      setHtmFrame(
        "https://app.powerbi.com/view?r=eyJrIjoiOTJjYTgwZWMtNzkzMy00MWJhLTkzNmEtMzM1YjA1YWYyMzc4IiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9&pageName=ReportSection"
      );
    if (props.salesRepCode === "ROB")
      setHtmFrame(
        "https://app.powerbi.com/view?r=eyJrIjoiNzAwMmNkZjctZDg3NS00Zjc3LWE4ZGMtNGNiMzU1MWRlMTQ4IiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9&pageName=ReportSection"
      );
    if (props.salesRepCode === "SCH")
      setHtmFrame(
        "https://app.powerbi.com/view?r=eyJrIjoiYjk2ZTE5NjgtYmVlZC00OTRiLThlYjctZjY2NjUyZWRkZTVjIiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9&pageName=ReportSection"
      );
    if (props.salesRepCode === "SOLO")
      setHtmFrame(
        "https://app.powerbi.com/view?r=eyJrIjoiNDAxNjE5YjgtMmIwMS00OGIyLWJlNDctOTFlYzNlYmQ5MDM5IiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9&pageName=ReportSection"
      );
    if (props.salesRepCode === "TDAW")
      setHtmFrame(
        "https://app.powerbi.com/view?r=eyJrIjoiMWIzNzZhZWEtOWZkYS00MWRmLTkzMzktMTI3Y2JkNzZjYTRkIiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9&pageName=ReportSection"
      );
  }, [props]);

  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <iframe
        title="The Original Retrobrand Sales by SR"
        width="100%"
        height="1200"
        src={htmFrame}
        style={{ border: "none" }}
        allowFullScreen={true}
      />
    </div>
  );
}
