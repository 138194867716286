import React, {
  useState,
  useEffect,
  useRef,
} from "react";
import DropDownBox from "devextreme-react/drop-down-box";
import DataGrid, {
  Selection,
  Pager,
  Scrolling,
  Paging,
  FilterRow,
} from "devextreme-react/data-grid";

import { qualityURL } from "../../../api/config";
import { dataSourceMasterOrder } from "../data";
import { useAudit } from "../contexts/audit.js";

export default function MasterDropBox({ props }) {
  const {
    pkAudit,
    setMaster,
    fkMasterOrder,
    setFkMasterOrder,
    setTotalOrder,
    gridBoxValue,
    setGridBoxValue,
    setDataGridVisible,
  } = useAudit();

  const [masterColumns, setMasterColumns] = useState([]);
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const [isDropBoxVisible, setIsDropBoxVisible] = useState(true);

  const dropBox = useRef(null);
  const ownerLabel = { "aria-label": "Owner" };
  const gridColumns = masterColumns;

  function refreshMasterOrder() {
    dataSourceMasterOrder.reload();
  }

  useEffect(() => {
    fetch(`${qualityURL}/columnsearch`)
      .then((res) => res.json())
      .then((data) => {
        const columValues = data.map((row) => row.MasterColumn);
        setMasterColumns(columValues);
      })
      .catch((error) => console.error("Error fetching columns data:", error));
  }, []);

  useEffect(() => {
    if (pkAudit && fkMasterOrder) {
      fetch(`${qualityURL}/order/${fkMasterOrder}`)
        .then((res) => res.json())
        .then((data) => {
          setMaster(data[0]);
          setFkMasterOrder(data[0].PK_Master);
          setTotalOrder(data[0].TotalOrder);
          setDataGridVisible(true);
        })
        .catch((error) => console.error("Error fetching load data:", error));
    } else {
    }
  }, [pkAudit]);
  

  function OnSearchValueChanged(e) {
    if (e.value[0] != null) {
      fetch(`${qualityURL}/order/${e.value[0]}`)
        .then((res) => res.json())
        .then((data) => {
          setMaster(data[0]);
          setFkMasterOrder(data[0].PK_Master);
          setTotalOrder(data[0].TotalOrder);
          setDataGridVisible(true);
        })
        .catch((error) => console.error("Error fetching columns data:", error));
    } else {
    }
  }

  function dataGridRender() {
    return (
      <DataGrid
        dataSource={dataSourceMasterOrder}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={gridBoxValue}
        columnAutoWidth={true}
        onSelectionChanged={dataGridOnSelectionChanged}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="standard" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={true}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
    );
  }

  function gridBoxDisplayExpr(item) {
    return item && `ID: ${item.PK_Master} <PO: ${item.PO}>`;
  }

  function syncDataGridSelection(e) {
    setGridBoxValue(e.value);
    if (e.value) {
      OnSearchValueChanged(e);

    }
    else {
      setMaster([]);
      setFkMasterOrder(null);
      setTotalOrder(0);
      setDataGridVisible(false);
    }
  }

  function onGridBoxOpened(e) {
    if (e.name === "opened") {
      setIsGridBoxOpened(e.value);
    }
  }

  function dataGridOnSelectionChanged(e) {
    setGridBoxValue(e.selectedRowKeys);
    setIsGridBoxOpened(false);
  }

  return (
    <React.Fragment>
      <DropDownBox
        ref={dropBox}
        dataSource={dataSourceMasterOrder}
        value={gridBoxValue}
        opened={isGridBoxOpened}
        valueExpr={"PK_Master"}
        deferRendering={false}
        inputAttr={ownerLabel}
        displayExpr={gridBoxDisplayExpr}
        placeholder="Select an order..."
        showClearButton={true}
        onValueChanged={syncDataGridSelection}
        onOptionChanged={onGridBoxOpened}
        contentRender={dataGridRender}
        disabled={false}
        visible={isDropBoxVisible}
        onOpened={refreshMasterOrder}
      />
    </React.Fragment>
  );
}
