import React, { useState, useEffect } from "react";
import { Button } from "devextreme-react/button";
import Gallery from "devextreme-react/gallery";
import FileUploader from "devextreme-react/file-uploader";
import notify from "devextreme/ui/notify";
import DataSource from "devextreme/data/data_source";
import { createStore } from "devextreme-aspnet-data-nojquery";

import { qualityURL, auditImageUploadURL } from "../../../api/config";
import imageItem from "./item.js";
import { useAudit } from "../contexts/audit.js";

export default function ImageUploader({ props }) {
  const {pkAudit, auditReadOnly} = useAudit();
  const [targetElement, setTargetElement] = useState(null);
  const [index, setIndex] = useState(0);
  const [idImage, setIdImage] = useState(null);

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      if (isSubscribed) {
        setTargetElement(document.querySelector(".open-button"));
      }
    };
    fetchData().catch(console.error);
    return () => (isSubscribed = false);
  }, [pkAudit]);
  
  const imageDataSource = createStore({
    keyExpr: "PK_AuditImage",
    loadUrl: `${qualityURL}/audit/image/${pkAudit}`,
  });

  const galleryDataSource = new DataSource({
    store: imageDataSource,
    requireTotalCount: true,
  });

  function refresImageData() {
    galleryDataSource
      .reload()
      .then(() => {
        setIndex(galleryDataSource.items().length);
      })
      .catch((error) => {
        console.error("Error al recargar datos:", error);
      });
  }


  const addIdParameter = (e) => {
    const uploadUrl = updateQueryStringParameter(
      e.component.option("uploadUrl"),
      "FK_Audit",
      pkAudit
    );
    e.component.option("uploadUrl", uploadUrl);
  };

  const updateQueryStringParameter = (uri, key, value) => {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
    const separator = uri.indexOf("?") !== -1 ? "&" : "?";

    if (uri.match(re)) {
      return uri.replace(re, `$1${key}=${value}$2`);
    } else {
      return uri + separator + `${key}=${value}`;
    }
  };

  function onItemClick(e) {
    setIdImage(e.itemData.PK_AuditImage);
  };

  async function onUploaded(e) {
    const imagePath = `http://192.168.2.4:8087/Genisys/Image_Art/Quality/${pkAudit}-${e.file.name}`;
    const myJSON = JSON.stringify({
      FK_Audit: pkAudit,
      ImagePath: imagePath,
      isDelete: false,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: myJSON,
    };
    try {
      const response = await fetch(`${qualityURL}/audit/image`, requestOptions);
      if (response.ok) {
        refresImageData();
      }
    } catch (error) {
      console.log(error);
    }
  }

  function onClickDelete() {
    if (idImage) {
      fetch(`${qualityURL}/audit/image/${idImage}`, { method: "DELETE" })
        .then(() => {
          setIdImage(null);
          notify(
            { message: "Delete successfully completed", shading: true },
            "success",
            1000
          );
        })
        .catch((error) => {
          notify({ message: "Delete Error", shading: true }, "error", 1000);
        });
    } else {
      notify("Pleaes select an image to delete", "error", 1000);
    }
  }

  return (
    <React.Fragment>
      <div className="widget-container" align="center">
        <Gallery
          id="dxGallery_container"
          dataSource={galleryDataSource}
          itemRender={imageItem}
          height={600}
          width={800}
          loop={true}
          showNavButtons={true}
          showIndicator={true}
          defaultSelectedIndex={index}
          onItemClick={onItemClick}
        />
      </div>
      <div className="widget-container" align="center">
        <FileUploader
          dialogTrigger={targetElement}
          selectButtonText="Select photo"
          multiple={true}
          accept="image/*"
          uploadMode="instantly"
          onValueChanged={addIdParameter}
          uploadUrl={auditImageUploadURL}
          onUploaded={onUploaded}
          disabled={pkAudit > 0 ? (auditReadOnly ? true : false) : true}
        />
      </div>

      <div className="buttons" align="center">
        <Button
          text="Delete Photo"
          icon="trash"
          type="danger"
          onClick={onClickDelete}
          stylingMode="contained"
          useSubmitBehavior={true}
          disabled={auditReadOnly}
        />
      </div>
    </React.Fragment>
  );
}
