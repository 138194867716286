import React, { useState, useEffect } from "react";
import { Form, GroupItem, Item } from "devextreme-react/form";
import { DSQualityStatus } from "../data";
import { useAudit } from "../contexts/audit.js";

export default function AuditConclusion({ props, onSaveClick }) {
  const {
    auditArray,
    fkStatus,
    remarks,
  } = useAudit();

  const [auditConclusion, setAuditConclusion] = useState([]);
  const [qualityStatus, setQualityStatus] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      if (isSubscribed) {
        setQualityStatus(await DSQualityStatus());
      }
    };
    fetchData().catch(console.error);
    return () => (isSubscribed = false);
  }, []);

  const statusEditorOptions = {
    label: "Status",
    dataSource: qualityStatus,
    valueExpr: "PK_Status",
    displayExpr: "Status",
    searchEnabled: true,
    value: fkStatus,
    readOnly: false,
  };
  const remarksEditorOptions = {
    label: "Remarks",
    value: remarks,
    readOnly: false,
  };

  function onFieldDataChanged(e) {
    //onSaveClick(auditConclusion);
  }

  return (
    <React.Fragment>
      <span>Conclusion</span>
      <hr />
      <Form
        formData={auditArray}
        readOnly={false}
        labelMode="floating"
        labelLocation="top"
        minColWidth={300}
        onFieldDataChanged={onFieldDataChanged}
      >
        <GroupItem>
          <Item
            dataField="FK_Status"
            editorType="dxSelectBox"
            editorOptions={statusEditorOptions}
          />
          <Item
            dataField="Remarks"
            editorType="dxTextArea"
            editorOptions={remarksEditorOptions}
          />
        </GroupItem>
      </Form>
    </React.Fragment>
  );
}
