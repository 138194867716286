
const menuItemsVP = [
  {
    id: "1",
    name: "Database",
    visible: true,
  },
  {
    id: "2",
    name: "Summary",
    visible: true,
  },
  {
    id: "3",
    name: "License",
    visible: true,
  },
  {
    id: "4",
    name: "Sales Rep. Dashboard",
    visible: true,
    items: [
      {
        id: "4_1",
        name: "Brian Haggbloom",
        visible: true,
      },
      {
        id: "4_2",
        name: "Candace Kim",
        visible: true,
      },
      {
        id: "4_3",
        name: "David Mullen",
        visible: true,
      },
      {
        id: "4_4",
        name: "Jon Hofer",
        visible: true,
      },
      {
        id: "4_5",
        name: "JT Galloway",
        visible: true,
      },
      {
        id: "4_6",
        name: "Megan Leinonen",
        visible: true,
      },
      {
        id: "4_7",
        name: "Ned Jones",
        visible: true,
      },
      {
        id: "4_8",
        name: "Paul Lippman",
        visible: true,
      },
      {
        id: "4_9",
        name: "Rob Johnson",
        visible: true,
      },
      {
        id: "4_10",
        name: "Rob Koenig",
        visible: true,
      },
      {
        id: "4_11",
        name: "Scott Hendrickson",
        visible: true,
      },
      {
        id: "4_12",
        name: "Solo Agency",
        visible: true,
      },
      {
        id: "4_13",
        name: "Tracy Dawson",
        visible: true,
      },
    ],
  },
];

const menuItemsSR = [
  {
    id: "1",
    name: "Database",
    visible: true,
  },
  {
    id: "3",
    name: "License",
    visible: true,
  },
  {
    id: "2",
    name: "Summary",
    visible: false,
  },
  {
    id: "4",
    name: "Sales Rep. Dashboard",
    visible: true,
  },
];

export function getMenuItems(isSalesRep) {
  return isSalesRep ? menuItemsSR : menuItemsVP;
}
