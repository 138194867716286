import React, { useState, useEffect, useContext } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Menu from "devextreme-react/menu";
import MultiView, { Item as ItemView } from "devextreme-react/multi-view";
import DropDownButton from "devextreme-react/drop-down-button";
import { ReactComponent as QualityNetSuiteIcon } from "./qualityNetSuite.svg";
import { useTheme } from "../../contexts/theme";
import { Link, useNavigate } from "react-router-dom";
import notify from "devextreme/ui/notify";

import service from "./dataMenu.js";
import DasboardTemplate from "./dashboard.js";
import IncomingAudit from "./incomingAudit";
import FinalAudit from "./finalAudit";
import Audit from "./audit/audit";
import Overview from "./overview";
import { useAuth } from "../../contexts/auth";

import {
  DSFacility,
  arrayFacility,
  arrayLogSession,
  newLogSession,
  accessRole,
} from "./data.js";
import "./suite.scss";
import { qualityURL } from "../../api/config.js";
import { AuditProvider, useAudit } from "./contexts/audit";

export default function QualitySuite() {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const {
    fkFacility,
    setFkFacility,
    facility, setFacility,
    fkProcess,
    setFkProcess,
    setProcess,
    fkAuditType,
    setFkAuditType,
    auditType,
    setFkAuditor,
    role,
    setRole,
    setIdLastScreen,
  } = useAudit();

  const [iconColor, setIconColor] = useState("white");
  const [items, setItems] = useState(false);
  const [itemComponent, setItemComponent] = useState(() => null);
  const [loading, setLoading] = useState(true);
  const [facilities, setFacilities] = useState([]);

  const [updateComponent, setUpdateComponent] = useState(() => () => {});

  const buttonDropDownOptions = { width: 230 };
  const itemMenu = service.getMenuItem();
  const { getTheme } = useTheme();

  const showSubmenuModes = [
    {
      name: "onHover",
      delay: { show: 0, hide: 500 },
    },
    {
      name: "onClick",
      delay: { show: 0, hide: 300 },
    },
  ];

  let currentTheme = getTheme().split(".")[1];
  useEffect(() => {
    function themeResult() {
      setIconColor(currentTheme === "dark" ? "white" : "black");
    }
    themeResult();
  }, [currentTheme]);

  useEffect(() => {
    async function getLastSessionResult() {
      try {
        const session = await arrayLogSession(user.idUser);
        const LastScreen = session.length > 0 ? session[0].ScreenId : "5";
        const FacilityName = session.length > 0 ? session[0].FacilityName : null;
        setFacility(FacilityName)
        setIdLastScreen(LastScreen);
        lastScreen(LastScreen);
        user.FK_Facility ? setFkFacility(user.FK_Facility) : setFkFacility(0);
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    }
    getLastSessionResult();
  }, [user.idUser]);

  useEffect(() => {
    async function fetchFacilities() {
      try {
        const data = await DSFacility().load();
        setFacilities(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching facilities:", error);
        setLoading(false);
        notify("Error fetching facilities", "error", 3000);
      }
    }

    fetchFacilities();
  }, []);


  function LogSession(
    fkFacility,
    FkUser,
    IdScreen,
    FkProcess,
    FkAuditType,
    auditType,
    FkRole
  ) {
    const myJSON = JSON.stringify({
      FK_Facility: fkFacility,
      FK_User: FkUser,
      ScreenId: IdScreen,
      FK_Process: FkProcess,
      FK_AuditType: FkAuditType,
      auditType: auditType,
      FK_Role: FkRole,
      DateSession: new Date(),
    });
    newLogSession(myJSON);
  }

  const multiViewItems = [{ text: "Dashboard" }, { text: "Packing Audit" }];

  const onFacilityItemClick = (e) => {
    if (e.item && e.item.PK_Facility) {
      setFkFacility(e.item.PK_Facility);
    }
  };

  const onItemClick = (e) => {
    if (e.itemData.id === "5") {
      setIdLastScreen("5");
      LogSession(
        fkFacility,
        user.idUser,
        e.itemData.id,
        fkProcess,
        fkAuditType,
        auditType,
        role.FK_Role
      );
      setItemComponent(() => <DasboardTemplate />);
    }
    if (e.itemData.id === "1_1_4_1") {
      setIdLastScreen("1_1_4_1");
      LogSession(
        fkFacility,
        user.idUser,
        e.itemData.id,
        12,
        fkAuditType,
        auditType,
        role.FK_Role
      );
      setItemComponent(() => <Overview updateComponent={setItemComponent} />);
    }
    if (e.itemData.id === "1_1_4_2") {
      setIdLastScreen("1_1_4_2");
      LogSession(
        fkFacility,
        user.idUser,
        e.itemData.id,
        12,
        fkAuditType,
        auditType,
        role.FK_Role
      );
      setItemComponent(() => <Audit props={{}} />);
    }
    if (e.itemData.id === "1_1_4_3") {
      setIdLastScreen("1_1_4_3");
      LogSession(
        fkFacility,
        user.idUser,
        e.itemData.id,
        12,
        fkAuditType,
        auditType,
        role.FK_Role
      );
      setItemComponent(() => <Audit props={{}} />);
    }
  };

  const lastScreen = (e) => {
    if (e === "5") {
      setItemComponent(() => <DasboardTemplate />);
    }
    if (e === "1_1_4_1") {
      setItemComponent(() => <Overview updateComponent={setItemComponent} />);
    }
    if (e === "1_1_4_2") {
      setItemComponent(() => <Audit props={{}} />);
    }
    if (e === "1_1_4_3") {
      setItemComponent(() => <Audit props={{}} />);
    }
  };

  return (
    <React.Fragment>
      <Toolbar className={"header-toolbar"}>
        <Item
          visible={true}
          location={"before"}
          locateInMenu={"auto"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <QualityNetSuiteIcon fill={iconColor} className={"qsuite-logo"} />
        </Item>
        <Item
          visible={true}
          location={"before"}
          locateInMenu={"auto"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <img
            className="quality-logo"
            src={
              iconColor === "black"
                ? `http://192.168.2.4:8087/Genisys/Image_Art/Icon/qualityLogo.svg`
                : `http://192.168.2.4:8087/Genisys/Image_Art/Icon/qualityLogoYellow.svg`
            }
          />
        </Item>
        <Item location="after">
          <div className="toolbar-separator"></div>
        </Item>
        <Item
          visible={true}
          location={"after"}
          locateInMenu={"auto"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <DropDownButton
            text={fkFacility ? facility : "Select Facility"}
            icon="globe"
            dropDownOptions={buttonDropDownOptions}
            dataSource={facilities}
            displayExpr="FacilityName"
            keyExpr="PK_Facility"
            splitButton={false}
            useSelectMode={facilities.length > 0 ? true : false}
            selectedItemKey={fkFacility}
            onSelectionChanged={onFacilityItemClick}
          />
        </Item>
      </Toolbar>
      <div className="form">
        <div>
          <Menu
            id="scroll"
            dataSource={itemMenu}
            displayExpr="name"
            showFirstSubmenuMode={showSubmenuModes[1]}
            orientation={"horizontal"}
            hideSubmenuOnMouseLeave={true}
            onItemClick={onItemClick}
          />
        </div>
      </div>
      <div className={"content-block dx-card responsive-paddings"}>
        {itemComponent}
      </div>
    </React.Fragment>
  );
}
