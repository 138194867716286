//const numIP = '192.168.2.190:90'
const numIP = 'genisys.bayislandsportswear.com'
//const numIP = '192.168.2.74:90' --Test Server
const numAPI ='/image'

//const numIP = 'localhost'
//const numAPI = 'http://localhost:5102'

//const numAPI = '192.168.2.4:8093' - bk

//API TOKEN URLs
/*
export const apiTokenRout = `http://${numIP}:8091/token`;
export const apiUserRout = `http://${numIP}:8091/user`;
export const apiLoginRout = `http://${numIP}:8091/login`;
export const apiLogoutRout = `http://${numIP}:8091/logout`;
export const apiNewUser = `http://${numIP}:8091/newuser`;
export const avatarAPI = `http://${numIP}:8091/upload`;
export const getAllUsers = `http://${numIP}:8091/getusers`;
export const sendRequestMailURL = `http://${numIP}:8091/MaterialTransfer/requestMail`
export const getScreenObject = `http://${numIP}:8090/object`;
export const authLoginURL = `http://${numIP}:8091/authlogin`;
export const refreshTokenURL = `http://${numIP}:8091/refreshtoken`;
export const authUserURL = `http://${numIP}:8091/authuser`;
export const authLogOutURL = `http://${numIP}:8091/loginout`;
*/

/////Auth
//export const loginURL = `http://${numIP}:8093/user`;
export const loginURL = `/auth/user`;
export const uploadAvatarURL = `/auth/uploadAvatarWeb`;

export const apiPassword = `/auth/pass`;
export const resetPassUrl = `http://${numIP}/#/change-password/`; 

/////Server
export const sharedURL = `/server/shared`;
export const qualityURL = `/server/quality`;
export const retrobrandURL = `/server/retrobrand`;
export const warehouseURL = `/server/warehouse`;
export const planningURL = `/server/planning`;
export const productionTrackURL = `/server/productiontrack`;

//////Image
export const auditImageUploadURL = `${numAPI}/uploadQualityImagen`; //`/image/uploadQualityImagen`