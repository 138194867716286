import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import "devextreme-react/text-area";
import Form, {
  SimpleItem,
  GroupItem,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from "devextreme-react/form";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Editing,
  Lookup,
  Summary,
  Selection,
  FilterRow,
  SearchPanel,
  Toolbar,
  Item,
  Format as FormatNumber,
  ColumnChooser,
  ColumnFixing,
  TotalItem,
  Scrolling,
  Export,
  HeaderFilter,
  FilterPanel,
  StateStoring,
} from "devextreme-react/data-grid";
import ResponsiveBox, {
  Row,
  Col,
  Item as BoxItem,
  Location,
} from "devextreme-react/responsive-box";
import SelectBox from "devextreme-react/select-box";
import TextBox, { TextBoxTypes } from "devextreme-react/text-box";
import { Button as ButtonItem } from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import { useAuth } from "../../../contexts/auth";
import {
  accessRights,
  DSFacility,
  DSProcess,
  DSWeekNumber,
  dsProcessCapacity,
  insertCapacity,
  getActualWeek,
} from "../data";
import GroupCaption from "./groupCaption.js";
import "../planGrid.scss";

const yearItems = [2024, 2025];

export default function Capacity(props) {
  const { user } = useAuth(); //user.idUser
  const [role, SetRole] = useState(2);
  const [actualYear, setActualYear] = useState(null);
  const [weekStart, setWeekStartValue] = useState(1);
  const [weekEnd, setWeekEndValue] = useState(52);
  const [facility, setFacility] = useState(user.FK_Facility);
  const [process, setProcess] = useState();
  const [capacityValue, setCapacityValue] = useState();
  const [dataSourceCapacity, setDataSourceCapacity] = useState([]);
  const screen = (width) => (width < 700 ? "sm" : "lg");

  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const year = String(today.getFullYear()).slice(2);
  const productIDLabel = { "aria-label": "Facility" };
  const dataGridRef = useRef(null);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      try {
        const week = await getActualWeek(today);
        if (isSubscribed) {
          setWeekStartValue(week.FkWeek);
          setActualYear(new Date().getFullYear());
          const roleResult = await accessRights(user.idUser, 1004, '20_15')
          SetRole(roleResult != undefined ?  roleResult : 2);
          /*setDataSource(
            dsProcessCapacity(facility, process, new Date().getFullYear())
          );*/
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (process !== undefined) {
      const fetchData = async () => {
        try {
          const week = await getActualWeek(today);
          if (isSubscribed) {
            setDataSourceCapacity(
              dsProcessCapacity(facility, process, actualYear)
            );
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }

    return () => {
      isSubscribed = false;
    };
  }, [facility, process, actualYear]);

  function refreshData() {
    let isSubscribed = true;
    const fetchData = async () => {
      try {
        const week = await getActualWeek(today);
        if (isSubscribed) {
          setDataSourceCapacity(
            dsProcessCapacity(facility, process, actualYear)
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    return () => {
      isSubscribed = false;
    };
  }

  const facilityValueChanged = useCallback((data) => {
    setFacility(data.value);
  }, []);
  const processValueChanged = useCallback((data) => {
    setProcess(data.value);
  }, []);
  const capacityValueChanged = useCallback((data) => {
    setCapacityValue(data.value);
  }, []);
  const yearValueChanged = useCallback((data) => {
    setActualYear(data.value);
  }, []);
  const weekStartValueChanged = useCallback((data) => {
    setWeekStartValue(data.value);
  }, []);
  const weekEndValueChanged = useCallback((data) => {
    setWeekEndValue(data.value);
  }, []);

  const onAddingNew = () => {
    var data = {
      FK_Facility: facility,
      FK_Process: process,
      FK_Year: actualYear,
      FK_WeekStart: weekStart,
      FK_WeekEnd: weekEnd,
      Capacity: capacityValue,
    };
    insertCapacity(data);
    refreshData();
    notify(
      { message: "Capacity added successfully", shading: true },
      "success",
      1000
    );
  };

  return (
    <React.Fragment>
      <ResponsiveBox singleColumnScreen="sm" screenByWidth={screen}>
        <Row ratio={1}></Row>
        <Row ratio={2}></Row>
        <Row ratio={1}></Row>
        <Col ratio={1}></Col>
        <Col ratio={1}></Col>
        <Col ratio={2} screen="lg"></Col>
        <BoxItem>
          <Location row={1} col={0} screen="lg"></Location>
          <Location row={2} col={0} colspan={2} screen="sm"></Location>
          <div>Facility</div>
          <SelectBox
            dataSource={DSFacility}
            displayExpr="FacilityName"
            inputAttr={productIDLabel}
            valueExpr="PK_Facility"
            defaultValue={facility}
            value={facility}
            onValueChanged={facilityValueChanged}
          />
          <div style={{ marginTop: "16px" }}>Process</div>
          <SelectBox
            dataSource={DSProcess}
            displayExpr="Process"
            inputAttr={productIDLabel}
            valueExpr="PK_Process"
            value={process}
            searchEnabled={true}
            onValueChanged={processValueChanged}
          />
          <div style={{ marginTop: "16px" }}>Capacity</div>
          <TextBox
            showClearButton={true}
            placeholder="Enter capacity value"
            value={capacityValue}
            onValueChanged={capacityValueChanged}
          />
        </BoxItem>
        <BoxItem>
          <Location row={1} col={1} screen="lg"></Location>
          <Location row={1} col={0} colspan={2} screen="sm"></Location>
          <div className="optionBox">
            <div>Year</div>
            <SelectBox
              items={yearItems}
              defaultValue={actualYear}
              onValueChanged={yearValueChanged}
            />
            <div style={{ marginTop: "16px" }}>Start Week</div>
            <SelectBox
              dataSource={DSWeekNumber}
              valueExpr="FK_Week"
              displayExpr="WeekName"
              value={weekStart}
              searchEnabled={true}
              onValueChanged={weekStartValueChanged}
            />
            <div style={{ marginTop: "16px" }}>End Week</div>
            <SelectBox
              dataSource={DSWeekNumber}
              valueExpr="FK_Week"
              displayExpr="WeekName"
              value={weekEnd}
              searchEnabled={true}
              showClearButton={true}
              onValueChanged={weekEndValueChanged}
            />
          </div>
        </BoxItem>
        <BoxItem>
          <Location row={1} col={2} screen="lg"></Location>
          <Location row={2} col={1} colspan={2} screen="sm"></Location>
          <div className="optionBox">
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={dataSourceCapacity}
              height="100%"
              focusedRowEnabled={false}
              hoverStateEnabled={true}
              rowAlternationEnabled={true}
              allowColumnReordering={false}
              allowColumnResizing={true}
              showBorders={true}
              columnAutoWidth={true}
              showColumnHeaders={true}
              //onInitNewRow={onInitNewRow}
              //onSaved={refreshDataGrid}
              /* onExporting={onExporting}
            onCellPrepared={onCellPrepared}
            onSelectionChanged={onSelectionChanged}*/
            >
              <SearchPanel visible={true} />
              <Scrolling mode="standard" />
              <Paging defaultPageSize={10} />
              <HeaderFilter visible={true} />
              <FilterPanel visible={true} />
              <FilterRow visible={false} />
              <ColumnFixing enabled={false} />
              <ColumnChooser enabled={false} mode={"dragAndDrop"} />{" "}
              {/*select*/}
              <Pager
                visible={true}
                allowedPageSizes={true}
                displayMode={"full"}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              <Selection
                mode="single"
                selectAllMode="allPages"
                showCheckBoxesMode="always"
              />{" "}
              <Editing
                mode="cell"
                allowAdding={false}
                allowDeleting={role.FK_Role > 4}
                allowUpdating={role.FK_Role > 2}
              ></Editing>
              <Column
                dataField="PK_ProcessCapacity"
                caption="ID"
                dataType="number"
                visible={false}
                allowEditing={false}
              />
              <Column
                dataField="FK_Facility"
                caption="Facility"
                dataType="number"
                visible={false}
              />
              <Column
                dataField="FK_Year"
                caption="Year"
                dataType="number"
                visible={false}
              />
              <Column
                dataField="FK_Week"
                caption="Week"
                dataType="number"
                width={120}
                visible={true}
              />
              <Column
                dataField="FK_Process"
                caption="Process"
                dataType="number"
                visible={false}
              />
              <Column
                dataField="Capacity"
                caption="Capacity"
                dataType="number"
                visible={true}
                width={120}
              />
              <Column
                dataField="Remarks"
                caption="Remarks"
                dataType="string"
                visible={true}
              />
              <Toolbar>
                <Item location="after">
                  <ButtonItem icon="add" visible={role.FK_Role > 3} onClick={onAddingNew} />
                </Item>
                <Item location="after">
                  <ButtonItem
                    icon="refresh"
                    visible={true}
                    onClick={refreshData}
                  />
                </Item>
                <Item name="searchPanel" locateInMenu="auto" location="after" />
              </Toolbar>
            </DataGrid>
          </div>
        </BoxItem>
      </ResponsiveBox>
    </React.Fragment>
  );
}
