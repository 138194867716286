import React from "react";

const htmFrame =
  "https://app.powerbi.com/view?r=eyJrIjoiYmVkMmNmZDYtZGNkOC00N2ZjLTk0NDQtNzk0ZDJiY2FkODk0IiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9";

export default function DashboardTemplate() {
  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <iframe
        title="PD Dashboard"
        width="100%"
        height="780"
        src={htmFrame}
        style={{ border: "none" }} 
        allowFullScreen={true}
      />
    </div>
  );
}

