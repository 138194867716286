import CustomStore from "devextreme/data/custom_store";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { sharedURL, planningURL } from "../../api/config";

export const accessRights = async (FK_User, FK_RoutIndex, MenuIndex) => {
  try {
    const response = await fetch(
      `${sharedURL}/auth/accessrights/${FK_User}/${FK_RoutIndex}/${MenuIndex}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    return result[0];
  } catch (error) {
    console.log(error.message);
  }
};

export const DSCapacityOpenOrder = new CustomStore({
  key: "PK_Master",
  loadMode: "raw",
  load: () => {
    return fetch(`${planningURL}/capacity/openorder`).then((response) =>
      response.json()
    );
  },
});

export async function arrayOpenOrder() {
  try {
    const url = `${planningURL}/capacity/openorder`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to fetch data:", error);
    return [];
  }
}

export const DSCapacity = (FK_Facility, FK_Process) =>
  new CustomStore({
    key: "PK_Master",
    loadMode: "raw",
    load: async () => {
      const response = await fetch(
        `${planningURL}/capacity/${FK_Facility}/${FK_Process}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    },
    update: (key, values) => {
      return key ? console.log(key, values) : [];
    },
  });

export const DSCapacityDetail = (FK_Facility, FK_Process) =>
  new CustomStore({
    key: "PK_CapacityDetail",
    loadMode: "raw",
    load: () => {
      return fetch(
        `${planningURL}/capacity/detail/${FK_Facility}/${FK_Process}`
      ).then((response) => response.json());
    },
    update: (key, values) => {
      return key ? console.log(key, values) : [];
    },
  });

export async function arrayCapacityColumn(FK_Facility, FK_Process, isDetail) {
  if (typeof FK_Facility === "undefined" || typeof FK_Process === "undefined") {
    //console.error('Invalid facility or process ID provided:', FK_Facility, FK_Process);
    return [];
  } else {
    try {
      const url = `${planningURL}/capacity/column/${FK_Facility}/${FK_Process}/${isDetail}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error) {
      console.error("Failed to fetch data:", error);
      return [];
    }
  }
}

export const DSContractor = (FK_Process) =>
  new CustomStore({
    key: "PK_Contractor",
    loadMode: "raw",
    load: () => {
      return fetch(`${planningURL}/contractor/${FK_Process}`).then((response) =>
        response.json()
      );
    },
  });

export const DSWorkStation = (FK_Facility, FK_Process) =>
  new CustomStore({
    key: "PK_WorkStation",
    loadMode: "raw",
    load: () => {
      return fetch(
        `${planningURL}/workstation/${FK_Facility}/${FK_Process}`
      ).then((response) => response.json());
    },
  });

export async function arrayContractor(FK_Process) {
  if (typeof FK_Process === "undefined") {
    //console.error('Invalid facility or process ID provided:', FK_Facility, FK_Process);
    return [];
  } else {
    try {
      const url = `${planningURL}/contractor/${FK_Process}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error) {
      console.error("Failed to fetch data:", error);
      return [];
    }
  }
}

export async function arrayWorkStation(FK_Facility, FK_Process) {
  if (typeof FK_Facility === "undefined" || typeof FK_Process === "undefined") {
    //console.error('Invalid facility or process ID provided:', FK_Facility, FK_Process);
    return [];
  } else {
    try {
      const url = `${planningURL}/workstation/${FK_Facility}/${FK_Process}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error) {
      console.error("Failed to fetch data:", error);
      return [];
    }
  }
}

export async function updateCapacityColumnVisible(
  PK_Capacity_ColumnProcess,
  isVisible
) {
  const url = `${planningURL}/capacity/column`;
  try {
    //console.log("Updating capacity column:", JSON.stringify({ FK_Sort, isVisible }));
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ PK_Capacity_ColumnProcess, isVisible }),
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok (${response.status})`);
    }
    const data = await response.json();
    //console.log("Server response:", data);
  } catch (error) {
    console.error("There was a problem with your fetch operation:", error);
  }
}

export async function insertCapacityMaster(
  FK_Facility,
  FK_Process,
  Masters,
  FK_User
) {
  const url = `${planningURL}/capacity`;
  const myJSON = JSON.stringify({
    FK_Facility: FK_Facility,
    FK_Process: FK_Process,
    Masters: Masters,
    FK_User: FK_User,
  });
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myJSON,
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok (${response.status})`);
    }
    const data = await response.json();
    //console.log("Server response:", data);
  } catch (error) {
    console.error("There was a problem with your fetch operation:", error);
  }
}

export async function insertCapacityDetail(
  FK_Capacity,
  FK_Contractor,
  FK_User
) {
  const url = `${planningURL}/capacity/detail`;
  const myJSON = JSON.stringify({
    FK_Capacity: FK_Capacity,
    FK_Contractor: FK_Contractor,
    FK_User: FK_User,
  });
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myJSON,
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok (${response.status})`);
    }
    const data = await response.json();
    //console.log("Server response:", data);
  } catch (error) {
    console.error("There was a problem with your fetch operation:", error);
  }
}
/////////////Status PD////////////////////////////////////
export const DSStatusPd = (status, FK_User) =>
  new CustomStore({
    key: "FK_Style",
    loadMode: "raw",
    load: () => {
      return fetch(`${planningURL}/status/pd/${status}`).then((response) =>
        response.json()
      );
    },
    update: async (key, values) => {
      const myJSON = JSON.stringify({
        FK_Style: key,
        StyleCreation: values.StyleCreation,
        StyleConsumption: values.StyleConsumption,
        StyleLabDip: values.StyleLabDip,
        SampleFit: values.SampleFit,
        SamplePP: values.SamplePP,
        SampleTesting: values.SampleTesting,
        SamplePhoto: values.SamplePhoto,
        Bom: values.Bom,
        SpecsSew: values.SpecsSew,
        SpecsPack: values.SpecsPack,
        FK_User: FK_User,
      });
      try {
        const response = await fetch(`${planningURL}/status/pd`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: myJSON,
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok (${response.status})`);
        }
        const data = await response.json();
        //console.log("Server response:", data);
      } catch (error) {
        console.error("There was a problem with your fetch operation:", error);
      }
    },
  });

export const arrayStatusPd = [
  {
    id: 0,
    Status: "Pending",
  },
  {
    id: 1,
    Status: "Completed",
  },
];

function formatDate(dateString) {
  if (!dateString) {
    return "";
  } else {
    const date = new Date(dateString);
    let month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  }
}
export const arrayStatusSummary = (props) => [
  {
    id: 0,
    Description: "Customer",
    Status: props.Customer,
  },
  {
    id: 5,
    Description: "PO",
    Status: props.PO,
  },
  {
    id: 10,
    Description: "Style",
    Status: props.Style,
  },
  {
    id: 15,
    Description: "Color",
    Status: props.Color,
  },
  {
    id: 17,
    Description: "Special Process",
    Status: props.SpecialProcess,
  },
  {
    id: 20,
    Description: "Art",
    Status: props.Art,
  },
  {
    id: 25,
    Description: "Design Name",
    Status: props.DesignName,
  },
  {
    id: 30,
    Description: "PO Date",
    Status: formatDate(props.PODate),
  },
  {
    id: 35,
    Description: "Ex Factory",
    Status: formatDate(props.ExFactory),
  },
  {
    id: 40,
    Description: "Rev Date",
    Status: formatDate(props.RevDate),
  },
  {
    id: 45,
    Description: "Cancel Date",
    Status: formatDate(props.CancelDate),
  },
  {
    id: 50,
    Description: "Priority Week",
    Status: props.PriorityWeek,
  },
  {
    id: 55,
    Description: "Order",
    Status: props.TotalOrder,
  },
  {
    id: 60,
    Description: "Status",
    Status: props.Status,
  },
  {
    id: 65,
    Description: "ID",
    Status: props.PK_Master,
  },
];

export const getStatusPdById = async (FK_Style) => {
  try {
    const response = await fetch(`${planningURL}/status/pd/style/${FK_Style}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const getStatusArtById = async (FK_Master) => {
  try {
    const response = await fetch(`${planningURL}/status/art/${FK_Master}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const getStatusTrimsById = async (FK_Master) => {
  try {
    const response = await fetch(`${planningURL}/status/trims/${FK_Master}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const getStatusProductionById = async (FK_Master) => {
  try {
    const response = await fetch(`${planningURL}/status/production/${FK_Master}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const DSStatusWip = (FK_Master) =>
  new CustomStore({
    key: "FK_Master",
    loadMode: "raw",
    load: () => {
      return fetch(`${planningURL}/status/wip/${FK_Master}`).then((response) =>
        response.json()
      );
    },
  });

  export async function arraySummaryStatus(FK_Master) {
    try {
      const url = `${planningURL}/status/summary/${FK_Master}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error) {
      console.error("Failed to fetch data:", error);
      return [];
    }
  }