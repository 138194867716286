import React from 'react';
import '../final.scss'; 

export default function Item({
  PK_AuditImage,
  ImagePath,
}) {
  const fallbackImagePath = `http://192.168.2.4:8087/Genisys/Image_Art/tbl_Art/Genisys_Logo.png`; 

  const handleImageError = (event) => {
    event.target.src = fallbackImagePath;
  };

  return (
    <div>
      <img
        alt={PK_AuditImage}
        src={ImagePath}
        onError={handleImageError}
      />
      <div className='item'>{`${PK_AuditImage}`}</div>
    </div>
  );
}
