import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { SpeedDialAction } from "devextreme-react/speed-dial-action";
import notify from "devextreme/ui/notify";

import { useAuth } from "../../../contexts/auth.js";
import ProductionDetail from "./productionDetail.js";
import ProductionSummary from "./productionSummary.js";

export default function SpeedAction({ data, onNewClick, summaryClick }) {
  const { user, signOut } = useAuth();
  const [visible, setVisible] = useState(false);
  const formOrder = useRef(null);

  function onNewRecordClick() {
    onNewClick(() => (
      <ProductionDetail
        data={{
          fkFacility: data.fkFacility,
          fkProcess: data.fkProcess,
        }}
      />
    ));
    setVisible(true);
  }
  function onSummaryClick() {
    summaryClick(() => (
      <ProductionSummary
        data={{
          fkFacility: data.fkFacility,
          fkProcess: data.fkProcess,
        }}
        ChangeFkFacility={(item) => item}
      />
    ));
    setVisible(false);
  }

  return (
    <React.Fragment>
      <SpeedDialAction
        icon="add"
        label="New Day"
        index={1}
        visible={true}
        onClick={onNewRecordClick}
      />
      <SpeedDialAction
        icon="formula"
        label={"Summary"}
        index={2}
        visible={visible}
        onClick={onSummaryClick}
      />
      <SpeedDialAction
        icon="trash"
        label="Delete Audit"
        index={3}
        visible={false}
        //onClick={deleteRow}
      />
      <SpeedDialAction
        icon="checklist"
        label={"collapsedLabel"}
        index={4}
        visible={false}
        //onClick={onCollapsedGroup}
      />
      <SpeedDialAction
        icon="fieldchooser"
        label={"showLabel"}
        index={5}
        visible={false}
        //onClick={onShowLessClick}
      />
      <SpeedDialAction
        icon="bookmark"
        label={"Finish Audit"}
        index={6}
        visible={false}
        //onClick={onClickfinish}
      />
    </React.Fragment>
  );
}
