import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button as ButtonItem } from "devextreme-react/button";
import { createStore } from "devextreme-aspnet-data-nojquery";
import DataSource from "devextreme/data/data_source";
import RangeSelector, {
  Margin,
  Scale,
  MinorTick,
  SliderMarker,
  Label,
  Marker,
  Behavior,
  Format,
} from "devextreme-react/range-selector";
import DataGrid, {
  Column,
  Editing,
  Selection,
  Button,
  Summary,
  Scrolling,
  TotalItem,
  Export,
  Format as FormatNumber,
  Item,
  Toolbar,
  Pager,
  Paging,
  FilterRow,
  SearchPanel,
  HeaderFilter,
  FilterPanel,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";

import { retrobrandURL } from "../../../api/config";
import { useAuth } from "../../../contexts/auth.js";
import { getSalesRepCode } from "../data.js";

import "../grid.scss";

export default function DataBaseSales() {
  const { user, signOut } = useAuth();
  const [isSalesRep, setIsSalesRep] = useState(true);
  const [srCode, setSrCode] = useState();
  const [startDate, setStartDate] = useState(new Date(2024, 0, 1));
  const [endDate, setEndDate] = useState(new Date());
  const dataGrid = useRef(null);

  var today = new Date(new Date().toLocaleString());
  var thisYear = today.getFullYear();
  var lastYear = thisYear - 1;
  const startValue = new Date(2019, 0, 1);
  const endValue = new Date(thisYear, 0, 1);
  const range = [new Date(lastYear, 0, 1), new Date(thisYear, 0, 1)];

  useEffect(() => {
    setIsSalesRep(user.FK_Position === 2005 ? true : false);
    async function getSRCode() {
      const salesRep = await getSalesRepCode(user.idUser);
      if (salesRep.length > 0) setSrCode(salesRep[0].Code);
    }
    getSRCode();
  }, [user]);

  const DSRBSales = () =>
    createStore({
      key: "PK_RBSales",
      loadUrl: isSalesRep
        ? `${retrobrandURL}/sales/${srCode}/${startDate.getFullYear()}/${endDate.getFullYear()}`
        : `${retrobrandURL}/sales/${startDate.getFullYear()}/${endDate.getFullYear()}`,
    });

  const rbSalesDataSource = new DataSource({
    key: "PK_RBSales",
    store: DSRBSales(),
    requireTotalCount: true,
  });

  function refreshDataGrid() {
    rbSalesDataSource.reload();
  }

  const filterSales = useCallback(
    ({ value }) => {
      setStartDate(value[0]);
      setEndDate(value[1]);
    },
    [setStartDate, setEndDate]
  );

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`dbSales`);
    exportDataGrid({
      component: dataGrid.current.instance,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          `dbSalesYTD${endDate.getFullYear()}.xlsx`
        );
      });
    });
  };

  return (
    <React.Fragment>
      <RangeSelector
        id="range-selector"
        title="Select a Sale Year Period"
        defaultValue={range}
        onValueChanged={filterSales}
      >
        <Margin top={50} />
        <Scale
          startValue={startValue}
          endValue={endValue}
          minorTickInterval="year"
          tickInterval="year"
        >
          <MinorTick visible={false} />
          <Marker visible={false} />
          <Label format="Year" />
        </Scale>
        <Behavior valueChangeMode={"onHandleMove"} />
        <SliderMarker format="Year" />
      </RangeSelector>
      <DataGrid
        //id="gridContainerTransferMaterial"
        ref={dataGrid}
        dataSource={rbSalesDataSource}
        focusedRowEnabled={true}
        hoverStateEnabled={true}
        rowAlternationEnabled={true}
        showBorders={true}
        height="100%"
        columnAutoWidth={true}
        showColumnHeaders={true}
        onExporting={onExporting}
      >
        <Selection mode="single" />
        <SearchPanel visible={true} />
        <Scrolling mode="standard" />
        <Paging defaultPageSize={10} />
        <HeaderFilter visible={true} />
        <FilterPanel visible={true} />
        <FilterRow visible={true} />
        <Pager
          visible={true}
          allowedPageSizes={true}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Column
          dataField="InvoiceNum"
          caption="Invoice Num"
          dataType="integer"
          visible={true}
        />
        <Column
          dataField="OrderNum"
          caption="Order Num"
          dataType="integer"
          visible={true}
        />
        <Column
          dataField="OrderDate"
          caption="Order Date"
          dataType="date"
          visible={true}
        />
        <Column
          dataField="CancelDate"
          caption="Cancel Date"
          dataType="date"
          visible={true}
        />
        <Column
          dataField="InvoiceDate"
          caption="Invoice Date"
          dataType="date"
          visible={true}
        />
        <Column
          dataField="InvoiceWeek"
          caption="Invoice Week"
          dataType="integer"
          visible={true}
        />
        <Column
          dataField="ShipDate"
          caption="Ship Date"
          dataType="date"
          visible={true}
        />
        <Column
          dataField="Brand"
          caption="Brand"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Style"
          caption="Style"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="StyleDescription"
          caption="Style Description"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Color"
          caption="Color"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Royaltor"
          caption="Royaltor"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="RoyaltorGroup"
          caption="License"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="CustomerCode"
          caption="Customer Code"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Customer"
          caption="Customer"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="Address1"
          caption="Address1"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="StateCode"
          caption="State Code"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="City"
          caption="City"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="ZipCode"
          caption="Zip Code"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="ShipState"
          caption="ShipState"
          dataType="string"
          visible={true}
        />
        <Column
          dataField="TotalOrder"
          caption="Total Order"
          dataType="integer"
          alignment="right"
          visible={true}
        >
         <FormatNumber type="fixedPoint" precision={0} />
        </Column>
        <Column
          dataField="UnitPrice"
          caption="UnitPrice"
          dataType="currency"
          alignment="right"
          format="$##0.00"
          visible={true}
        />
        <Column
          dataField="TotalCost"
          caption="Total Cost"
          width={160}
          alignment="right"
          visible={true}
        >
          <FormatNumber type="currency" precision={2} />
        </Column>
        <Summary>
          <TotalItem column="InvoiceNum" summaryType="count" />
          <TotalItem
            column="TotalOrder"
            summaryType="sum"
            valueFormat="###,##0"
            displayFormat="{0}"
          />
          <TotalItem
            column="TotalCost"
            summaryType="sum"
            valueFormat="$ ###,##0.##0"
            displayFormat="{0}"
          >
            <FormatNumber type="currency" precision={2} />
          </TotalItem>
        </Summary>
        <Toolbar>
          <Item location="after">
            <ButtonItem
              icon="refresh"
              visible={true}
              onClick={refreshDataGrid}
            />
          </Item>
          <Item location="after">
            <ButtonItem icon="export" visible={true} onClick={onExporting} />
          </Item>
          <Item name="searchPanel" locateInMenu="auto" location="after" />
        </Toolbar>
        <Export enabled={true} allowExportSelectedData={true} />
      </DataGrid>
    </React.Fragment>
  );
}
