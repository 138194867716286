import React from "react";

const htmFrame =
  "https://app.powerbi.com/view?r=eyJrIjoiNzkzY2Y5YTEtZDhmMC00M2ViLThkZTEtMDZjNzAyZTI1NjdlIiwidCI6ImVjNjg0MWRkLTM1N2ItNDEwMS1hYmFhLWVmY2VmNzc0MzcyYSJ9";

export default function DashboardTemplate() {
  return (
    <div style={{ width: "100%", overflowX: "auto" }}>
      <iframe
        title="Shipping Dashboard"
        width="100%"
        height="4500"
        src={htmFrame}
        style={{ border: "none" }} 
        allowFullScreen={true}
      />
    </div>
  );
}
