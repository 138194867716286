import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import DataGrid, { Column } from "devextreme-react/data-grid";
import Box, { Item } from "devextreme-react/box";

import { arrayStatusSummary } from "../data";
import "./status.scss";

export default function Summary(props) {
  const {
    Customer,
    PO,
    Style,
    Color,
    Art,
    FK_Art,
    PriorityWeek,
    DesignName,
    PODate,
    ExFactory,
    RevDate,
    CancelDate,
    TotalOrder,
    Status,
  } = props || {};
  const [imageValid, setImageValid] = useState(false);

  function formatDate(dateString) {
    const date = new Date(dateString);
    let month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  }

  function formatNumber(number) {
    return new Intl.NumberFormat("en-US").format(number);
  }

  function truncateString(str, num) {
    if (!str) return "";
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  const renderGridCell = (data) => {
    return <div style={{ fontWeight: "bold", fontSize: 20 }}>{data.text}</div>;
  };
  const renderGridStatusCell = (data) => {
    return (
      <div style={{ fontWeight: "normal", fontSize: 20, color: "grey" }}>
        {data.text}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Box direction="row" width={1000} height={650}>
        <Item ratio={0} baseSize={650} shrink={1}>
          <DataGrid
            id="gridContainer"
            class="borderlessGrid"
            dataSource={arrayStatusSummary(props)}
            height={600}
            keyExpr="FK_Style"
            showBorders={false}
            showColumnHeaders={false}
          >
            <Column dataField="id" caption="id" visible={false} />
            <Column
              dataField="Description"
              caption="Description"
              width={250}
              cellRender={renderGridCell}
            />
            <Column
              dataField="Status"
              caption="status"
              cellRender={renderGridStatusCell}
            />
          </DataGrid>
        </Item>
        <Item ratio={0} baseSize={20} shrink={1}>
          <div className="sku-description">
         
          </div>
        </Item>
        <Item ratio={0} baseSize={350} shrink={1}>
          <div className="sku-description">
            {props ? (
              <img
                className="tile-image"
                alt={`Image of Art ${FK_Art}`}
                src={`http://192.168.2.4:8087/Genisys/Image_Art/tbl_Art_Hi/img_${FK_Art}.jpg`}
                onLoad={() => setImageValid(true)}
                onError={() => setImageValid(false)}
                style={{ display: imageValid ? "block" : "none" }}
              />
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </Item>
      </Box>
    </React.Fragment>
  );
}
