import React, { useState, useEffect, useCallback, useRef } from "react";
import SelectBox from "devextreme-react/select-box";
import notify from "devextreme/ui/notify";
import { Button as ButtonItem } from "devextreme-react/button";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Editing,
  Lookup,
  Summary,
  Selection,
  FilterRow,
  SearchPanel,
  Toolbar,
  Item,
  Format as FormatNumber,
  ColumnChooser,
  ColumnFixing,
  TotalItem,
  Scrolling,
  Export,
  HeaderFilter,
  FilterPanel,
  StateStoring,
} from "devextreme-react/data-grid";

import { useAuth } from "../../../contexts/auth.js";
import SpeedAction from "./speedAction.js";
import ProductionSummary from "./productionSummary.js";
import ProductionDetail from "./productionDetail.js";

export default function Main({ data }) {
  const { user } = useAuth();
  const [fkFacility, setFkFacility] = useState(user.FK_Facility);
  const [itemComponent, setItemComponent] = useState(() => (
    <ProductionSummary
      data={{ fkFacility: fkFacility, fkProcess: data.fkProcess }}
      ChangeFkFacility={(item) => setFkFacility(item)}
    />
  ));

  const dataGridRef = useRef(null);

  useEffect(() => {
    setItemComponent(() => (
      <ProductionSummary
        data={{ fkFacility: fkFacility, fkProcess: data.fkProcess }}
        ChangeFkFacility={(item) => setFkFacility(item)}
      />
    ));
  }, [data]);

  return (
    <React.Fragment>
      <SpeedAction
        data={{ fkFacility: fkFacility, fkProcess: data.fkProcess }}
        onNewClick={(item) => setItemComponent(item)}
        summaryClick={(item) => setItemComponent(item)}
      />
      {itemComponent}
    </React.Fragment>
  );
}
