import React, { createContext, useContext, useState } from "react";

const AuditContext = createContext();

const AuditProvider = ({ children }) => {
  const [idLastScreen, setIdLastScreen] = useState();
  const [pkAudit, setPkAudit] = useState(-1);
  const [fkMasterOrder, setFkMasterOrder] = useState();
  const [fkFacility, setFkFacility] = useState(3);
  const [facility, setFacility] = useState();
  const [fkProcess, setFkProcess] = useState();
  const [process, setProcess] = useState();
  const [fkShift, setFkShift] = useState(2);
  const [fkAuditor, setFkAuditor] = useState();
  const [fkAuditType, setFkAuditType] = useState();
  const [auditType, setAuditType] = useState();
  const [fkSuperviser, setFkSuperviser] = useState();
  const [fkQualityLevel, setFkQualityLevel] = useState(1);
  const [fkWorkStation, setFkWorkStation] = useState(1);
  const [fkStatus, setFkStatus] = useState(3);
  const [totalOrder, setTotalOrder] = useState(0);
  const [sampling, setSampling] = useState(0);
  const [totalBoxes, setTotalBoxes] = useState(0);
  const [remarks, setRemarks] = useState();
  const [auditReadOnly, setAuditReadOnly] = useState(false);
  const [auditFinish, setAuditFinish] = useState();
  const [newPkAudit, setNewPkAudit] = useState(0);
  const [dataGridVisible, setDataGridVisible] = useState(false);
  const [collapsedGroup, setCollapsedGroup] = useState(false);
  const [isListChecked, setIsListChecked] = useState(true);

  const [auditor, setAuditor] = useState([])
  const [role, setRole] = useState([]);
  const [auditArray, setAuditArray] = useState([]);
  const [master, setMaster] = useState([]);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);
  const [gridBoxValue, setGridBoxValue] = useState([]);
  const [arraySampling, setArraySampling] = useState([]);
  const [arrayMeasure, setArrayMeasure] = useState([]);
  const [arrayMeasureDetail, setArrayMeasureDetail] = useState([]);

  return (
    <AuditContext.Provider
      value={{
        idLastScreen,
        setIdLastScreen,
        pkAudit,
        setPkAudit,
        newPkAudit,
        setNewPkAudit,
        master,
        setMaster,
        fkMasterOrder,
        setFkMasterOrder,
        fkFacility,
        setFkFacility,
        facility, setFacility,
        fkProcess,
        setFkProcess,
        process,
        setProcess,
        fkShift,
        setFkShift,
        fkAuditor,
        setFkAuditor,
        role,
        setRole,
        fkAuditType,
        setFkAuditType,
        auditType,
        setAuditType,
        fkSuperviser,
        setFkSuperviser,
        fkQualityLevel,
        setFkQualityLevel,
        fkWorkStation,
        setFkWorkStation,
        totalOrder, 
        setTotalOrder,
        totalBoxes, 
        setTotalBoxes,
        sampling, setSampling,
        fkStatus,
        setFkStatus,
        remarks,
        setRemarks,
        auditReadOnly,
        setAuditReadOnly,
        auditArray,
        setAuditArray,
        selectedItemKeys,
        setSelectedItemKeys,
        gridBoxValue,
        setGridBoxValue,
        dataGridVisible,
        setDataGridVisible,
        collapsedGroup,
        setCollapsedGroup,
        isListChecked,
        setIsListChecked,
        auditor, setAuditor,
        arraySampling, setArraySampling,
        arrayMeasure, setArrayMeasure,
        arrayMeasureDetail, setArrayMeasureDetail,
        auditFinish, setAuditFinish,
      }}
    >
      {children}
    </AuditContext.Provider>
  );
};

const useAudit = () => useContext(AuditContext);
export { AuditProvider, useAudit };
