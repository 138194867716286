import { InvalidTokenError, jwtDecode } from "jwt-decode";
import axios from "axios";
import Cookies from "js-cookie";
import {
  apiUserRout,
  apiLoginRout,
  apiTokenRout,
  apiNewUser,
  authLoginURL,
  refreshTokenURL,
  authUserURL,
  apiPassword,
  apiMail,
  resetPassUrl,
  loginURL,
} from "./config";

export async function signIn(email, password) {
  try {
    const myJSON = JSON.stringify({
      Login: email,
      Password: password,
    });
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: myJSON,
    };
    const response = await fetch(`${loginURL}/login`, requestOptions);
    if (response.ok) {     
      const result = await response.json();
      const decoded = jwtDecode(result.accessToken);
      const AllowedUserTypes = await allowedUserTypes(decoded.PKUser);
      const user = {
        email: email,
        User_Name: decoded.username,
        idUser: decoded.PKUser,
        Avatar_Url: decoded.Avatar_Url,
        User_Group: AllowedUserTypes.map(item => item.FK_RoutIndex),
        IsAdmin: decoded.IsAdmin,
        FK_Facility: decoded.FK_Facility,
        FirstName: decoded.FirstName,
        LastName: decoded.LastName,
        FK_Process: decoded.FK_Process,
        FK_Position: decoded.FK_Position,
      };
      return {
        isOk: true,
        data: user,
      };
    } else {
      return {
        isOk: false,
        message: "Authentication failed",
      };
    }
  } catch (e) {
    console.log(e);
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

export async function getUser() {
  try {
    const requestOptions = {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const response = await fetch(`${loginURL}/refresh`, requestOptions);
    if (response.ok) {
      const result = await response.json();
      const decoded = jwtDecode(result.accessToken);
      const PKUser = decoded.PKUser;
      const data = await axios
      .get(`${loginURL}/id/${PKUser}`)
      .then((res) => res.data);
      
      const AllowedUserTypes = await allowedUserTypes(PKUser);
      const user = {
        email: data[0].UQ_Email,
        User_Name: data[0].UQ_UserName,
        FirstName: data[0].FirstName,
        LastName: data[0].LastName,
        Avatar_Url: data[0].AvatarUrl,
        User_Group: AllowedUserTypes.map(item => item.FK_RoutIndex),
        idUser: data[0].PK_User,
        IsAdmin: data[0].isAdmin,
        FK_Facility: data[0].FK_Facility,
        FK_Process: data[0].FK_Process,
        FK_Position: data[0].FK_Position,
      };
      return {
        isOk: true,
        data: user,
      };
    } else {
      return {
        isOk: false,
        message: "Authentication failed",
      };
    }
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(
  Name,
  Last_Name,
  User_Name,
  email,
  password,
  imageName,
  facility,
  process,
  position
) {
  try {
    const data = await axios.post(`${loginURL}`, {
      UQ_UserName: User_Name,
      UQ_Email: email,
      Password: password,
      FirstName: Name,
      LastName: Last_Name,
      AvatarUrl: imageName,
      FK_Facility: facility,
      FK_Process: process,
      FK_Position: position,
      FK_UserCreate: 0
    });

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to create account",
    };
  }
}

export async function changePassword(password, recoveryCode) {
  try {
    //console.log(password, recoveryCode);

    const data = await axios.post(`${loginURL}/changepassword`, {
      RecoveryCode: recoveryCode,
      password: password,
    });
    console.log(true);
    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to change password",
    };
  }
}

export async function resetPassword(email) {
  try {
    const recoveryCode = Math.floor(
      100000 + Math.random() * 900000,
      (err, n) => {
        if (err) throw err;
        console.log(n);
      }
    );

    const data = await axios.post(`${loginURL}/resetpassword`, {
      eMail: email,
      subjectMsg: "Reset Password Request",
      recoveryCode: recoveryCode,
      urlRecoveryLink: `${resetPassUrl}${recoveryCode}`
    });

    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to reset password",
    };
  }
}

async function allowedUserTypes(FK_User) {
  const response = await fetch(`${loginURL}/allowedroutes/${FK_User}`)
    .then((response) => response.json())
    .catch((error) => {
      console.error(error);
      return [];
    });
  return response;
}
