import React, { useState, useEffect, useContext } from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Menu from "devextreme-react/menu";
import { useTheme } from "../../contexts/theme";
import { useAuth } from "../../contexts/auth";

import { ReactComponent as RBLogo } from "./assets/RBLogo.svg";
import { ReactComponent as BIS_BW } from "./assets/BIS_BW.svg";
import { ReactComponent as TSC_BW } from "./assets/TSC_BW.svg";
import service from "./dataMenu.js";

import DashboardDailyProduction from "./daily/dashboard.js";
import DashboardShipping from "./shipping/dashboard.js";
import Projection from "./dpr/projection.js";
import Capacity from "./capacity/plantCapacity.js";
import DashboardDPRTemplate from "./dpr/dashboard.js";
import MainProcess from "./process/main.js";
import ProductionPivotReport from "./process/productionPivotReport.js";
import DprReport from "./dpr/dprReport.js";

import "./plan.scss";

export default function ProductionMainPage() {
  const { user, signOut } = useAuth();
  const [sticky, setSticky] = useState("");
  const { getTheme } = useTheme();
  const [title, setTitle] = useState("Production Tracking");
  const [iconColor, setIconColor] = useState("white");
  const itemMenu = service.getMenuItem();
  const [itemComponent, setItemComponent] = useState(() => null);

  const showSubmenuModes = [
    {
      name: "onHover",
      delay: { show: 0, hide: 500 },
    },
    {
      name: "onClick",
      delay: { show: 0, hide: 300 },
    },
  ];

  let currentTheme = getTheme().split(".")[1];
  let themeColor = getTheme().split(".")[0];

  useEffect(() => {
    function themeResult() {
      setIconColor(currentTheme === "dark" ? "white" : "black");
    }
    themeResult();
  }, [currentTheme]);

  const onItemClick = (e) => {
    if (e.itemData.id === "1") {
      setItemComponent(() => null);
      setTitle("DPR Demand Prioritization Report");
    }
    if (e.itemData.id === "10_1") {
      setItemComponent(() => <DashboardDailyProduction />);
      setTitle("Daily Production Report");
    }
    if (e.itemData.id === "15_1") {
      setItemComponent(() => <DashboardShipping />);
      setTitle("Shipping Dashboard Report");
    }
    if (e.itemData.id === "20_10_1") {
      setItemComponent(() => <DashboardDPRTemplate />);
      setTitle("DPR Planner Report");
    }
    if (e.itemData.id === "20_10_2") {
      setItemComponent(() => <DprReport />);
      setTitle("DPR Report");
    }
    if (e.itemData.id === "20_15") {
      setItemComponent(() => <Capacity />);
      setTitle("Weekly Capacity by Process");
    }
    if (e.itemData.id === "20_20_1_1") {
      setItemComponent(() => (
        <Projection
          data={{
            fkProcess: 4,
            colCapacity: false,
            colBilling: false,
          }}
        />
      ));
      setTitle("Fabric Weekly Capacity");
    }
    if (e.itemData.id === "20_20_1_2") {
      setItemComponent(() => (
        <Projection
          data={{
            fkProcess: 3009,
            colCapacity: false,
            colBilling: false,
          }}
        />
      ));
      setTitle("Pigment Dye Weekly Capacity");
    }
    if (e.itemData.id === "20_20_1_3") {
      setItemComponent(() => (
        <Projection
          data={{
            fkProcess: 3008,
            colCapacity: false,
            colBilling: false,
          }}
        />
      ));
      setTitle("Mineral Wash Weekly Capacity");
    }
    if (e.itemData.id === "20_20_1_4") {
      setItemComponent(() => (
        <Projection
          data={{
            fkProcess: 2006,
            colCapacity: false,
            colBilling: false,
          }}
        />
      ));
      setTitle("Garment Wash Weekly Capacity");
    }
    if (e.itemData.id === "20_20_1_5") {
      setItemComponent(() => (
        <Projection
          data={{
            fkProcess: 3007,
            colCapacity: false,
            colBilling: false,
          }}
        />
      ));
      setTitle("Tie Dye Weekly Capacity");
    }
    if (e.itemData.id === "20_20_10") {
      setItemComponent(() => (
        <Projection
          data={{
            fkProcess: 5,
            colCapacity: false,
            colBilling: false,
          }}
        />
      ));
      setTitle("Cutting Weekly Capacity");
    }
    if (e.itemData.id === "20_20_20_1") {
      setItemComponent(() => (
        <Projection
          data={{
            fkProcess: 6,
            colCapacity: false,
            colBilling: false,
          }}
        />
      ));
      setTitle("Sewing Weekly Capacity");
    }
    if (e.itemData.id === "20_20_25") {
      setItemComponent(() => (
        <Projection
          data={{
            fkProcess: 2008,
            colCapacity: false,
            colBilling: false,
          }}
        />
      ));
      setTitle("Sublimation Weekly Capacity");
    }
    if (e.itemData.id === "20_20_30_1") {
      setItemComponent(() => (
        <Projection
          data={{
            fkProcess: 8,
            colCapacity: false,
            colBilling: false,
          }}
        />
      ));
      setTitle("Screen Print Weekly Capacity");
    }
    if (e.itemData.id === "20_20_30_2") {
      setItemComponent(() => (
        <Projection
          data={{
            fkProcess: 10,
            colCapacity: false,
            colBilling: false,
          }}
        />
      ));
      setTitle("Rapidtag Weekly Capacity");
    }
    if (e.itemData.id === "20_20_40") {
      setItemComponent(() => (
        <Projection
          data={{
            fkProcess: 12,
            colCapacity: false,
            colBilling: false,
          }}
        />
      ));
      setTitle("Packing Weekly Capacity");
    }
    if (e.itemData.id === "20_20_50") {
      setItemComponent(() => (
        <Projection
          data={{
            fkProcess: 13,
            colCapacity: false,
            colBilling: true,
          }}
        />
      ));
      setTitle("Shipping & Billing Weekly Capacity");
    }//////////////Digital
    if (e.itemData.id === "30_4_1") {
      setItemComponent(() => (
        <MainProcess
          data={{
            fkProcess: 9,
          }}
        />
      ));
      setTitle("Digital Daily Production");
    }
    if (e.itemData.id === "30_4_2") {
      setItemComponent(() => (
        <ProductionPivotReport
          data={{
            fkProcess: 9,
          }}
        />
      ));
      setTitle("Digital Daily Production Report");
    }
    if (e.itemData.id === "30_5_1") {
      setItemComponent(() => (
        <MainProcess
          data={{
            fkProcess: 8,
          }}
        />
      ));
      setTitle("Screen Printing Daily Production");
    }
    if (e.itemData.id === "30_5_2") {
      setItemComponent(() => (
        <ProductionPivotReport
          data={{
            fkProcess: 8,
          }}
        />
      ));
      setTitle("Screen Printing Daily Production Report");
    }
    if (e.itemData.id === "30_6_1") {
      setItemComponent(() => (
        <MainProcess
          data={{
            fkProcess: 10,
          }}
        />
      ));
      setTitle("Rapidtag Daily Production");
    }
    if (e.itemData.id === "30_6_2") {
      setItemComponent(() => (
        <ProductionPivotReport
          data={{
            fkProcess: 10,
          }}
        />
      ));
      setTitle("Rapidtag Daily Production Report");
    }
    if (e.itemData.id === "30_7_1") {
      setItemComponent(() => (
        <MainProcess
          data={{
            fkProcess: 12,
          }}
        />
      ));
      setTitle("Packing Daily Production");
    }
    if (e.itemData.id === "30_7_2") {
      setItemComponent(() => (
        <ProductionPivotReport
          data={{
            fkProcess: 12,
          }}
        />
      ));
      setTitle("Packing Daily Production Report");
    }
  };

  return (
    <React.Fragment>
      <Toolbar className={"header-toolbar"}>
        <Item visible={true} location={"before"} locateInMenu={"auto"}>
          <h5 className={"content-block"}>{title}</h5>
        </Item>
        <Item
          visible={true}
          location={"after"}
          locateInMenu={"auto"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <BIS_BW fill={iconColor} className={"bis-logo"} />
        </Item>
        <Item
          visible={true}
          location={"after"}
          locateInMenu={"auto"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <TSC_BW fill={iconColor} className={"tsc-logo"} />
        </Item>
        <Item
          visible={true}
          location={"after"}
          locateInMenu={"auto"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <RBLogo fill={iconColor} className={"rb-logo"} />
        </Item>
      </Toolbar>
      <div className="form">
        <div className="parent-container">
          <Menu
            id="scroll"
            dataSource={itemMenu}
            displayExpr="name"
            showFirstSubmenuMode={showSubmenuModes[1]}
            orientation={"horizontal"}
            hideSubmenuOnMouseLeave={true}
            onItemClick={onItemClick}
          />
        </div>
      </div>
      <div className={"content-block dx-card responsive-paddings"}>
        {itemComponent}
      </div>
    </React.Fragment>
  );
}
